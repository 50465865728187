
import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import CarsPriceHistoryDocumentItemModel from '@/modules/cars/models/cars-price-history-document-item.model';
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import type Day from '@/modules/common/types/day.type';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import CarsPriceHistoryService, { CarsPriceHistoryServiceS } from '@/modules/cars/cars-price-history.service';
import { DEFAULT_GRAPH_COLORS as GRAPH_COLORS } from '@/modules/common/constants/default-graph-colors.constant';
import CALENDAR_DATA_SOURCE from '@/modules/cars/constants/calendar-data-source.constant';
import RatesGraphTooltip from '@/modules/cars/components/rates-graphs/rates-graph-tooltip.vue';

@Component({
    components: {
        CustomGraph, LoaderWrapper, RatesGraphTooltip,
    },
})
export default class PriceHistoryGraphCars extends Vue {
    @inject(KEY.DocumentFiltersService) documentFiltersService!: DocumentFiltersService;
    @inject(UserServiceS) protected userService!: UserService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsPriceHistoryServiceS) carsPriceHistoryService!: CarsPriceHistoryService;

    onTooltipClick(day: Day) {
        const newDay = Number.isNaN(day) ? 0 : day;
        this.$emit('setDay', newDay);
    }

    get chartData(): ChartData {
        const datasets: ChartDataSets[] = [];
        const { competitors, isBrandMode, isBrokerMode } = this.carsFiltersService;
        const { brokersCompetitors } = this.carsService;
        const { isBrokerToBrand, isBrokerToBroker } = this.carsService;
        const { currentCompany } = this.userService;

        if (!this.carsPriceHistoryService.get30Days || !currentCompany) {
            return {
                labels: [],
                datasets: [{ data: [] }],
            };
        }

        if (isBrandMode && !competitors) {
            return {
                labels: [],
                datasets: [{ data: [] }],
            };
        }

        if (isBrokerMode && !brokersCompetitors) {
            return {
                labels: [],
                datasets: [{ data: [] }],
            };
        }

        let companies = [...(competitors || [])];

        if (isBrokerToBroker) {
            companies = [...(brokersCompetitors || [])];
        }

        companies.unshift(currentCompany);
        companies.forEach(nameOfCompany => {
            const data: (number | null)[] = [];
            let company = nameOfCompany;
            this.carsPriceHistoryService.get30Days.forEach(day => {
                const allCars = this.carsService.allCars(day, true, undefined, CALENDAR_DATA_SOURCE.HISTORY) as {
                    [company: string]: CarsPriceHistoryDocumentItemModel
                };
                if (!allCars) {
                    data.push(null);
                    return;
                }

                if (isBrokerToBrand && nameOfCompany === currentCompany) {
                    const search = Object.keys(allCars).find(brand => brand.split(',')[0] === currentCompany);
                    if (search) {
                        company = search;
                    }
                }

                if (isBrokerToBroker) {
                    const search = Object.keys(allCars).find(brand => brand.split(',')[0] === nameOfCompany);
                    if (search) {
                        company = search;
                    }
                }
                const car = allCars[company];
                if (car) {
                    data.push(car.price);
                } else {
                    data.push(null);
                }
            });

            const currentData = this.currentTodayData(nameOfCompany);
            data.push(currentData);

            datasets.push({
                label: String(company),
                data,
                borderColor: this.borderColor(company),
                borderWidth: company.split(',')[0] === currentCompany ? 3 : 2,
                lineTension: 0,
                borderJoinStyle: 'round',
            });
        });

        return {
            labels: this.labels,
            datasets,
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    tension: 0,
                },
                point: {
                    radius: 3,
                    backgroundColor: '#ECF1F5',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                    ticks: {
                        fontSize: 11,
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                    },
                    offset: true,
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    borderColor(companyName: string) {
        const { currentCompany } = this.userService;
        const { isBrokerToBrand, isBrokerToBroker } = this.carsService;

        if (isBrokerToBrand || isBrokerToBroker) {
            const [broker] = companyName.split(',');
            return PROVIDER_COLORS[broker] || GRAPH_COLORS.pop() || '#123456';
        }

        return currentCompany && companyName === currentCompany ? PROVIDER_COLORS[currentCompany] : this.colorByHotel(companyName);
    }

    colorByHotel(companyName: string) {
        return this.carsService.carsGraphColor[companyName];
    }

    get labels() {
        const labels: any[] = [];
        labels.push(...this.carsPriceHistoryService.get30Days.map(day => (day < 10 ? `-0${day}` : `-${day}`)));
        labels.push(['Last', 'Update']);
        return labels;
    }

    currentTodayData(nameOfCompany: string) {
        const date = 0 as Day;
        const allCars = this.carsService.allCars(date, true, undefined, CALENDAR_DATA_SOURCE.HISTORY) as {
            [company: string]: CarsPriceHistoryDocumentItemModel
        };
        let company = nameOfCompany;

        const { isBroker } = this.carsService;

        if (!allCars) {
            return null;
        }
        if (isBroker) {
            const search = Object.keys(allCars).find(brand => brand.split(',')[0] === nameOfCompany);
            if (search) {
                company = search;
            }
        }

        const car = allCars[company];
        if (car) {
            return car.price;
        }

        return null;
    }
}
