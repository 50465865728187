
import CustomRadio, { Item as RadioItem } from '@/modules/common/components/ui-kit/custom-radio.vue';
import CustomSelectBordered, { Item } from '@/modules/common/components/ui-kit/custom-select-bordered.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { $enum } from 'ts-enum-util';
import {
    DAY_CONFIG,
    DAY_TYPE,
    SCHEDULER_CONFIG,
} from '../../../constants';
import type { ISchedulerConfig } from '../../../interfaces';

@Component({
    components: { CustomSelectBordered, CustomRadio },
})
export default class DayFilter extends Vue {
    @Prop({ type: Object, required: true })
    value!: ISchedulerConfig;

    @Prop({ type: Boolean, default: false })
    disabled!: boolean;

    dayType_ = this.value.dayOfMonth !== undefined ? DAY_TYPE.DYNAMIC : DAY_TYPE.SPECIFIC;

    get dayType() {
        return this.dayType_;
    }

    set dayType(value: DAY_TYPE) {
        this.dayType_ = value;
        this.value.dayType = value;
    }

    get dayTypeValue() {
        return this.dayType;
    }

    handleInput(propName: string, value: string | number) {
        this.$emit('input', {
            propName,
            value,
        });
    }

    get isWeekly() {
        return this.value.type === SCHEDULER_CONFIG.WEEKLY;
    }

    get isDynamic() {
        if (this.isWeekly) {
            return true;
        }
        return this.dayType === DAY_TYPE.DYNAMIC;
    }

    get dayItems(): Item[] {
        return [0, 1, 2, 3, 4, 5, 6].map((_, index) => ({
            name: this.$tc(`day.${index}`),
            value: String(index === 0 ? 6 : index - 1),
        }));
    }

    get specificItem(): RadioItem {
        return {
            value: `${DAY_TYPE.SPECIFIC}`,
            name: this.$tc('specific'),
        };
    }

    get dynamicItem(): RadioItem {
        return {
            value: `${DAY_TYPE.DYNAMIC}`,
            name: this.$tc('dynamic'),
        };
    }

    get monthPeriodItems(): Item[] {
        return $enum(DAY_CONFIG).map((value): Item => ({
            value,
            name: this.$tc(value),
        })).filter(item => !!item.value);
    }

    get daysSpecific(): Item[] {
        return [...Array(30).keys()].map((value): Item => ({
            value: value + 1,
            name: this.$tc(`counting.${value}`),
        }));
    }
}
