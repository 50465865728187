import { container } from '@/inversify.container';
import { Route, RouteConfig } from 'vue-router';
import use from '@/router/use';

import AuthMiddleware, { AuthMiddlewareS } from '@/router/middlewares/auth.middleware';
import LevelMiddleware, { LevelMiddlewareS } from '@/router/middlewares/level.middleware';
import CarGuard, { CarGuardS } from '@/router/guards/car.guard';

import OnboardingMiddleware, { OnboardingMiddlewareS } from './middlewares/onboarding.middleware';

import { CommonMenuRoutes, LockedPage } from './modules/common.routes';
import { CarMappingPopup } from './modules/cars.routes';
import HotelBranch from './modules/hotel';
import ClusterBranch from './modules/cluster';
import OnBoardingRoutes from './modules/onboarding.routes';
import DMSRoutes from './modules/dms.routes';
import SettingsPageRoutes from './modules/settings.routes';
import ViewAsMiddleware, { ViewAsMiddlewareS } from './middlewares/view-as.middleware';

function RatesScreenShotHistory(prefix: string) {
    return {
        name: `${prefix}.screenshot-popup`,
        path: 'screenshot/:provider',
        props: (route: Route) => ({
            url: route.params.url,
            date: route.params.date,
            rectangleUniqueness: route.params.rectangleUniqueness,
            provider: route.params.provider,
        }),
        component: () => import('@/modules/cars/pages/rate-screenshot.modal.page.vue'),
        meta: { modal: true },
    };
}

function CarsPriceHistory(prefix: string, noDayParam = false) {
    return {
        name: `${prefix}.price-history-popup`,

        // TODO: Change this param name to avoid route duplicates.
        //       For example: .../day-popup/:day/price-history/:day
        path: `price-history/${!noDayParam ? ':day' : ''}`,
        props: (route: Route) => ({ day: route.params.day }),
        component: () => import('@/modules/cars/pages/cars-price-history.modal.vue'),
        meta: { modal: true },
    };
}

function CarDayPopup(prefix: string) {
    return {
        name: `${prefix}.day-popup`,
        path: 'day-popup/:day',
        component: () => import('@/modules/cars/pages/day-rate.modal.page.vue'),
        meta: { modal: true },
        children: [
            RatesScreenShotHistory(`${prefix}.day-popup`),
            CarsPriceHistory(`${prefix}.day-popup`, true),
        ],
    };
}

function FleetHistory(prefix: string) {
    return {
        name: `${prefix}.fleet-history-popup`,
        path: 'fleet-history/:historyDay',
        props: (route: Route) => ({ historyDay: route.params.historyDay, source: route.params.source }),
        component: () => import('@/modules/cars/modules/cars-price-history/pages/fleet-price-history.modal.vue'),
        meta: { modal: true },
    };
}

function FleetDayPopup(prefix: string) {
    return [
        {
            name: `${prefix}.day-popup`,
            path: 'day-popup/:day',
            props: (route: Route) => ({ day: Number(route.params.day), source: route.params.source }),
            component: () => import('@/modules/cars/modules/fleet/pages/day-rate.modal.page.vue'),
            meta: { modal: true },
            children: [
                FleetHistory(`${prefix}.day-popup`),
            ],
        },
        {
            name: `${prefix}.day-popup-source`,
            path: 'day-popup/:day/:source',
            props: (route: Route) => ({ day: Number(route.params.day), source: route.params.source }),
            component: () => import('@/modules/cars/modules/fleet/pages/day-rate.modal.page.vue'),
            meta: { modal: true },
            children: [
                FleetHistory(`${prefix}.day-popup-source`),
            ],
        },
    ];
}

function ParityComparePopup() {
    return {
        name: 'parity-compare-popup',
        path: 'parity-compare-popup',
        meta: {
            modal: true,
        },
        component: () => import('@/modules/cars/modules/parity/pages/parity-compare.modal.vue'),
    };
}

export default function initRoutes() {
    return [
        {
            path: '/unsubscribe',
            name: 'unsubscribe',
            component: () => import('@/modules/common/pages/unsubscribe.page.vue'),
            props: route => ({ token: route.query.token }),
        },
        {
            path: '/auth',
            name: 'auth',
            component: () => import('@/modules/auth/pages/auth.page.vue'),
            props: (route: Route) => ({ token: route.query.token }),
        },
        {
            path: '/authenticating',
            name: 'authenticating',
            component: () => import('@/modules/auth/pages/auth.page.vue'),
            props: () => ({ postAuth: true }),
        },
        {
            path: '/',
            component: () => import('@/modules/common/layouts/page.layout.vue'),
            beforeEnter: use([
                container.get<AuthMiddleware>(AuthMiddlewareS),
                container.get<OnboardingMiddleware>(OnboardingMiddlewareS),
                container.get<LevelMiddleware>(LevelMiddlewareS),
                container.get<ViewAsMiddleware>(ViewAsMiddlewareS),
            ]),
            children: [
                ClusterBranch(),
                HotelBranch(),
                DMSRoutes(),
                OnBoardingRoutes(),
                SettingsPageRoutes(),

                // NOTE: Cars Zone:
                {
                    path: 'cars-rates',
                    beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                    component: () => import('@/modules/common/pages/transition.page.vue'),
                    children: [
                        {
                            name: 'car-rates-calendar',
                            path: '',
                            component: () => import('@/modules/cars/pages/car-calendar.page.vue'),
                            children: [
                                CarDayPopup('cars-rates'),
                                CarMappingPopup('cars-rates'),
                                ...CommonMenuRoutes('cars-rates'),
                            ],
                        },
                        {
                            name: 'car-rates-table',
                            path: 'table',
                            component: () => import('@/modules/cars/pages/car-table.page.vue'),
                            children: [
                                CarDayPopup('cars-rates.table'),
                                CarMappingPopup('cars-rates.table'),
                                ...CommonMenuRoutes('cars-rates.table'),
                            ],
                        },
                        {
                            name: 'car-rates-graph',
                            path: 'graph',
                            component: () => import('@/modules/cars/pages/car-graph.page.vue'),
                            children: [
                                CarDayPopup('cars-rates.graph'),
                                CarsPriceHistory('cars-rates.graph'),
                                CarMappingPopup('cars-rates.graph'),
                                ...CommonMenuRoutes('cars-rates.graph'),
                            ],
                        },
                        {
                            name: 'analysis.table',
                            path: 'analysis/table',
                            component: () => import('@/modules/cars/pages/car-rates-analysis-table.page.vue'),
                            children: [
                                CarDayPopup('cars-rates.analysis.table'),
                                CarMappingPopup('cars-rates.graph'),
                                ...CommonMenuRoutes('cars-rates.graph'),
                            ],
                        },
                    ],
                },
                {
                    name: 'parity',
                    path: 'parity',
                    beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                    component: () => import('@/modules/cars/modules/parity/pages/brokers.page.vue'),
                    children: [
                        CarMappingPopup('parity'),
                        ...CommonMenuRoutes('parity'),
                    ],
                },
                {
                    path: 'fleet-density',
                    beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                    component: () => import('@/modules/common/pages/transition.page.vue'),
                    children: [
                        {
                            name: 'fleet-density-calendar',
                            path: '',
                            component: () => import('@/modules/cars/modules/fleet/pages/fleet-density.page.vue'),
                            children: [
                                ...FleetDayPopup('fleet-density'),
                                FleetHistory('fleet-density'),
                                ...CommonMenuRoutes('fleet-density'),
                            ],
                        },
                        {
                            name: 'fleet-table',
                            path: 'table',
                            component: () => import('@/modules/cars/modules/fleet/pages/fleet-density-table.page.vue'),
                            children: [
                                ...FleetDayPopup('fleet-density.table'),
                                ...CommonMenuRoutes('fleet-density.table'),
                            ],
                        },
                        {
                            name: 'fleet-graph',
                            path: 'graph',
                            component: () => import('@/modules/cars/modules/fleet/pages/fleet-density-graph.page.vue'),
                            children: [
                                ...FleetDayPopup('fleet-density.graph'),
                                ...CommonMenuRoutes('fleet-density.graph'),
                            ],
                        },
                    ],
                },
                {
                    name: 'parity-provider',
                    path: 'parity/:provider',
                    beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                    component: () => import('@/modules/cars/modules/parity/pages/parity.page.vue'),
                    children: [
                        ParityComparePopup(),
                        CarMappingPopup('parity.provider'),
                        ...CommonMenuRoutes('parity.provider'),
                    ],
                },
                {
                    path: '/car-settings',
                    component: () => import('@/modules/common/pages/transition.page.vue'),
                    props: (r: Route) => ({
                        hotelId: Number(r.params.hotelId),
                    }),
                    children: [
                        {
                            name: '',
                            component: () => import('@/modules/cars/modules/settings/pages/index.page.vue'),
                            path: '',
                            children: [
                                CarMappingPopup('car-settings'),
                                ...CommonMenuRoutes('car-settings'),
                            ],
                        },
                        {
                            name: 'car-settings.rate-alert',
                            component: () => import('@/modules/cars/modules/settings/pages/car-rate-alert-popup.page.vue'),
                            path: 'car-settings.rate-alert/:id?',
                            children: [],
                        },
                    ],
                },
                {
                    name: 'location-availability',
                    path: 'location-availability',
                    beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                    component: () => import('@/modules/cars/modules/location-availability/pages/location-availability.page.vue'),
                    children: [
                        CarMappingPopup('location-availability'),
                        ...CommonMenuRoutes('location-availability'),
                    ],
                },
                // NOTE: Remove this if it's unnecessary
                // {
                //     path: 'fleet-density',
                //     beforeEnter: use([container.get<CarGuard>(CarGuardS)]),
                //     component: () => import('@/modules/cars/modules/tableau/pages/fleet-density.page.vue'),
                // },
                {
                    path: 'forbidden',
                    name: 'forbidden',
                    component: () => import('@/modules/common/pages/forbidden.page.vue'),
                    children: [
                        LockedPage('forbidden'),
                    ],
                },
                {
                    path: '*',
                    component: () => import('@/modules/common/pages/not-found.page.vue'),
                },
            ],
        },
    ] as RouteConfig[];
}
