
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { ChartData, ChartOptions } from 'chart.js';
import ProviderFilter from '@/modules/home/components/home-visibility/provider-filter.vue';
import CompsetFilter from '@/modules/home/components/home-visibility/compset-filter.vue';
import VisibilityGraphLabels from '@/modules/home/components/home-visibility/visibility-graph-labels.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import type HomeService from '@/modules/home/home.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import type Day from '@/modules/common/types/day.type';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component({
    components: {
        Tooltip,
        CustomGraph,
        VisibilityGraphLabels,
        ProviderFilter,
        CompsetFilter,
        LoaderWrapper,
    },
})
export default class VisibilityContainer extends Vue {
    @inject(KEY.HomeService) protected homeService!: HomeService;
    @inject(KEY.DocumentFiltersService) protected documentFiltersService!: DocumentFiltersService;
    @inject(UserServiceS) protected userService!: UserService;

    get areas() {
        const chartData = [];

        const dayThresholds = this.documentFiltersService.days.map(_ => [0, 25, 50, 75, 100]);

        chartData.push(dayThresholds.map(thresholds => thresholds[0]));
        chartData.push(dayThresholds.map(thresholds => thresholds[1]));
        chartData.push(dayThresholds.map(thresholds => thresholds[2]));
        chartData.push(dayThresholds.map(thresholds => thresholds[3]));
        chartData.push(dayThresholds.map(thresholds => thresholds[4]));

        return chartData;
    }

    get skeleton() {
        return this.homeService.storeState.loadingVisibility.isLoading();
    }

    get currentHotelAssessmentsData() {
        const assessments = this.homeService.calculateMyAssessments();

        if (!assessments) {
            return null;
        }

        return assessments.map(assessment => {
            switch (assessment) {
                case (ASSESSMENT_TYPES.BAD):
                    return 12;
                case (ASSESSMENT_TYPES.FAIR):
                    return 37;
                case (ASSESSMENT_TYPES.NORMAL):
                    return 62;
                case (ASSESSMENT_TYPES.GOOD):
                    return 87;
                default:
                    return null;
            }
        });
    }

    get ratesLabels(): Date[] {
        const { year, month } = this.documentFiltersService.storeState.settings;

        return this.documentFiltersService.days.map((day: Day) => new Date(year, month, day));
    }

    get chartData(): ChartData {
        const [min, poorThreshold, medLowThreshold, highThreshold, max] = this.areas;

        return {
            labels: this.ratesLabels,
            datasets: [
                {
                    data: this.currentHotelAssessmentsData || [],
                    borderColor: '#2081AC',
                    fill: false,
                    pointRadius: 4,
                    pointBackgroundColor: 'white',
                    pointBorderWidth: 2,
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'low', // index 1
                    data: min,
                    fill: 2,
                    borderColor: 'white',
                    backgroundColor: '#FBC8CB',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'low-line', // index 2
                    data: poorThreshold,
                    borderWidth: 3,
                    borderColor: '#F14950',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'poor', // index 3
                    data: poorThreshold,
                    fill: 4,
                    borderWidth: 4,
                    borderColor: 'white',
                    backgroundColor: '#FBEFB2',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'poor-line', // index 4
                    data: medLowThreshold,
                    borderWidth: 3,
                    borderColor: '#FFD205',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'med-low', // index 5
                    data: medLowThreshold,
                    fill: 6,
                    borderWidth: 3,
                    borderColor: 'white',
                    backgroundColor: '#B3EAD6',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'med-low-line', // index 6
                    data: highThreshold,
                    borderWidth: 3,
                    borderColor: '#01B875',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'high', // index 7
                    data: highThreshold,
                    fill: 8,
                    borderWidth: 3,
                    borderColor: 'white',
                    backgroundColor: '#80C4AB',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'high-line', // index 8
                    data: max,
                    borderWidth: 3,
                    borderColor: '#018A58',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
            ],
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 0,
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                    ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                        maxTicksLimit: 5, /* we have plugin, that add last sixth tick */
                        callback: value => `${new Date(value).getDate()}/${new Date(value).getMonth() + 1}`,
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: false,
                        zeroLineWidth: 0,

                    },
                    ticks: {
                        display: false,
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
            events: [],
        };
    }

    get marketsRoute() {
        const { userService } = this;

        return {
            name: `${userService.viewAs}.markets`,
            params: { hotelId: userService.currentHotelId, userType: userService.viewAs },
        };
    }
}
