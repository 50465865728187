
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Component, Vue, Prop } from 'vue-property-decorator';
import type InsightFiltersService from '@/modules/document-filters/insight-filters.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import PAGES from '@/modules/common/constants/pages.constant';
import InsightTypeModel from '@/modules/insights/models/insight-type.model';
import InsightsClusterCard from './insights-cluster-card.vue';
import ClusterInsightsModel from '../../../models/cluster-insights.model';
import ClusterInsightsDocumentModel from '../../../models/cluster-insights-document.model';
import type ClusterService from '../../../cluster.service';

@Component({
    components: { InsightsClusterCard },
})
export default class InsightsClusterHotelData extends Vue {
    @inject(KEY.InsightFiltersService) private insightFiltersService!: InsightFiltersService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    @Prop({ type: Object })
    hotelData!: ClusterInsightsModel;

    isDocumentLoading = false;

    get insightTypes() {
        return this.insightFiltersService.options.insightTypes.filter(t => t.isActive);
    }

    get isNoData() {
        return !this.hotelData.compsetMain || !Object.keys(this.hotelData.compsetMain).length;
    }

    cardValue(insightType: string) {
        if (!this.hotelData.compsetMain) {
            return null;
        }

        return this.hotelData.compsetMain[insightType as keyof ClusterInsightsDocumentModel];
    }

    // Lazy load of document, send request only if component is in viewport.
    loadViewportedHotel(isVisible: boolean) {
        if (this.isDocumentLoading || !isVisible || this.hotelData.documentLoaded) {
            return;
        }

        this.isDocumentLoading = true;
        this.clusterService
            .loadDaysData(PAGES.INSIGHTS, this.hotelData.hotelId)
            .finally(() => {
                this.isDocumentLoading = false;
            });
    }

    async handleCardClick(insightType: InsightTypeModel) {
        const { hotelId } = this.hotelData;
        await this.userViewService.goToHotelPage(hotelId);
        this.insightFiltersService.updateSettings({
            insightTypes: [insightType],
            providers: [this.clusterService.currentRatesProvider],
        });
    }
}
