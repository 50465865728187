
import { inject } from '@/inversify';
import { Component, Vue, Prop } from 'vue-property-decorator';

import SCORE_THRESHOLDS from '@/modules/score/constants/score-thresholds.constant';

import type RatesService from '@/modules/rates/rates.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ClusterScoreRankModel from '../../modules/score/models/score-rank.model';

@Component({})
export default class BestWorstHotel extends Vue {
    @inject(KEY.RatesService) ratesService!: RatesService;
    @inject(KEY.DocumentFiltersService) documentFiltersService!: DocumentFiltersService;
    @inject(HelperServiceS) helperService!: HelperService;

    @Prop({
        required: true,
        type: Array,
    })
    hotels!: ClusterScoreRankModel[];

    @Prop({ type: Boolean })
    skeleton!: boolean;

    barColor(score: number) {
        let color = '#EA7877';

        if (score > SCORE_THRESHOLDS.GOOD) {
            color = '#52C161';
        } else if (score > SCORE_THRESHOLDS.FAIR) {
            color = '#8CD446';
        } else if (score > SCORE_THRESHOLDS.POOR) {
            color = '#FFD205';
        } else if (score > SCORE_THRESHOLDS.CRITICAL) {
            color = '#FF9E21';
        }
        return color;
    }

    async goToHotel(hotel: ClusterScoreRankModel) {
        this.ratesService.saveDocument(null);
        this.documentFiltersService.savePos(null);
        await this.$router.push(`/hotel/${hotel.fornovaId}/rates`);
    }
}
