
import { inject } from '@/inversify';
import { Component, Vue } from 'vue-property-decorator';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import HorizontalArrows from './ui-kit/horizontal-arrows.vue';

@Component({
    components: {
        HorizontalArrows,
    },
})
export default class MonthChanger extends Vue {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    get month(): Month {
        return this.documentFiltersService.storeState.settings.month;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    previousMonth() {
        const month = this.month === 0 ? 11 : this.month - 1;
        const year = this.month === 0 ? this.year - 1 : this.year;
        this.updateDateFilter(month as Month, year);
    }

    nextMonth() {
        const month = this.month === 11 ? 0 : this.month + 1;
        const year = this.month === 11 ? this.year + 1 : this.year;
        this.updateDateFilter(month as Month, year);
    }

    updateDateFilter(month: Month, year: Year) {
        this.documentFiltersService.saveMonth(month);
        this.documentFiltersService.saveYear(year);
    }
}
