// Functions here are used as utility for other decorators

import { container } from '@/inversify';
import LoadingModel from '@/modules/common/models/loading.model';
import { routerData } from '@/router/routerData';
import OpenTelemetryService, { OpenTelemetryServiceS } from '../open-telemetry.service';
import { LOGTYPE } from '../constants';

/**
 * Utility method to decorate provided class's constructor with LOADING log.
 * boforeMount hook with startSpan is added and executed first in the queqe.
 * endSpan is executed when all loadings in provided loadingModels are finished or on comonents beforeDestroy hook.
 * @param constructor decorated class's constructor.
 * @param settings payload to add into the log.
 * @param loadingModels array of loading models to track complition of loading.
 */
export function otelBaseComponentLoading(constructor: Function, settings: Record<string, any>, loadingModels: LoadingModel[]) {
    const otelService = container.get<OpenTelemetryService>(OpenTelemetryServiceS);
    const prefix = LOGTYPE.LOADING;
    let name: string;

    // eslint-disable-next-line no-param-reassign
    constructor.prototype.constructor.options.beforeMount ??= [];
    constructor.prototype.constructor.options.beforeMount.unshift(async () => {
        name = routerData.router.currentRoute.name || routerData.router.currentRoute.path;

        otelService.startSpan({ name, prefix });
        otelService.addEvent({ name, prefix }, 'start');
    });

    // eslint-disable-next-line no-param-reassign
    constructor.prototype.constructor.options.mounted ??= [];
    constructor.prototype.constructor.options.mounted.unshift(() => {
        otelService.addEvent({ name, prefix }, 'mounted');
        const promises = loadingModels.map(l => l.whenLoadingFinished());
        Promise.all(promises)
            .then(_ => {
                const payload = {
                    'cx.action.filterBy': otelService.buildFilterBy(settings),
                };
                otelService.addEvent({ name, prefix }, 'gotResponse');
                otelService.addEvent({ name, prefix }, 'finish');
                otelService.endSpan({ name, prefix }, { sendLogs: true, payload });
            });
    });

    // eslint-disable-next-line no-param-reassign
    constructor.prototype.constructor.options.beforeDestroy ??= [];
    constructor.prototype.constructor.options.beforeDestroy.unshift(() => {
        otelService.addEvent({ name, prefix }, 'finish');
        otelService.endSpan({ name, prefix });
    });
}
