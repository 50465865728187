import { Expose } from 'class-transformer';
import { RoomsGroup } from '../types';

export class RoomGroupModel implements RoomsGroup {
    @Expose()
    groupId!: string;

    @Expose({ name: 'group_name' })
    name!: string;

    @Expose({ name: 'new' })
    isNew: boolean = false;

    @Expose({ name: 'isAutoArchived' })
    isAutoArchived!: boolean;

    @Expose({ name: 'mergedRooms' })
    rooms!: string[];
}
