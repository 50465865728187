
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ChartDataSets, ChartOptions, ChartData } from 'chart.js';

import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import ScoreCard from '../_score-card.vue';

@Component({
    components: {
        CustomGraph,
        ScoreCard,
    },
})
export default class ScoreOvertime extends Vue {
    @Prop({ type: Boolean })
    isLoading!: boolean;

    @Prop({ type: Array })
    scoreTrend!: number[] | null;

    ranges = [7, 14, 30, 60];
    currentRange = 30;

    get chartData() {
        if (this.isLoading) {
            return {
                datasets: [],
                labels: [],
            };
        }

        const labels = new Array(this.currentRange * 2 + 1)
            .fill(0)
            .map((_, i) => -this.currentRange + i);

        const mainRvsDataset = {
            data: this.scoreTrend,
            backgroundColor: 'transparent',
            borderColor: CURRENT_HOTEL_GRAPH_COLOR,
            borderWidth: 2,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            lineTension: 0.2,
        } as ChartDataSets;

        return {
            labels,
            datasets: [
                mainRvsDataset,
            ],
        } as ChartData;
    }

    get chartOptions() {
        return {
            maintainAspectRatio: false,
            legend: { display: false },
            tooltips: { enabled: false },
            scales: {
                xAxes: [{
                    gridLines: {
                        drawTicks: false,
                        color: '#CCD1D5',
                    },
                    ticks: {
                        maxTicksLimit: 21,
                        maxRotation: 0,
                        padding: 10,
                        callback: v => (v === 0 ? this.$tc('titles.today') : v),
                    },
                }],
                yAxes: [{
                    gridLines: {
                        color: '#CCD1D5',
                        drawTicks: false,
                    },
                    ticks: {
                        maxTicksLimit: 3,
                        padding: 10,
                    },
                }],
            },
        } as ChartOptions;
    }
}
