
import { Prop, Vue, Component } from 'vue-property-decorator';

export interface Item {
    name: string,
    value: string | number,
    disabled?: boolean
}

@Component
export default class CustomMultiSelect extends Vue {
    @Prop({
        required: false,
        type: Number,
        default: 0,
    })
    private maxSelected!: number;

    @Prop({
        required: true,
        type: Array as () => Item[],
    })
    private items!: Item[];

    @Prop({
        required: false,
        type: Array as () => Item[],
    })
    private value!: Item[] | Item['value'][];

    @Prop({
        required: false,
        type: String,
    })
    label!: string;

    @Prop({
        required: false,
        type: Boolean,
        default: true,
    })
    showButton!: boolean;

    @Prop({
        required: false,
        type: Boolean,
        default: true,
    })
    showSelected!: boolean;

    @Prop({
        type: String,
        required: false,
    })
    title?: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private disabled!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private closeAfterSelect!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    isGearSelect!: boolean;

    /**
     * Changes the emit value type
     * from [{ name: 'Value', value: 1 }] --> [1]
     */
    @Prop({
        type: Boolean,
        default: false,
    })
    emitValuesOnly!: boolean;

    @Prop({
        type: Function,
        default: (n: any) => n,
    })
    private filterSelectAll!: <T>(n: T[]) => T[];

    private dropdownPosition: {
        top?: string,
        left?: string,
        bottom?: string,
    } = {};

    private isExpanded_: boolean = false;

    private get isExpanded() {
        return this.isExpanded_;
    }

    private set isExpanded(value: boolean) {
        this.isExpanded_ = value;
        this.$emit('visible-change', value);
    }

    get isSelectedAll() {
        const allItems = this.filterSelectAll(this.items
            .filter(item => !item.disabled)
            .map(item => item.value));

        const selectedItems = this.emitValuesOnly
            ? this.value as (string | number)[]
            : (this.value as Item[] || [] as Item[]).map(item => item.value);

        return allItems.every(item => selectedItems.includes(item));
    }

    get valueLength() {
        const value = this.value || [];

        if (this.emitValuesOnly) {
            return value.length;
        }

        return (value as Item[]).filter(item => !item.disabled).length;
    }

    get disabledLength() {
        const items = this.items || [];

        return items.filter(item => item.disabled).length;
    }

    mounted() {
        this.setDropdownPosition();
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.handleScroll, true);
        document.body.appendChild(this.$refs.wrapper as any);
    }

    updated() {
        this.setDropdownPosition();
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll, true);
        window.removeEventListener('resize', this.handleScroll, true);
        document.body.removeChild(this.$refs.wrapper as any);
    }

    hide() {
        this.isExpanded = false;
    }

    handleSelectClick() {
        if (this.disabled) return;

        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
            document.body.addEventListener('click', this.clickOutside, true);
        } else {
            document.body.removeEventListener('click', this.clickOutside, true);
        }
    }

    handleOptionClick(clickedValue: Item) {
        const { emitValuesOnly } = this;

        if (this.isDisabled(clickedValue)) {
            return;
        }

        const value = this.value || [];

        const isSelected = emitValuesOnly
            ? (value as Item['value'][]).includes(clickedValue.value)
            : (value as Item[]).some(option => option.value === clickedValue.value);

        const actualClickedValue = emitValuesOnly
            ? clickedValue.value
            : clickedValue;

        let selectedOptions = [...value];

        if (!isSelected) {
            selectedOptions = selectedOptions.concat([actualClickedValue]);
        } else {
            selectedOptions = !emitValuesOnly
                ? (selectedOptions as Item[]).filter(option => option.value !== clickedValue.value)
                : (selectedOptions as Item['value'][]).filter(option => option !== clickedValue.value);
        }

        if (this.closeAfterSelect) {
            this.handleSelectClick();
        }

        this.$emit('input', selectedOptions);
    }

    handleAllClick() {
        let selectedOptions: Item[] | Item['value'][] = [];

        if (!this.isSelectedAll) {
            selectedOptions = !this.emitValuesOnly
                ? [...this.items.filter(item => !item.disabled)]
                : [...this.items.filter(item => !item.disabled).map(item => item.value)];
        } else {
            selectedOptions = [];
        }

        selectedOptions = this.filterSelectAll(selectedOptions as any) as typeof selectedOptions;

        this.$emit('input', selectedOptions);
    }

    isSelected(item: Item) {
        const value = this.value || [];

        if (this.emitValuesOnly) {
            return (value as Item['value'][]).includes(item.value);
        }

        return !!(value as Item[]).find(currentItem => currentItem.value === item.value);
    }

    isDisabled(item: Item) {
        return item.disabled || (this.maxSelected && this.maxSelected === this.valueLength && !this.isSelected(item));
    }

    private handleScroll() {
        this.setDropdownPosition();
    }

    private clickOutside(e: MouseEvent) {
        const ref = this.$refs.multiSelect as HTMLDivElement;
        const path = e.composedPath() as HTMLElement[];

        const isListClick = path
            .some(el => el.className && el.className.includes('list-wrapper'));

        if (isListClick) return;

        if (ref && (ref !== e.target && !ref.contains(e.target as Node | null))) {
            this.isExpanded = false;
            document.body.removeEventListener('click', this.clickOutside);
        }
    }

    private setDropdownPosition() {
        const parentRef = this.$refs.multiSelect as HTMLDivElement;
        if (!parentRef) {
            return;
        }
        const clientRect = parentRef.getBoundingClientRect();

        if (clientRect.bottom + 300 < window.innerHeight) {
            if (this.dropdownPosition.left
            && this.dropdownPosition.top
            && clientRect.left === parseFloat(this.dropdownPosition.left)
            && clientRect.top + clientRect.height === parseFloat(this.dropdownPosition.top)) {
                return;
            }

            this.dropdownPosition = {
                top: `${(clientRect.top + clientRect.height)}px`,
                left: `${(clientRect.left)}px`,
                bottom: undefined,
            };
        } else {
            if (this.dropdownPosition.left
            && this.dropdownPosition.bottom
            && clientRect.left === parseFloat(this.dropdownPosition.left)
            && window.innerHeight - clientRect.top + 20 === parseFloat(this.dropdownPosition.bottom)) {
                return;
            }

            this.dropdownPosition = {
                top: undefined,
                bottom: `${window.innerHeight - clientRect.top + 20}px`,
                left: `${(clientRect.left)}px`,
            };
        }
    }
}
