
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScoreCard from '../_score-card.vue';

@Component({ components: { ScoreCard } })
export default class ScoreTotal extends Vue {
    @Prop({ type: Boolean })
    isLoading!: boolean;

    @Prop({ type: Number })
    score!: number | null;

    @Prop({ type: Number })
    diff!: number | null;

    get isNoData() {
        return !this.isLoading && this.score === null;
    }

    get color() {
        if (this.isLoading) {
            return 'white';
        }

        if (this.isNoData || !this.diff) {
            return 'grey';
        }

        if (this.diff < -5) {
            return 'red';
        }

        if (this.diff > 5) {
            return 'green';
        }

        return 'yellow';
    }

    get message() {
        if (this.isNoData || this.diff === null) {
            return null;
        }

        if (this.diff < 0) {
            return 'rvs.cluster.totalScoreDownMsg';
        }

        if (this.diff > 0) {
            return 'rvs.cluster.totalScoreUpMsg';
        }

        return 'rvs.cluster.totalScoreSameMsg';
    }

    get tooltip() {
        return {
            header: this.$tc('rvs.cluster.totalScoreTitle'),
            text: this.$tc('rvs.cluster.totalScoreHint'),
            position: 'right',
        };
    }
}
