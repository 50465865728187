
import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';

const images = require.context('@/modules/common/assets/', true, /\.png$|\.jpg$|\.svg$/);
const MOBILE_DEVICE_ICON = images('./mobile-icon.svg');
const DESKTOP_DEVICE_ICON = images('./desktop-icon.svg');

@Component
export default class ProviderCard extends Vue {
    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(HelperServiceS) private helperService!: HelperService;

    @Prop({
        type: [String, Array],
    })
    provider!: string | string[];

    @Prop({
        type: Boolean,
        default: true,
    })
    hasLogo!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    skeleton!: boolean;

    @Prop({
        type: Number,
        default: 30,
    })
    logoSize!: number;

    @Prop({
        type: Number,
        default: 18,
    })
    fontSize!: number;

    @Prop({
        type: Number,
        default: 0,
    })
    max!: number;

    @Prop({
        type: String,
        default: '',
    })
    postfix!: string;

    get isList() {
        return typeof this.provider !== 'string';
    }

    get providerList() {
        if (!this.isList) {
            return [];
        }

        if (this.max) {
            return this.provider.slice(0, this.max);
        }

        return this.provider;
    }

    get restOfProviders() {
        if (!this.isList) {
            return [];
        }

        if (this.max) {
            const providersLeft = Math.max(0, this.provider.length - this.max);

            if (!providersLeft) {
                return [];
            }

            return (this.provider as string[])
                .slice(-providersLeft)
                .map(p => this.getProviderLabel(p));
        }

        return [];
    }

    get providersLeft() {
        return this.restOfProviders.length;
    }

    get logo() {
        if (typeof this.provider !== 'string') {
            return this.provider
                .map(p => this.helperService.providerLogo(p));
        }

        switch (this.provider) {
            case 'rentalcars':
                return images('./broker-logos/rentalcars.svg');
            case 'autoeurope':
                return images('./broker-logos/autoeurope.svg');
            default:
                return this.helperService.providerLogo(this.provider);
        }
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (this.userService.isCarUser) {
            return providerName;
        }

        if (!data) {
            return providerName;
        }

        const provider = data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }

    getProviderDeviceIcon(providerName: string) {
        if (!providerName) return '';
        return providerName.includes('mobile')
            ? MOBILE_DEVICE_ICON
            : DESKTOP_DEVICE_ICON;
    }
}
