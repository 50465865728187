
import { Component, Vue } from 'vue-property-decorator';

import AllChannelsSection from '@/modules/di-lite/components/all-channels/all-channels-section.vue';
import MarketSection from '@/modules/di-lite/components/market/market-section.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import DiLiteHeader from '@/modules/di-lite/components/di-lite-header.vue';
import { OtelDiliteHotelPageLoading } from '@/modules/open-telemetry/decorators';

@OtelDiliteHotelPageLoading
@Component({
    components: {
        PageWrapper,
        DiLiteHeader,
        AllChannelsSection,
        MarketSection,
    },
})
export default class DiLitePage extends Vue {}
