
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Component, Vue } from 'vue-property-decorator';

import RatesAnalysisCalendar from '@/modules/rates/components/analysis/calendar/rates-analysis-calendar.vue';
import RatesColorPicker from '@/modules/rates/components/calendar/rates-color-picker.vue';
import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import RatesAnalysisComparison from '@/modules/rates/components/analysis/rates-analysis-filters.vue';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import { OtelRateHotelPageLoading } from '@/modules/open-telemetry/decorators';
import RatesOutOfRangeMessage from '../../components/rates-out-of-range-message.vue';
import RatesAnalysisService, { RatesAnalysisServiceS } from '../../rates-analysis.service';
import type RatesFiltersService from '../../rates-filters.service';
import type RatesService from '../../rates.service';

@OtelRateHotelPageLoading
@Component({
    components: {
        DateDocumentFilter,
        RatesColorPicker,
        RatesAnalysisCalendar,
        RatesActions,
        PageWrapper,
        RatesHeader,
        RatesAnalysisComparison,
        RatesOutOfRangeMessage,
        TogglePrices,
    },
})
export default class RatesAnalysisCalendarPage extends Vue {
    @inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @inject(KEY.RatesFiltersService) private ratesFiltersService!: RatesFiltersService;
    @inject(KEY.RatesService) private ratesService!: RatesService;

    get disableBtn() {
        return this.ratesFiltersService.isProviderAll;
    }

    get isLoading() {
        const { isLoading: ratesLoading } = this.ratesService;
        const { isLoading: ratesAnalysisLoading } = this.ratesAnalysisService;

        return ratesLoading || ratesAnalysisLoading;
    }

    get isOutOfRange() {
        if (this.isAnalysisMode) {
            return this.ratesAnalysisService.isAllOutOfRange()
                || this.ratesService.isOutOfRange();
        }

        return this.ratesService.isOutOfRange();
    }

    get isAnalysisMode() {
        return this.$route.name?.includes('.analysis') ?? false;
    }
}
