
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Component, Vue, Prop } from 'vue-property-decorator';
import RatesPriceHistoryFilters from '@/modules/price-history/components/common-day-popup/rates-price-history-filters.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import type RatesService from '../rates.service';

@Component({
    components: {
        ModalWrapper,
        RatesPriceHistoryFilters,
    },
})
export default class RatesScreenshotPopup extends Vue {
    @inject(KEY.RatesService) private ratesService!: RatesService;

    @Prop({ type: String })
    private url!: string;

    @Prop({ type: String })
    private rectangleUniqueness!: string;

    get isHTML() {
        return this.url.endsWith('html');
    }

    get settings() {
        return this.ratesService.settings;
    }

    onFrameReady(e: Event) {
        const iframe = e.currentTarget as HTMLIFrameElement;
        const frameWindow = iframe.contentWindow;

        if (!frameWindow) return;

        frameWindow.postMessage(this.rectangleUniqueness, '*');
    }

    removeQuery() {
        this.$router.push({
            query: {
                ...this.$route.query,
                url: undefined,
            },
        });
    }
}
