
import { Component, Mixins } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import DiLiteClusterHeader from '@/modules/cluster/components/di-lite/di-lite-cluster-header.vue';
import DiLiteClusterActions from '@/modules/cluster/components/di-lite/di-lite-cluster-actions.vue';
import DiLiteHotelsList from '@/modules/cluster/components/di-lite/di-lite-hotels-list/index.vue';
import ColorPickerGroup from '@/modules/common/components/ui-kit/color-picker-group.vue';
import { COLOR_NAME } from '@/modules/common/constants';
import { OtelDiLiteClusterPageLoading } from '@/modules/open-telemetry/decorators';
import ClusterPageMixin from '../mixins/cluster-page.mixin';
import type ClusterDiLiteService from '../cluster-di-lite.service';

@OtelDiLiteClusterPageLoading
@Component({
    components: {
        PageWrapper,
        DiLiteClusterHeader,
        DiLiteClusterActions,
        DiLiteHotelsList,
        ColorPickerGroup,
    },
})
export default class DiLiteClusterListPage extends Mixins(ClusterPageMixin) {
    @inject(KEY.ClusterDiLiteService) private clusterDiLiteService!: ClusterDiLiteService;

    get clusterColorThresholds() {
        return this.clusterDiLiteService.colorThresholds;
    }

    set clusterColorThresholds(value: [number, number]) {
        this.clusterDiLiteService.colorThresholds = value;
    }

    get colorPickerProps() {
        return {
            colors: [COLOR_NAME.GREEN, COLOR_NAME.RED],
            min: 5,
            max: 30,
        };
    }
}
