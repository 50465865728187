import { container } from '@/inversify';
import OpenTelemetryService, { OpenTelemetryServiceS } from '../open-telemetry.service';
import { LOGTYPE } from '../constants';

/** Decorator for async download excel trigger functions. Only initial request. Not full download of the excel document.
 * @param logName Example: logName === 'dilite', result === 'click:dilite-download'
 * @return decorator
 */
export function otelDownload(logName: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;
        // eslint-disable-next-line no-param-reassign
        descriptor.value = async (...args: any[]) => {
            let name = logName;

            if (logName === 'market' && args[0].provider) {
                name += `-${args[0].provider}-download`;
            } else if (logName === 'rates') {
                if (args[0].compareTo) {
                    name += '-analysis';
                }

                if (args[1]) {
                    name += '-send-email';
                } else if (args[2]) {
                    name += '-demand-and-email';
                } else {
                    name += '-download';
                }
            } else {
                name += '-download';
            }

            const otelService = container.get<OpenTelemetryService>(OpenTelemetryServiceS);
            otelService.startSpan({ name, prefix: LOGTYPE.CLICK });

            try {
                const result = await originalMethod.apply(target, args);
                otelService.endSpan({ name, prefix: LOGTYPE.CLICK }, { sendLogs: true });
                return result;
            } catch (err) {
                otelService.endSpan(
                    { name, prefix: LOGTYPE.CLICK },
                    { sendLogs: true, payload: { 'cx.action.error': (err as Error).message } },
                );
                throw err;
            }
        };

        return descriptor;
    };
}

/** Decorator for async on demand trigger functions. Only initial request.
 * @param logName Example: logName === 'dilite', result === 'click:dilite-ondemand'
 * @return decorator
 */
export function otelOnDemand(logName: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;
        const name = `${logName}-ondemand`;

        // eslint-disable-next-line no-param-reassign
        descriptor.value = async (...args: any[]) => {
            const otelService = container.get<OpenTelemetryService>(OpenTelemetryServiceS);
            otelService.startSpan({ name, prefix: LOGTYPE.CLICK });

            try {
                const result = await originalMethod.apply(target, args);
                otelService.endSpan({ name, prefix: LOGTYPE.CLICK }, { sendLogs: true });
                return result;
            } catch (err) {
                otelService.endSpan(
                    { name, prefix: LOGTYPE.CLICK },
                    { sendLogs: true, payload: { 'cx.action.error': (err as Error).message } },
                );
                throw err;
            }
        };

        return descriptor;
    };
}

/** Decorator for async schedule functions.
 * @param logName Example: logName === 'rates', result === 'click:rates-schedule'
 * @return decorator
 */
export function otelSchedule(logName: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;

        // eslint-disable-next-line no-param-reassign
        descriptor.value = async (...args: any[]) => {
            let name = logName;

            if (args[1]) {
                name += `-${(args[1].properties.dataType as string).toLowerCase().split(' ').join('-')}`;
            } else if (typeof args[0] !== 'string') {
                name += `-${(args[0].properties.dataType as string).toLowerCase().split(' ').join('-')}`;
            }

            const otelService = container.get<OpenTelemetryService>(OpenTelemetryServiceS);
            otelService.startSpan({ name, prefix: LOGTYPE.CLICK });

            try {
                const result = await originalMethod.apply(target, args);
                otelService.endSpan({ name, prefix: LOGTYPE.CLICK }, { sendLogs: true });
                return result;
            } catch (err) {
                otelService.endSpan(
                    { name, prefix: LOGTYPE.CLICK },
                    { sendLogs: true, payload: { 'cx.action.error': (err as Error).message } },
                );
                throw err;
            }
        };

        return descriptor;
    };
}
