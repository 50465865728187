
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import type MarketsFiltersService from '@/modules/markets/markets-filters.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';

@Component({
    components: { CustomSelect },
})
export default class PosMarketFilter extends Vue {
    @inject(KEY.MarketsFiltersService) private marketsFiltersService!: MarketsFiltersService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    get pos() {
        this.marketsFiltersService.verifyPos();
        return this.documentFiltersService.storeState.settings.pos;
    }
    set pos(value) {
        this.documentFiltersService.storeState.settings.pos = value;
    }
    get posItems(): Item[] {
        return this.documentFiltersService.getPosItems(null);
    }
}
