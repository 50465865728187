
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import type RatesFiltersService from '@/modules/rates/rates-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import type RatesService from '@/modules/rates/rates.service';

import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import type Day from '@/modules/common/types/day.type';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';
import formatDate from '@/modules/common/filters/format-date.filter';
import RatesScanTooltip from '@/modules/rates/components/rates-scans-tooltip.vue';
import type ClusterService from '@/modules/cluster/cluster.service';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';

@Component({
    components: {
        ScanBtnSmall,
        RatesScanTooltip,
    },
})
export default class RatesScanBtn extends Vue {
    @inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @inject(KEY.RatesFiltersService) private ratesFiltersService!: RatesFiltersService;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;

    @Prop({ type: Number, default: () => null })
    private day!: Day | null;

    @Prop({ type: Object, default: () => null })
    private data!: RatesDocumentModel | null;

    @Prop({ type: Object, default: () => null })
    private settings!: RatesSettingsModel | null;

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    @Prop({ type: Boolean, default: () => false })
    showScanDate!: boolean;

    @Prop({ type: Date })
    lastScan!: Date | undefined;

    @Prop({
        type: String,
        default: '',
    })
    compsetId!: string;

    tooltipPos: HTMLDivElement | null = null;

    get scanStatus() {
        if (this.userService.isViewAsHotel) {
            return this.ratesService.scan.values.status || null;
        }

        const doc = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(this.hotelId);
        return doc?.scan.status || null;
    }

    get hotelId() {
        return +this.$route.params.hotelId || this.userService.currentHotelId!;
    }

    get isScanning() {
        return this.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get isDisabled() {
        if (this.disabled) return true;

        const isCompsetActive = this.userService.isViewAsHotel
            ? this.compsetsService.getCompset(this.compsetId)?.isActive
            : this.clusterCompsetsService.getCompsetById(this.compsetId)?.isActive;

        if (!isCompsetActive) return true;

        const { scanLimitation } = this.ratesCommonService;
        const notAbleToScan = this.day
            ? !this.ratesCommonService.isScanAvailable(this.settings, this.day)
            : false;

        const haveNoScans = scanLimitation && scanLimitation.remaining === 0;

        return notAbleToScan || haveNoScans;
    }

    async handleClick() {
        if (this.isScanning || this.isDisabled || (!this.data && !this.compsetId)) {
            return;
        }

        this.$emit('triggerScan', this.day);
    }

    get filterAll() {
        return this.ratesFiltersService.isProviderAll;
    }

    get formatScanDate() {
        const emptyDate = '--/--/--';

        if (this.lastScan) return formatDate(this.lastScan);

        if (!this.day || !this.data) {
            return emptyDate;
        }

        const doc = this.ratesCommonService.getUpdateDate(this.day, this.data);

        if (doc === null) {
            return emptyDate;
        }

        return formatDate(doc);
    }
}
