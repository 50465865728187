import { render, staticRenderFns } from "./markets-calendar-item-no-data.vue?vue&type=template&id=37a22e33&scoped=true&"
import script from "./markets-calendar-item-no-data.vue?vue&type=script&lang=ts&"
export * from "./markets-calendar-item-no-data.vue?vue&type=script&lang=ts&"
import style0 from "./markets-calendar-item-no-data.vue?vue&type=style&index=0&id=37a22e33&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a22e33",
  null
  
)

export default component.exports