
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import type InsightsService from '../../insights.service';
import InsightGroupModel from '../../models/insight-group.model';
import { Rooms, RoomsInsight } from '../../types';

@Component
export default class DayRoomsTable extends Vue {
    @inject(UserServiceS) private userService!: UserService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(KEY.InsightsService) private insightsService!: InsightsService;

    get groupData() {
        return this.insightsService.dayGroup as InsightGroupModel<RoomsInsight> | null;
    }

    get dayData() {
        if (!this.groupData?.insights) {
            return [];
        }

        return Object.entries(this.groupData.insights).map(([id, insight]) => ({
            insightId: id,
            fornovaId: insight.fornovaId!,
            name: insight.hotelName || String(insight.fornovaId),
            provider: this.providersService.getProviderLabel(insight.provider!),
            roomType: this.roomTypesService.roomName(parseInt((insight.rooms as Rooms)[0].type!, 10)) || '',
            rooms: insight.rooms,
        }));
    }

    isMyHotel(id: string | number) {
        return this.$route.params.hotelId === String(id);
    }

    handleSeeMore(insightId: string) {
        if (!this.groupData?.insights[insightId]) {
            return;
        }

        const { fornovaId, rooms, provider } = this.groupData.insights[insightId];

        sessionStorage.setItem('fornovaId', String(fornovaId));
        sessionStorage.setItem('rooms', JSON.stringify({
            source: provider as string,
            roomTypeIds: rooms.map(r => r.type),
            roomNames: rooms.map(r => r.name),
        }));

        const tab = this.userService.isAdmin ? '1' : '0';

        this.$router.push({ name: 'hotel.settings', query: { tab }, params: { ...this.$route.params } });
    }
}
