import { inject, injectable } from '@/inversify';
import _ from 'lodash';
import DI_PROVIDERS from '@/modules/di-lite/constants/di-providers';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import DiLiteStore from '@/modules/di-lite/store/di-lite.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import DiLiteMarketApiService, { DiLiteMarketApiServiceS, DILiteDownloadExcelForm } from '@/modules/di-lite/di-lite-market-api.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import CustomNotificationService, { CustomNotificationServiceS } from '../common/modules/custom-notification/custom-notification.service';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';

export const DiLiteMarketServiceS = Symbol.for('DiLiteMarketServiceS');
@injectable()
export default class DiLiteMarketService implements Stateable {
    @inject(DiLiteMarketApiServiceS) private diLiteMarketApiService!: DiLiteMarketApiService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;

    readonly storeState: DiLiteStore = this.storeFacade.getState('DiLiteStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.compsetId,
        ], ((newValue, oldValue) => {
            const [newCompsetId] = newValue;
            const [currentCompsetId] = oldValue;

            if (newCompsetId && JSON.stringify(currentCompsetId) !== JSON.stringify(newCompsetId)) {
                return this.resetLoading.call(this);
            }
            return {};
        }
        ));
    }

    resetLoading() {
        this.storeState.trLoading.reset();
        this.storeState.rcaLoading.reset();
        this.storeState.bmlLoading.reset();
    }

    async loadTrData(): Promise<boolean> {
        if (!this.marketId || !this.mealTypesService.mealTypes.length) {
            return false;
        }

        const trData = await this.diLiteMarketApiService.getTestReservationData([Number(this.marketId)]);

        if (trData && !_.isEmpty(trData)) {
            this.storeState.trData = trData;
        } else {
            this.storeState.trData = null;
        }

        return true;
    }

    async loadRcaData(): Promise<boolean> {
        if (!this.marketId) {
            return false;
        }

        const rcaData = await this.diLiteMarketApiService.getRcaData(this.providers, [Number(this.marketId)]);

        if (rcaData && !_.isEmpty(rcaData)) {
            this.storeState.rcaData = rcaData;
        } else {
            this.storeState.rcaData = null;
        }

        return true;
    }

    async loadBmlData(): Promise<boolean> {
        if (!this.marketId) {
            return false;
        }

        const bmlData = await this.diLiteMarketApiService.getBmlData(this.providers, [Number(this.marketId)]);

        if (bmlData) {
            this.storeState.bmlData = bmlData;
        }

        return true;
    }

    async downloadExcel(form: DILiteDownloadExcelForm, hotelId: number): Promise<boolean> {
        this.storeState.excelLoading.start();
        const excelData = await this.diLiteMarketApiService.downloadExcel(form, hotelId);
        this.storeState.excelLoading.finish();

        if (excelData === null) {
            throw new Error('No data for selected date range!');
        }

        this.customNotificationService.handleExcel(excelData);
        return true;
    }

    get providers() {
        return this.compset ? this.compset.marketProviders.map((ciProvider: string) => {
            const diName = this.providersService.getDIName(ciProvider) || DI_PROVIDERS[ciProvider];
            return diName || ciProvider.charAt(0).toUpperCase() + ciProvider.slice(1);
        }) : [];
    }

    get isExcelLoading() {
        return this.storeState.excelLoading.isLoading();
    }

    get compset() {
        const { compsets } = this.compsetsService;
        if (compsets && compsets.length) {
            return compsets[0];
        }
        return null;
    }

    get compsetId() {
        if (!this.compset) {
            return null;
        }

        return this.compset.id;
    }

    get marketId() {
        if (!this.compset) {
            return null;
        }

        return this.compset.marketId;
    }

    get trData() {
        this.helperService.dynamicLoading(this.storeState.trLoading, this.loadTrData.bind(this));
        return this.storeState.trData;
    }

    get bmlData() {
        this.helperService.dynamicLoading(this.storeState.bmlLoading, this.loadBmlData.bind(this));
        return this.storeState.bmlData;
    }

    get rcaData() {
        this.helperService.dynamicLoading(this.storeState.rcaLoading, this.loadRcaData.bind(this));
        return this.storeState.rcaData;
    }

    get isTrLoading(): boolean {
        return this.storeState.trLoading.isLoading();
    }

    get isRcaLoading(): boolean {
        return this.storeState.rcaLoading.isLoading();
    }

    get isBmlLoading(): boolean {
        return this.storeState.bmlLoading.isLoading();
    }

    get trLoading() {
        return this.storeState.trLoading;
    }

    get rcaLoading() {
        return this.storeState.rcaLoading;
    }

    get bmlLoading() {
        return this.storeState.bmlLoading;
    }
}
