import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type ClusterService from '@/modules/cluster/cluster.service';

/**
 * Base mixin for cluster pages.
 * Resets cluster loadings.
 */
@Component
export default class ClusterPage extends Vue {
    @inject(KEY.ClusterService) protected clusterService!: ClusterService;

    beforeMount() {
        // Resets cluster loading and deletes all hotels from the store if stored hotels are from another page
        const prevMode = this.clusterService.hotelsMode;
        const newMode = this.$route.name?.split('.')[1];
        if (prevMode !== newMode) {
            this.clusterService.resetLoading();
        }
    }
}
