var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",staticClass:"promotions-mark-table",on:{"mousedown":_vm.startDragScroll}},[_c('PromotionsCalendar',{ref:"calendarTooltip",attrs:{"focusElement":_vm.tooltipFocusElement},on:{"cellover":_vm.setPercentTooltipElement,"cellout":_vm.resetPercentTooltipElement}}),_c('PromotionsPercentTooltip',{ref:"percentTooltip",attrs:{"focusElement":_vm.tooltipPercentFocusElement}}),_c('div',{staticClass:"programs-column",on:{"click":_vm.resetFocusElement}},[_c('div',{staticClass:"programs-column__row"},[_c('div'),_c('div',[_c('span',{class:{ skeleton: _vm.skeleton },attrs:{"title":_vm.getHotelName(_vm.currentHotelId, true)}},[_vm._v(" "+_vm._s(_vm.getHotelName(_vm.currentHotelId))+" ")])])]),_vm._l((_vm.providerList),function(provider){return [_c('div',{key:'provider-row' + provider,staticClass:"programs-column__row programs-column__row--provider"},[_c('div',{class:{
                    'programs-column__provider-container': true,
                    'skeleton-container skeleton-container--gray': _vm.isLoading,
                }},[_c('ProviderCard',{attrs:{"provider":provider,"skeleton":_vm.skeleton,"postfix":_vm.$tc('titles.programs').toLowerCase()}})],1)]),_vm._l((_vm.getProgramList(provider)),function(program){return _c('div',{key:'promotion-row' + provider + program,class:{
                    'programs-column__row': true,
                    'skeleton-container skeleton-container--gray': _vm.isLoading,
                }},[_c('div',[_c('div',{staticClass:"programs-column__logo-container"},[_c('img',{attrs:{"src":_vm.getProgramLogo(program)}})]),_c('span',{class:{ skeleton: _vm.skeleton }},[_vm._v(" "+_vm._s(_vm.getProgramLabel(program))+" ")])]),_c('div',[_c('PromotionsMark',{attrs:{"hotelId":_vm.currentHotelId,"program":program,"provider":provider},nativeOn:{"mouseover":function($event){return _vm.setFocusElement.apply(null, arguments)}}})],1)])})]})],2),_vm._l((_vm.competitors),function(hotelId,i){return _c('div',{key:hotelId + i,staticClass:"competitor-column",on:{"click":_vm.resetFocusElement}},[_c('div',{staticClass:"competitor-column__row"},[_c('div'),_c('div',{class:{
                    'skeleton-container skeleton-container--gray': _vm.isLoading,
                }},[_c('span',{class:{ skeleton: _vm.skeleton },attrs:{"title":_vm.getHotelName(hotelId, true)}},[_vm._v(" "+_vm._s(_vm.getHotelName(hotelId))+" ")])])]),_vm._l((_vm.providerList),function(provider,d){return [_c('div',{key:'competitor' + hotelId + 'provider-row' + provider + '-' + d,staticClass:"competitor-column__row competitor-column__row--provider"}),_vm._l((_vm.getProgramList(provider)),function(program,d2){return _c('div',{key:'competitor' + hotelId + 'program-row' + provider + program + d2,staticClass:"competitor-column__row"},[_c('PromotionsMark',{attrs:{"hotelId":hotelId,"program":program,"provider":provider},nativeOn:{"mouseover":function($event){return _vm.setFocusElement.apply(null, arguments)}}})],1)})]})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }