
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import Item from '@/modules/common/interfaces/item.interface';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import type InsightFiltersService from '@/modules/document-filters/insight-filters.service';
import type InsightsService from '../insights.service';
import InsightTypeModel from '../models/insight-type.model';

@Component({
    components: {
        CustomMultiSelect,
    },
})
export default class InsightsFilters extends Vue {
    @inject(KEY.InsightsService) private insightsService!: InsightsService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(KEY.InsightFiltersService) private insightFiltersService!: InsightFiltersService;

    get insightsTypesItems() {
        return this.insightFiltersService.options.insightTypes
            .filter(item => item.isActive);
    }

    get insightsTypes() {
        return this.insightFiltersService.settings.insightTypes || [];
    }

    set insightsTypes(value: InsightTypeModel[]) {
        this.insightFiltersService.updateSettings({ insightTypes: value });
    }

    get providerItems() {
        return this.insightFiltersService.options.providers;
    }

    get providers() {
        return this.insightFiltersService.settings.providers?.map(p => ({ name: p, value: p })) || [];
    }

    set providers(providers: Item[]) {
        this.insightFiltersService.updateSettings({ providers: providers.map(p => p.value as string) });
    }

    get isLoading() {
        return this.insightFiltersService.loading.insightTypes.isLoading() || this.compsetsService.isLoading;
    }
}
