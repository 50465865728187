import { container } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type RatesFiltersService from '@/modules/rates/rates-filters.service';
import type RatesService from '@/modules/rates/rates.service';
import type MarketsFiltersService from '@/modules/markets/markets-filters.service';
import type MarketsService from '@/modules/markets/markets.service';
import type RankingService from '@/modules/ranking/ranking.service';
import type DiLiteAllChannelsService from '@/modules/di-lite/di-lite-all-channels.service';
import type PromotionsService from '@/modules/promotions/promotions.service';
import type InsightFiltersService from '@/modules/document-filters/insight-filters.service';
import type InsightsService from '@/modules/insights/insights.service';
import type DeepCompsetAnalysisService from '@/modules/deep-analysis/deep-analysis.service';
import type HomeService from '@/modules/home/home.service';
import type DiLiteMarketService from '@/modules/di-lite/di-lite-market.service';
import { otelBaseComponentLoading } from '../_utils';

/**
 * Add logs to a hotel rates page Vue components
 */
export function OtelRateHotelPageLoading(constructor: Function) {
    const ratesService = container.get<RatesService>(KEY.RatesService);
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);
    const ratesFiltersService = container.get<RatesFiltersService>(KEY.RatesFiltersService);

    const settings = {
        ...ratesFiltersService.settings,
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const { loading } = ratesService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a hotel market page Vue components
 */
export function OtelMarketHotelPageLoading(constructor: Function) {
    const marketsService = container.get<MarketsService>(KEY.MarketsService);
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);
    const marketsFiltersService = container.get<MarketsFiltersService>(KEY.MarketsFiltersService);

    const settings = {
        ...documentFiltersService.settings,
        ...marketsFiltersService.storeState.settings,
    } as Record<string, any>;

    const { loading } = marketsService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a hotel ranking page Vue components
 */
export function OtelRankingHotelPageLoading(constructor: Function) {
    const rankingService = container.get<RankingService>(KEY.RankingService);
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);

    const filterToBuild = ['compsetId', 'los', 'pos', 'year', 'month'];
    const settingsUnion = {
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const settings = filterToBuild.reduce((acc, key) => {
        acc[key] = settingsUnion[key];
        return acc;
    }, {} as any);

    const { loading } = rankingService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a hotel di lite page Vue components
 */
export function OtelDiliteHotelPageLoading(constructor: Function) {
    const diLiteAllChannelsService = container.get<DiLiteAllChannelsService>(KEY.DiLiteAllChannelsService);
    const diLiteMarketService = container.get<DiLiteMarketService>(KEY.DiLiteMarketService);
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);

    const filterToBuild = ['compsetId', 'month', 'year', 'priceType', 'los', 'pos', 'numberOfGuests', 'roomTypeId', 'mealTypeId'];
    const settingsUnion = {
        ...diLiteAllChannelsService.settings,
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const settings = filterToBuild.reduce((acc, key) => {
        acc[key] = settingsUnion[key];
        return acc;
    }, {} as any);

    const { loading } = diLiteAllChannelsService;
    const { trLoading, rcaLoading, bmlLoading } = diLiteMarketService;
    otelBaseComponentLoading(constructor, settings, [loading, trLoading, rcaLoading, bmlLoading]);
}

/**
 * Add logs to a hotel promotions page Vue components
 */
export function OtelPromotionsHotelPageLoading(constructor: Function) {
    const promotionsService = container.get<PromotionsService>(KEY.PromotionsService);
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);

    const settings = {
        ...promotionsService.settings,
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const { loading } = promotionsService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a hotel promotions page Vue components
 */
export function OtelInsightsHotelPageLoading(constructor: Function) {
    const insightFiltersService = container.get<InsightFiltersService>(KEY.InsightFiltersService);
    const insightsService = container.get<InsightsService>(KEY.InsightsService);

    const settings = {
        ...insightFiltersService.settings,
    } as Record<string, any>;

    const { insightsLoading: loading } = insightsService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a hotel benchmark page Vue components
 */
export function OtelBenchmarkHotelPageLoading(constructor: Function) {
    const dcaService = container.get<DeepCompsetAnalysisService>(KEY.DeepCompsetAnalysisService);
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);

    const settings = {
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const { loading } = dcaService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a hotel home page Vue components
 */
export function OtelHomeHotelPageLoading(constructor: Function) {
    const homeService = container.get<HomeService>(KEY.HomeService);
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);

    const filterToBuild = ['month', 'year']; // Add benchmark specific filters
    const settingsUnion = {
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const settings = filterToBuild.reduce((acc, key) => {
        acc[key] = settingsUnion[key];
        return acc;
    }, {} as any);

    const { loadingRates, loadingVisibility, loadingDemand } = homeService;
    otelBaseComponentLoading(constructor, settings, [loadingRates, loadingVisibility, loadingDemand]);
}
