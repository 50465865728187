import { inject } from '@/inversify';
import { Vue } from 'vue-property-decorator';

import CompsetModel from '@/modules/compsets/models/compset.model';
import Item from '@/modules/common/interfaces/item.interface';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import type PromotionsService from '../promotions.service';
import { PromotionGraphType } from '../models/promotions-settings.model';

export default class PromotionsFiltersMixin extends Vue {
    @inject(CompsetsServiceS) protected compsetsService!: CompsetsService;
    @inject(KEY.PromotionsService) protected promotionsService!: PromotionsService;
    @inject(ProvidersServiceS) protected providersService!: ProvidersService;
    @inject(KEY.DocumentFiltersService) documentFiltersService!: DocumentFiltersService;
    @inject(HotelsServiceS) protected hotelsService!: HotelsService;

    protected get graphTypeItems() {
        return [
            {
                value: PromotionGraphType.DEALS,
                name: this.$tc('promotions.graphType.deals'),
            },
            {
                value: PromotionGraphType.PROGRAMS,
                name: this.$tc('promotions.graphType.programs'),
            },
        ];
    }

    protected get provider() {
        return this.promotionsService.provider;
    }

    protected set provider(value: string | null) {
        this.promotionsService.provider = value;
    }

    protected get graphType() {
        return this.promotionsService.graphType;
    }

    protected set graphType(value: PromotionGraphType) {
        this.promotionsService.graphType = value;
    }

    protected get providerItems() {
        const { data } = this.promotionsService;

        if (!data) return [];

        const list = Object
            .keys(data.providerData)
            .map(provider => ({
                name: this.providersService.getProviderLabel(provider),
                value: provider,
            }));

        const isChoosenProviderExist = !!list.find(item => item.value === this.provider);

        if (!this.provider || !isChoosenProviderExist) {
            this.provider = list[0]?.value || 'booking';
        }

        return list;
    }

    protected get competitors() {
        return this.documentFiltersService.competitors!;
    }

    protected set competitors(newCompetitors: number[]) {
        this.documentFiltersService.competitors = newCompetitors;
    }

    protected get competitorsItems() {
        const { data } = this.promotionsService;
        const { competitors } = this.compsetsService;

        if (!data) return [];

        if (!competitors) return [];

        return competitors
            .map(hotelId => ({
                name: this.hotelsService.getHotelName(hotelId),
                value: hotelId,
            }));
    }

    protected get viewItems() {
        const { data, provider } = this.promotionsService;
        if (!data || !provider) return [];

        const programList = (data.promotions[provider] || [])
            .filter(program => (this.graphType === PromotionGraphType.DEALS
                ? program.startsWith('percentage')
                : !program.startsWith('percentage')));

        if (!programList.includes(this.programView)) {
            this.programView = '';
        }

        const items = programList
            .map(program => ({
                name: this.$tc(`promotions.program.${program}`),
                value: program,
            }));

        const isAnalysisView = (() => {
            if (!this.$route.name) return false;
            return this.$route.name!.includes('analysis');
        })();

        if (!isAnalysisView) {
            if (items.length > 1) {
                items.splice(0, 0, {
                    name: this.$tc('filters.all'),
                    value: '',
                });
            } else if (items.length > 0) {
                this.programView = items[0].value;
            }
        } else if (this.programView === '' && items.length > 0) {
            this.programView = items[0].value;
        }

        return items;
    }

    protected get compsetItems(): Item[] {
        if (!this.compsetsService.compsets) {
            return [];
        }

        const getCompsetTypeLabel = (compset: CompsetModel) => this
            .$t(`compset.${compset.type}`);

        return this.compsetsService.compsets
            .filter(c => c.ownerHotelId === Number(this.$route.params.hotelId))
            .map((compset: CompsetModel) => ({
                value: compset.id,
                name: `${compset.name} (${getCompsetTypeLabel(compset)})`,
            }));
    }

    get compsetId() {
        return this.documentFiltersService.settings.compsetId;
    }

    set compsetId(value) {
        if (value) {
            this.documentFiltersService.updateCompset(value);
        }
    }

    get programView() {
        return this.promotionsService.programView;
    }

    set programView(value: string) {
        this.promotionsService.programView = value;
    }
}
