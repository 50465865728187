
import { Vue, Component } from 'vue-property-decorator';
import { container } from '@/inversify';
import CalendarsGroup from '@/modules/events/components/calendars-group.vue';
import EventsCalendar from '@/modules/events/components/events-manager/events-calendar.vue';
import EventsActions from '@/modules/events/components/events-actions.vue';
import EventsHeader from '@/modules/events/components/events-manager/events-header.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import { otelBaseComponentLoading } from '@/modules/open-telemetry/decorators/_utils';
import EventsManagerService, { EventsManagerServiceS } from '../events-manager.service';

/**
 * Add logs to a hotel events page Vue components. Cluster page renders same component, so it is used on both levels.
 * Causes circular dependency if placed into open-telemetry/decorators. Needs to investigate.
 */
function OtelEventsHotelPageLoading(constructor: Function) {
    const eventsManagerService = container.get<EventsManagerService>(EventsManagerServiceS);
    const settings = {};

    const { eventsSetLoading: loading } = eventsManagerService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

@OtelEventsHotelPageLoading
@Component({
    components: {
        CalendarsGroup,
        EventsCalendar,
        EventsActions,
        PageWrapper,
        EventsHeader,
    },
})
export default class EventsManagerNewPage extends Vue {}
