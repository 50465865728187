
import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import CustomTooltipV2 from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import SortingButtons from '@/modules/common/components/ui-kit/sorting-buttons.vue';
import STATISTIC_NAMES from '@/modules/deep-analysis/constants/statistic-names.constant';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import Pagination from '@/modules/common/components/ui-kit/pagination.vue';
import type ClusterService from '@/modules/cluster/cluster.service';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '../../../cluster-deep-analysis.service';
import TotalIndicator from './deep-analysis-total-indicator.vue';
import Skeleton from '../../_common/hotels-list/skeleton.vue';
import type TotalIndicatorData from '../../../interfaces/da-total-indicator.interface';
import HotelLine from './hotel-line.vue';

@Component({
    components: {
        TotalIndicator,
        SortingButtons,
        CustomTooltipV2,
        Pagination,
        Skeleton,
        HotelLine,
    },
})
export default class CompsetBenchmarkTable extends Vue {
    @inject(DeepCompsetAnalysisClusterServiceS) dcaClusterService!: DeepCompsetAnalysisClusterService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;

    private readonly STATISTICS_WITH_SORTING = [STATISTIC_TYPE.REVENUE];
    hint: HTMLElement | null = null;
    hintData: Record<string, string | number> | null = null;

    get isLoading() {
        return this.clusterService.isLoading;
    }

    get isStatsLoading() {
        return this.dcaClusterService.isStatsLoading;
    }

    get statistics() {
        return this.dcaClusterService.statistics;
    }

    get sortingType() {
        return this.dcaClusterService.sortingType;
    }

    get statisticNames() {
        return STATISTIC_NAMES;
    }

    get sortingKey() {
        return this.dcaClusterService.sortingKey;
    }

    get calculatedFields() {
        return this.dcaClusterService.calculatedFields;
    }

    get hotels() {
        const { hotels } = this.dcaClusterService;

        if (!hotels || !hotels.length) {
            return [];
        }

        return hotels;
    }

    totalStatValue(stat: STATISTIC_TYPE) {
        const statValues = this.dcaClusterService.clusterStatisticValues;
        if (!statValues) {
            return null;
        }

        return statValues[stat];
    }

    isSortingEnabled(statistic: STATISTIC_TYPE) {
        return this.STATISTICS_WITH_SORTING.includes(statistic);
    }

    handleHover(e: MouseEvent | UIEvent, totalData?: TotalIndicatorData) {
        if (!totalData) {
            this.hint = null;
            return;
        }

        this.hint = e.target as HTMLElement;
        this.hintData = {
            header: totalData.name,
            increased: totalData.increase,
            decreased: totalData.decrease,
            noChange: totalData.noChange,
        };
    }

    sort(e: {
        key: STATISTIC_TYPE,
        type: -1 | 1
    }) {
        this.dcaClusterService.sort(e.type, e.key);
    }
}
