
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';

@Component
export default class PageWrapper extends Vue {
    @inject(UserServiceS) private userService!: UserService;
    @inject(CarsFiltersServiceS) private carsFilterService!: CarsFiltersService;

    @Prop({ type: Boolean, default: false })
    ignorePageViewRouter!: boolean;

    get showCarFooter() {
        if (!this.userService.isCarUser) {
            return false;
        }
        if (!this.carsFilterService.settings.theme) {
            return false;
        }

        return !!this.carsFilterService.settings.theme.logo;
    }
}
