
import { Component, Vue } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import { OtelInsightsHotelPageLoading } from '@/modules/open-telemetry/decorators';
import InsightsHeader from '../components/insights-header.vue';
import InsightsFilters from '../components/insights-filters.vue';
import InsightsGrid from '../components/insights-grid.vue';

@OtelInsightsHotelPageLoading
@Component({
    components: {
        PageWrapper,
        InsightsHeader,
        InsightsFilters,
        InsightsGrid,
    },
})
export default class InsightsPage extends Vue {}
