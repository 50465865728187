

import { Component, Vue } from 'vue-property-decorator';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { inject } from '@/inversify';

import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import type RatesService from '@/modules/rates/rates.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';

import RatesGraphTooltip from '@/modules/rates/components/graph/rates-graph-tooltip.vue';
import type Day from '@/modules/common/types/day.type';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import GraphHotelsLegend, { ICustomData } from '@/modules/common/components/graph-hotels-legend.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import setScrollbarPadding from '@/modules/common/filters/scrollbar-padding.filter';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import DayRateTooltipCompset from './day-rate-tooltip-compset.vue';

@Component({
    components: {
        CustomGraph,
        LoaderWrapper,
        RatesGraphTooltip,
        DayRateTooltipCompset,
        GraphHotelsLegend,
    },
})
export default class RatesGraphCompset extends Vue {
    @inject(KEY.RatesService)
    private ratesService!: RatesService;

    @inject(KEY.DocumentFiltersService)
    private documentFiltersService!: DocumentFiltersService;

    @inject(UserServiceS)
    private userService!: UserService;

    @inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    disabledChart: number[] = [];

    tooltipDay: Day | null = null;
    tooltipFocusElement: HTMLElement | null = null;

    get loading() {
        return this.ratesService.storeState.loading;
    }

    get isNonSupportedModal() {
        return this.$route.name!.includes('.not-supported');
    }

    get mainHotelId() {
        const { currentHotelId } = this.userService;

        return +this.$route.params.hotelId || currentHotelId;
    }

    get chartData(): { labels: (Day | string)[][], datasets: ChartDataSets[] } {
        if (!this.documentFiltersService.days || !this.userService.currentHotelId) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const compsetMinMaxPrices = this.ratesService.minMaxPrices(this.userService.currentHotelId);

        const compData: (number | null)[] = [];
        const myHotelData: (number | null)[] = [];
        let beforeCompExistedValue: null | number = null;

        this.documentFiltersService.days.forEach(day => {
            const compPrice = this.ratesService.getCompsetPrice(day);

            if (compPrice) {
                beforeCompExistedValue = compPrice;
                compData.push(compPrice);
            } else {
                compData.push(beforeCompExistedValue || this.ratesService.getCompsetPrice((day + 1) as Day));
            }

            const myPrice = this.ratesService.getPrice(day);
            myHotelData.push(myPrice || null); // Null needs to show Sold Out on the Graph (CI-2255)
        });

        const { year, month } = this.documentFiltersService;

        const labels = this.documentFiltersService.days
            .map(day => [day, this.$t(`dayShort.${new Date(year, month, day).getDay()}`).toString()]);

        return {
            labels,
            // @ts-ignore
            datasets: [
                {
                    label: this.hotelsService
                        .getHotelName(this.userService.currentHotelId),
                    data: myHotelData,
                    borderColor: '#2081AC',
                    pointRadius: 3,
                    borderWidth: 3,
                    pointBackgroundColor: '#E9F7FD',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                    hidden: this.disabledChart.includes(this.mainHotelId!),
                },
                {
                    label: 'Median',
                    borderDash: [8, 4],
                    data: compData,
                    borderColor: '#79CFF3',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                    hidden: this.disabledChart.includes(-1),
                },
                {
                    label: 'Min',
                    data: compsetMinMaxPrices
                        ? compsetMinMaxPrices.minPrices
                        : [],
                    borderDash: [0, 1],
                    borderColor: 'black',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'Max',
                    data: compsetMinMaxPrices
                        ? compsetMinMaxPrices.maxPrices
                        : [],
                    borderDash: [0, 1],
                    fill: 2,
                    backgroundColor: '#E9F7FD',
                    borderColor: 'black',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
            ],
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 0,
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                    },
                    offset: true,
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    get legendList(): ICustomData[] {
        const { currentCompset } = this.compsetsService;
        const compsetType = currentCompset
            ? this.$t(`compset.${currentCompset.type}`)
            : '';

        return [
            {
                id: this.mainHotelId!,
                name: this.hotelsService.getHotelName(this.mainHotelId!),
                color: CURRENT_HOTEL_GRAPH_COLOR,
            },
            {
                id: -1,
                name: `${compsetType} ${this.$tc('rates.comprate')}`,
                color: '#79CFF3',
                indicatorClass: 'median-indicator',
            },
            {
                id: -2,
                name: this.$tc('rates.comprange'),
                color: '#E9F7FD',
                persistent: true,
                indicatorClass: 'range-indicator',
            },
        ];
    }

    updated() {
        setScrollbarPadding();

        if (this.ratesService.isAllChannelsMode) {
            this.redirectToHotelsMode();
        }
    }

    redirectToHotelsMode() {
        this.$router.push({
            name: this.$route.name!.replace('-compset', '-hotels'),
            params: { hotelId: this.$route.params.hotelId },
        });
    }

    onTooltipClick(dayLabel: string) {
        const hotelId = String(this.userService.currentHotelId);
        const day = String(this.parseLabel(dayLabel));

        if (hotelId) {
            this.$router.push({
                name: `${this.$route.name}.day-rate`,
                params: { hotelId, day },
            });
        }
    }

    parseLabel(label: string | null) {
        return label ? String(parseInt(label, 10)) : null;
    }

    passCurrentDay(day: string | null) {
        this.$emit('current-day', this.parseLabel(day));
        this.tooltipDay = this.parseLabel(day) as unknown as Day;
        this.tooltipDay = this.tooltipDay && +this.tooltipDay as Day;
    }

    setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }
}
