import { inject, injectable } from '@/inversify';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import type Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';
import ClusterApiService, { ClusterApiServiceS } from '@/modules/cluster/cluster-api.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ClusterStore from './store/cluster.store';
import CustomNotificationService, { CustomNotificationServiceS } from '../common/modules/custom-notification/custom-notification.service';
import PAGES from '../common/constants/pages.constant';
import ClusterHotelsMarketsModel from './models/cluster-markets.model';
import SCAN_STATUS from '../rates/constants/scan-status.constant';

@injectable()
export default class ClusterMarketsService implements Stateable {
    @inject(ClusterApiServiceS) private clusterApiService!: ClusterApiService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(MarketsCommonServiceS) private marketsCommonService!: MarketsCommonService;
    @inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.storeState.provider,
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
            this.storeState.marketsSorting,
        ], () => this.clusterService.resetLoading());
    }

    get hotels() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.MARKETS),
        );
        return this.storeState.clusterHotels as ClusterHotelsMarketsModel[];
    }

    get hotelsCount() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.MARKETS),
        );

        return this.storeState.totalCount as number;
    }

    get settings() {
        return this.storeState.settings;
    }

    getDemand(day: Day, hotelId: number) {
        const hotelData = this.clusterService.getHotelBy<ClusterHotelsMarketsModel>({ hotelId });

        if (!hotelData || !hotelData.compsetMain) {
            return null;
        }

        return hotelData.compsetMain.dayStatistics?.[day]?.demand || null;
    }

    getOccupancy(day: Day, hotelId: number) {
        const hotelData = this.clusterService.getHotelBy<ClusterHotelsMarketsModel>({ hotelId });

        if (!hotelData || !hotelData.compsetMain) {
            return null;
        }

        return hotelData.compsetMain.dayStatistics?.[day]?.occupancy || null;
    }

    toggleScoreSort() {
        if (this.storeState.marketsSorting === 1) {
            this.storeState.marketsSorting = -1;
        } else {
            this.storeState.marketsSorting = 1;
        }
    }

    isNA(day: Day, hotelId: number) {
        const marketsDocument = this.clusterService.getMainCompsetData<MarketsCompsetMainModel>(hotelId);

        if (!marketsDocument) {
            return false;
        }

        return this.marketsCommonService.isNA(day, hotelId, marketsDocument);
    }

    isNoData(day: Day, hotelId: number) {
        const marketsDocument = this.clusterService.getMainCompsetData<MarketsCompsetMainModel>(hotelId);

        if (!marketsDocument) {
            return false;
        }

        return this.marketsCommonService.isNoData(day, marketsDocument);
    }

    isOutOfRange(hotelId: number) {
        const marketsDocument = this.clusterService.getMainCompsetData<MarketsCompsetMainModel>(hotelId);

        if (!marketsDocument) {
            return false;
        }

        return this.marketsCommonService.isOutOfRange(marketsDocument);
    }

    getAssessments(day: Day, hotelId: number) {
        if (!this.clusterService.currentMarketsProvider) {
            return null;
        }

        const document = this.clusterService.getMainCompsetData<MarketsCompsetMainModel>(hotelId);

        if (!document) {
            return null;
        }

        const marketAssessments = this.marketsCommonService.getCardAssessment(day, hotelId, document);

        if (marketAssessments === null) {
            const isNoData = this.marketsCommonService.isNoData(day, document);
            const isOutOfRange = this.marketsCommonService.isOutOfRange(document);

            if (isNoData || isOutOfRange) {
                return ASSESSMENT_TYPES.NO_DATA;
            }

            return ASSESSMENT_TYPES.SOLD_OUT;
        }
        return marketAssessments;
    }

    getPosition(day: Day, hotelId: number, document: MarketsCompsetMainModel) {
        return this.marketsCommonService.getPosition(day, hotelId, document);
    }

    getPage(day: Day, hotelId: number, document: MarketsCompsetMainModel) {
        return this.marketsCommonService.getPage(day, hotelId, document);
    }

    async downloadExcel() {
        const { provider } = this.storeState;

        if (provider === null) {
            return;
        }

        const { month, year } = this.documentFiltersService;

        const startDate = new Date(Date.UTC(year, month, 1, 0, 0, 0)).toISOString();
        const endDate = new Date(Date.UTC(year, month + 1, 0, 1, 0, 0, 0)).toISOString();

        const excelData = await this.clusterApiService.getMarketsExcelDocument(provider, startDate, endDate);

        if (excelData) {
            await this.customNotificationService
                .handleExcel(excelData);
        }
    }

    /**
     * Triggers on demand scan on cluster level
     * @param settings trigger options
     * @param day if not passed, whole month will be triggered
     * @returns is successful
     */
    async triggerScan(
        settings: {
            compsetId: string | null;
            provider: string | null;
            los: number | null;
            pos: string | null;
            hotelId?: number;
        },
        day?: Day,
    ) {
        const { provider } = settings;

        if (provider === null || !settings.hotelId || !this.hotels || !this.hotels.length) {
            return false;
        }

        const docId = await this.marketsCommonService.triggerScan(settings, day);

        if (docId === null) {
            return false;
        }

        const hotelIndex = this.hotels.findIndex(h => h.hotelId === settings.hotelId);

        if (hotelIndex === -1) {
            return false;
        }

        let doc = this.hotels[hotelIndex].compsetMain as MarketsCompsetMainModel;

        if (doc) {
            doc.scanStatus = SCAN_STATUS.IN_PROGRESS;
        } else {
            this.hotels[hotelIndex].compsetMain = new MarketsCompsetMainModel();
            doc = this.hotels[hotelIndex].compsetMain as MarketsCompsetMainModel;
            doc.scanStatus = SCAN_STATUS.IN_PROGRESS;
            doc.documentId = String(docId);
            doc.id = settings.compsetId || '';
        }

        return true;
    }
}
