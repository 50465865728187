
import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import type MarketsService from '@/modules/markets/markets.service';
import GRAPH_TYPE from '@/modules/markets/constants/graph-types.constant';

@Component({
    components: {
        CustomSwitch,
    },
})
export default class MarketsGraphTypeChanger extends Vue {
    @inject(KEY.MarketsService) private marketsService!: MarketsService;

    @Prop({
        type: String,
        required: true,
    })
    provider!: string;

    get value(): boolean {
        return this.marketsService.getGraphType(this.provider) === GRAPH_TYPE.HOTELS;
    }
}
