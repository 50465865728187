import { Expose, plainToClass, Transform } from 'class-transformer';
import RatesDocumentItemModel from './rates-document-item.model';

type RoomTypeId = string;

export interface RatesHotelInterface {
    updateDate?: string | null;
    link: string | null;
    losRestriction?: number | false;
    taskId?: string;
    screenshot: string | null;
    isSmartShopping?: boolean;
    pax?: number[] | null;
    rooms: Record<RoomTypeId, RatesDocumentItemModel[]>;
}

export default class RatesHotelModel implements RatesHotelInterface {
    @Expose({ name: 'update_date' })
    updateDate?: string | null;

    @Expose()
    link!: string | null;

    @Expose({ name: 'los_restricted' })
    losRestriction?: number | false;

    @Expose({ name: 'task_id' })
    taskId?: string;

    @Expose()
    screenshot!: string | null;

    @Expose({ name: 'is_smart_shopping' })
    isSmartShopping?: boolean;

    @Expose()
    @Transform(pax => (pax && pax.length ? pax : null))
    pax?: number[] | null;

    @Expose()
    @Transform((rooms: Record<RoomTypeId, any[]>) => {
        const record = {} as Record<RoomTypeId, RatesDocumentItemModel[]>;

        Object.entries(rooms || {}).forEach(([roomTypeId, rooms]) => {
            record[roomTypeId] = plainToClass(RatesDocumentItemModel, rooms, { excludeExtraneousValues: true });
        });

        return record;
    })
    rooms!: Record<RoomTypeId, RatesDocumentItemModel[]>;
}
