
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ChartDataSets, ChartOptions, ChartData } from 'chart.js';

import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import CustomTooltipV2, { TOOLTIP_DIRECTION, Point } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import ScoreCard from '../_score-card.vue';

@Component({
    components: {
        CustomGraph,
        CustomTooltipV2,
        ScoreCard,
        CustomMultiSelect,
    },
})
export default class ScoreByHotel extends Vue {
    @Prop({ type: Boolean, default: false })
    isLoading!: boolean;

    @Prop({ type: Array })
    scoreData!: ({ name: string } & Point)[] | null;

    ranges = [7, 14, 30, 60];
    currentRange = 30;
    tooltipPos: Point | null = null;
    hoveredChartDataIndexes: number[] | null = null;
    selectedHotels: string[] | null = null;

    mounted() {
        if (this.selectedHotels === null && this.scoreData !== null) {
            this.selectedHotels = this.scoreData.map(v => v.name);
        }
    }

    updated() {
        if (this.selectedHotels === null && this.scoreData !== null) {
            this.selectedHotels = this.scoreData.map(v => v.name);
        }
    }

    get chartData() {
        if (this.isLoading || !this.scoreData || !this.shownScoreData) {
            return {
                datasets: [],
                labels: [],
            };
        }

        const mainRvsDataset = {
            data: this.shownScoreData.map(v => ({ x: v.x, y: v.y })),
        } as ChartDataSets;

        return {
            datasets: [
                mainRvsDataset,
            ],
        } as ChartData;
    }

    get chartOptions() {
        const axisOffset = 1.2;

        let maxX = 100;
        let maxY = 100;
        let minX = 0;
        const minY = 0;

        if (this.scoreData) {
            maxX = Math.ceil(Math.max(...this.scoreData.map(v => v.x)) * axisOffset);
            maxY = Math.ceil(Math.max(...this.scoreData.map(v => v.y)) * axisOffset);
            minX = Math.floor(Math.min(...this.scoreData.map(v => v.x)) / axisOffset);

            if (minX < 0) {
                minX = 0;
            }
        }

        return {
            maintainAspectRatio: false,
            legend: { display: false },
            tooltips: { enabled: false },
            hover: {
                mode: 'point',
            },
            elements: {
                point: {
                    backgroundColor: CURRENT_HOTEL_GRAPH_COLOR,
                    hoverRadius: 5,
                    hitRadius: 7,
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        drawTicks: false,
                        color: '#CCD1D5',
                    },
                    ticks: {
                        maxTicksLimit: 21,
                        maxRotation: 0,
                        padding: 10,
                        min: minX,
                        max: maxX,
                        callback: v => `$${v}`,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: this.$tc('titles.rates'),
                    },
                }],
                yAxes: [{
                    gridLines: {
                        color: '#CCD1D5',
                        drawTicks: false,
                    },
                    ticks: {
                        maxTicksLimit: 7,
                        padding: 10,
                        min: minY,
                        max: maxY,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: this.$tc('titles.value'),
                    },
                }],
            },
            onHover: (
                event,
                activeElements: {
                    _model: Point;
                    _chart: { canvas: HTMLElement };
                    _index: number;
                }[],
            ) => {
                if (!activeElements[0]) {
                    this.tooltipPos = null;
                    this.hoveredChartDataIndexes = null;
                    return;
                }

                const xOffset = -2;
                const yOffset = -3;

                const { x: canvasXOffset, y: canvasYOffset } = activeElements[0]._chart.canvas.getBoundingClientRect();

                this.tooltipPos = {
                    x: activeElements[0]._model.x + canvasXOffset + xOffset,
                    y: activeElements[0]._model.y + canvasYOffset + yOffset,
                };

                this.hoveredChartDataIndexes = activeElements.map(e => (e as { _index: number })._index);
            },
        } as ChartOptions;
    }

    get shownScoreData() {
        if (!this.scoreData || !this.selectedHotels) {
            return null;
        }

        return this.scoreData.filter(v => this.selectedHotels!.includes(v.name));
    }

    get tooltipDir() {
        return TOOLTIP_DIRECTION.TOP;
    }
}
