import { inject, injectable } from '@/inversify';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import type Day from '@/modules/common/types/day.type';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import CompsetModel from '@/modules/compsets/models/compset.model';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import HomeStore from '@/modules/home/store/home.store';
import MarketsApiService, { MarketsApiServiceS } from '@/modules/markets/markets-api.service';
import type MarketsService from '@/modules/markets/markets.service';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import RatesApiService, { RatesApiServiceS } from '@/modules/rates/rates-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ASSESSMENT_TYPES from '../common/constants/assessments-types.constant';
import RatesCommonService, { RatesCommonServiceS } from '../common/modules/rates/rates-common.service';
import HotelRooms from '../common/interfaces/hotelRooms.interface';
import RatesDocumentModel from '../rates/models/rates-document.model';
import PRICE_SHOWN from '../rates/constants/price-shown.constant';
import UserSettingsService, { UserSettingsS } from '../user/user-settings.service';
import MarketsDocumentItemModel from '../markets/models/markets-document-item.model';

export const HomeServiceS = Symbol.for('HomeServiceS');
@injectable()
export default class HomeService implements Stateable {
    @inject(MarketsApiServiceS) private marketsApiService!: MarketsApiService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(KEY.MarketsService) private marketsService!: MarketsService;
    @inject(RatesApiServiceS) private ratesApiService!: RatesApiService;
    @inject(HomeFiltersServiceS) private homeFiltersService!: HomeFiltersService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(KEY.DocumentFiltersService) protected documentFiltersService!: DocumentFiltersService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    storeState: HomeStore = this.storeFacade.getState('HomeStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.storeState.settings.ratesProvider,
                this.compsetsService.storeState.compsets,
                this.storeState.settings.ratesPos,
            ],
            (n, o) => {
                if (JSON.stringify(n) === JSON.stringify(o)) {
                    return;
                }
                this.storeState.homeRateDocuments = {};
                this.storeState.loadingRates.reset();
            },
        );

        this.storeFacade.watch(
            () => [this.storeState.settings.visibilityProvider, this.storeState.settings.visibilityCompsetId],
            this.storeState.loadingVisibility.reset.bind(this.storeState.loadingVisibility),
        );

        this.storeFacade.watch(
            () => [
                this.documentFiltersService.storeState.settings.month,
                this.documentFiltersService.storeState.settings.year,
                this.userService.currentHotelId,
            ],
            () => {
                this.storeState.loadingVisibility.reset();
                this.storeState.loadingRates.reset();
            },
        );

        this.loadRatesDocuments();
    }

    get loadingRates() {
        return this.storeState.loadingRates;
    }

    get loadingVisibility() {
        return this.storeState.loadingVisibility;
    }

    get loadingDemand() {
        return this.storeState.loadingDemand;
    }

    get ratesDocuments() {
        this.helperService.dynamicLoading(this.storeState.loadingRates, this.loadRatesDocuments.bind(this));
        return this.storeState.homeRateDocuments;
    }

    async loadVisibility(): Promise<boolean> {
        if (this.storeState.settings.visibilityProvider === null) {
            return false;
        }

        const documentSettings: DocumentFiltersModel = {
            ...this.documentFiltersService.storeState.settings,
            compsetId: this.storeState.settings.visibilityCompsetId,
        };

        const homeVisibilityDocument = await this.marketsApiService.getMarketsDocument(documentSettings, { provider: this.storeState.settings.visibilityProvider });
        this.storeState.homeVisibilityDocument = (homeVisibilityDocument && homeVisibilityDocument.checkinDates)
            ? { ...homeVisibilityDocument }
            : null;

        return true;
    }

    async loadRatesDocuments() {
        await this.compsetsService.storeState.loading.whenLoadingFinished();
        const { compsets } = this.compsetsService;

        if (compsets === null || !compsets.length) {
            return false;
        }

        const settings = this.getHomeRatesSettings();

        if (settings === null) {
            return false;
        }

        this.storeState.loadingRates.start();

        await Promise.all(compsets.map(async compset => {
            const ratesSettings = settings[compset.id];
            const doc = await this.ratesApiService.getRatesDocument(ratesSettings);
            this.storeState.homeRateDocuments[compset.id] = doc as RatesDocumentModel | null;
        }));

        this.storeState.loadingRates.finish();

        return true;
    }

    getHomeRatesSettings() {
        const { compsets } = this.compsetsService;
        const { ratesProvider, ratesPos } = this.storeState.settings;
        const { year, month } = this.documentFiltersService.storeState.settings;

        const { numberOfGuests, price } = this.userSettingsService.defaultFilters;
        const { mealType, los } = this.userSettingsService.defaultFilters;

        if (compsets === null || !compsets.length || ratesProvider === null) {
            return null;
        }

        return compsets.reduce((acc, compset) => ({
            ...acc,
            [compset.id]: {
                ...new RatesSettingsModel(),
                competitors: compset.competitors,
                provider: ratesProvider,
                compsetId: compset.id,
                pos: ratesPos,
                numberOfGuests,
                priceType: price,
                los,
                mealType,
                year,
                month,
            } as RatesSettingsModel & DocumentFiltersModel,
        }), {} as {
            [compsetId: string]: RatesSettingsModel & DocumentFiltersModel;
        });
    }

    getChainAssessment(compsetId: string, day: Day) {
        const document = this.ratesDocuments[compsetId];
        const allSettings = this.getHomeRatesSettings();

        if (allSettings === null || !document) {
            return null;
        }

        const { currentHotelId } = this.userService;

        const compset = this.compsetsService.getCompset(compsetId);
        // For some reason without this.storeState.loadingRates.finishDate here reactivity not works =(
        if (compset === null || currentHotelId === null || !document || this.storeState.loadingRates.finishDate === null) {
            return null;
        }

        if (
            this.ratesCommonService.isOutOfRange(document)
            || this.ratesCommonService.isNoData(day, document)
        ) {
            return ASSESSMENT_TYPES.NO_DATA;
        }

        const {
            rooms, competitionPercent,
        } = this.ratesCommonService.calculateDay(day, document, compset.type, currentHotelId, PRICE_SHOWN.SHOWN);

        if (this.ratesCommonService.isNADay(currentHotelId, rooms)) {
            return ASSESSMENT_TYPES.NA;
        }

        if (this.ratesCommonService.isSoldOutDay(currentHotelId, rooms)) {
            return ASSESSMENT_TYPES.SOLD_OUT;
        }

        if (competitionPercent === null) {
            return ASSESSMENT_TYPES.SOLD_OUT;
        }

        const assessment = this.ratesCommonService.getCardAssessment(competitionPercent, compset);

        if (assessment === null) {
            const isNoData = this.ratesCommonService.isNoData(day, document);

            if (isNoData) {
                return ASSESSMENT_TYPES.NO_DATA;
            }

            return ASSESSMENT_TYPES.SOLD_OUT;
        }

        return assessment;
    }

    getHotelRooms(day: Day, compsetId: string) {
        const allSettings = this.getHomeRatesSettings();
        const document = this.ratesDocuments[compsetId];

        if (allSettings === null || document === null) {
            return {} as HotelRooms;
        }

        const settings = allSettings[compsetId];

        const allRooms = this.ratesCommonService
            .getAllRooms(day, document);

        const { competitors } = settings;
        const { currentHotelId } = this.userService;

        if (!competitors || !currentHotelId) return {};

        const entries = competitors
            .map(hid => [hid, allRooms[hid]]);

        return Object.fromEntries(entries) as HotelRooms;
    }

    get currentHotelVisibilityData(): (number | null)[] | null {
        this.helperService.dynamicLoading(this.storeState.loadingVisibility, this.loadVisibility.bind(this));

        const { homeVisibilityDocument } = this.storeState;
        if (!homeVisibilityDocument || !homeVisibilityDocument.checkinDates || this.userService.currentHotelId === null) {
            return null;
        }

        const { currentHotelId } = this.userService;

        return Object.entries(homeVisibilityDocument.checkinDates).map(([_, hotels]) => {
            const currentHotel = hotels[currentHotelId];
            if (!currentHotel || !currentHotel.position) {
                return null;
            }

            return currentHotel.position;
        });
    }

    isRatesDocumentAvailable(compset: CompsetModel) {
        const { ratesProvider, ratesPos } = this.homeFiltersService;
        return ratesProvider && ratesPos && compset.rateProviders.includes(ratesProvider) && compset.pos.includes(ratesPos);
    }

    calculateMyAssessmentByDay(day: Day) {
        const { visibilityProvider, visibilityCompsetId } = this.storeState.settings;
        if (visibilityProvider && visibilityCompsetId) {
            const { homeVisibilityDocument } = this.storeState;
            if (!homeVisibilityDocument || !homeVisibilityDocument.checkinDates || this.userService.currentHotelId === null) {
                return null;
            }
            const { currentHotelId } = this.userService;
            const checkingDates = homeVisibilityDocument.checkinDates[day]
                ? homeVisibilityDocument.checkinDates[day]
                : null;

            if (!checkingDates || !checkingDates[currentHotelId]) {
                return null;
            }

            const { position } = checkingDates[currentHotelId];

            const positions: number[] = [];

            Object
                .entries(checkingDates)
                .forEach(([hotelId, hotel]) => {
                    if (hotelId === 'updateDate') return;

                    if (Number(hotelId) === currentHotelId) {
                        return;
                    }
                    positions.push((hotel as MarketsDocumentItemModel).position);
                });

            const thresholds = this.marketsService.calculateThresholds(positions);

            if (!position || !thresholds) {
                return null;
            }
            const [highThreshold, midHighThreshold, midLowThreshold] = thresholds;

            if (position <= highThreshold) {
                return ASSESSMENT_TYPES.GOOD;
            }
            if (position <= midHighThreshold) {
                return ASSESSMENT_TYPES.NORMAL;
            }
            if (position <= midLowThreshold) {
                return ASSESSMENT_TYPES.FAIR;
            }
            return ASSESSMENT_TYPES.BAD;
        }

        return null;
    }

    calculateMyAssessments() {
        const data = this.currentHotelVisibilityData;

        if (!data) {
            return null;
        }

        return data.map((value: number | null, index: number) => {
            if (value === null) {
                return null;
            }

            const day = index + 1 as Day;
            return this.calculateMyAssessmentByDay(day);
        });
    }

    getMyPricesAverage(): (number | null)[] {
        const prices: (number | null)[] = [];
        const compsets = this.compsetsByPos;
        const { ratesDocuments: documents } = this;
        const { currentHotelId } = this.userService;

        if (compsets == null || !Object.keys(documents).length || currentHotelId === null) {
            return prices;
        }

        this.documentFiltersService.days.forEach(day => {
            const beforeCompExistedValue: { [compsetId: string]: number } = {};
            const dayPrices: (number | null)[] = [];

            compsets.forEach(compset => {
                if (documents[compset.id]) {
                    const myPrice = this.ratesCommonService.getPrice(day, currentHotelId, documents[compset.id], PRICE_SHOWN.SHOWN);
                    if (myPrice !== null) {
                        beforeCompExistedValue[compset.id] = myPrice;
                        dayPrices.push(myPrice);
                    } else {
                        dayPrices.push(beforeCompExistedValue[compset.id] || myPrice);
                    }
                }
            });
            prices.push(this.getAveragePrice(dayPrices));
        });
        return prices;
    }

    getAveragePrice(prices: (number|null)[]) {
        const pricesSum = prices.reduce((accumulator, currentValue) => {
            if (accumulator !== null && currentValue !== null) {
                return accumulator + currentValue;
            }

            return accumulator || currentValue;
        }, null);

        return pricesSum ? (pricesSum / prices.length) : pricesSum;
    }

    compsetMinMaxPrices(compsetType: COMPSET_TYPE): { minPrices: (number | null)[], maxPrices: (number | null)[] } | null {
        // NOTE For some reason without this.storeState.loadingRates.finishDate
        //      here reactivity not works =

        if (!this.storeState.loadingRates.finishDate || !Object.keys(this.ratesDocuments).length || !this.userService.currentHotelId) {
            return null;
        }

        const compsetsByType = Object
            .keys(this.ratesDocuments)
            .filter(compsetId => {
                const compset = this.compsetsService.getCompset(compsetId);
                return compset && compset.type === compsetType;
            });

        return this.getAggregatedPrices(compsetsByType);
    }

    getCompsetPrice(day: Day, compsetId: string) {
        const rooms = this.getHotelRooms(day, compsetId);
        const compset = this.compsetsService.getCompset(compsetId);

        if (!compset) {
            return null;
        }

        return this.ratesCommonService.getCompsetPrice(rooms, compset.type, PRICE_SHOWN.SHOWN);
    }

    getAggregatedPrices(compsets: string[]) {
        const allSettings = this.getHomeRatesSettings();
        if (allSettings === null) {
            return null;
        }

        return compsets.reduce((aggregatedPrices: { minPrices: (number | null)[], maxPrices: (number | null)[] } | null, compsetId: string) => {
            const document = this.ratesDocuments[compsetId];

            const { days } = this.documentFiltersService;
            const compsetMinMaxPrices = this.ratesCommonService.minMaxPrices(document, days);

            if (!aggregatedPrices) {
                return compsetMinMaxPrices;
            }
            const maxPrices: (number|null)[] = [];
            const minPrices: (number|null)[] = [];

            this.documentFiltersService.days.forEach((_, i) => {
                maxPrices.push(this.getAggregatedPrice(aggregatedPrices.maxPrices[i], compsetMinMaxPrices.maxPrices[i], 1));
                minPrices.push(this.getAggregatedPrice(aggregatedPrices.minPrices[i], compsetMinMaxPrices.minPrices[i], -1));
            });

            return { maxPrices, minPrices };
        }, null);
    }

    getAggregatedPrice(priceOne: number| null, priceTwo: number| null, type: 1 | -1): number | null {
        if (priceOne === null || priceTwo === null) {
            return priceOne || priceTwo;
        }
        return type === 1 ? Math.max(...[priceOne, priceTwo]) : Math.min(...[priceOne, priceTwo]);
    }

    getMedianCompsetsAveragePrices() {
        const medianCompsets = Object.keys(this.ratesDocuments).filter(compsetId => {
            const compset = this.compsetsService.getCompset(compsetId);
            return compset && compset.type === COMPSET_TYPE.MEDIAN;
        });
        const medianPrices : (number| null)[] = [];

        if (!medianCompsets.length) {
            return medianPrices;
        }

        const medianCompsetsPrices = medianCompsets.map(compsetId => this.getCompsetPrices(compsetId));

        this.documentFiltersService.days.forEach((_, i) => {
            const prices: (number| null)[] = [];
            medianCompsetsPrices.forEach(compsetPrices => {
                prices.push(compsetPrices[i]);
            });

            medianPrices.push(this.getAveragePrice(prices));
        });

        return medianPrices;
    }

    getCompsetPrices(compsetId: string): (number | null)[] {
        const compData: (number | null)[] = [];
        let beforeCompExistedValue: null | number = null;
        const { ratesDocuments: documents } = this;

        const compset = this.compsetsService.getCompset(compsetId);
        if (!compset || !documents[compset.id]) {
            return compData;
        }

        this.documentFiltersService.days.forEach(day => {
            const rooms = this.getHotelRooms(day, compsetId);
            const compPrice = this.ratesCommonService.getCompsetPrice(rooms, compset.type, PRICE_SHOWN.SHOWN);

            if (compPrice) {
                beforeCompExistedValue = compPrice;
                compData.push(compPrice);
            } else {
                compData.push(beforeCompExistedValue);
            }
        });

        return compData;
    }

    getMyPrice(day: Day) {
        const prices = this.getMyPricesAverage();
        return prices[day - 1] ? prices[day - 1] : null;
    }

    get compsetsByPos() {
        if (!Object.keys(this.ratesDocuments).length) {
            return null;
        }
        const { compsets } = this.compsetsService;
        const { ratesPos } = this.homeFiltersService;
        const compsetsByPos = compsets && ratesPos ? compsets.filter(compset => compset.pos.includes(ratesPos)) : [];
        return compsetsByPos.length ? compsetsByPos : null;
    }

    getRatesCurrency(pos: string | null) {
        if (!Object.keys(this.ratesDocuments).length) {
            return null;
        }

        const { compsets } = this.compsetsService;
        const compsetsByPos = compsets && pos ? compsets.filter(compset => compset.pos.includes(pos)) : [];

        if (compsetsByPos.length && this.ratesDocuments[compsetsByPos[0].id]) {
            return this.ratesCommonService.currency(this.ratesDocuments[compsetsByPos[0].id]);
        }

        return null;
    }
}
