/* eslint-disable */
import { Expose, Transform } from "class-transformer";
import moment from "moment";
import { DAY_TYPE, TIME_BUFFER } from "../constants";

export default class SchedulerConfigPayloadModel {
    @Expose({ name: 'hour' })
    @Transform(hour => moment({ hour: +hour }).subtract(TIME_BUFFER, 'minutes').hour())
    hour!: number;

    @Expose()
    @Transform((_, { hour }) => moment({ hour: +hour }).subtract(TIME_BUFFER, 'minutes').minute())
    minute!: number;

    @Expose()
    month?: number;

    @Expose({ name: 'monthPeriod' })
    month_period?: string;

    @Expose({ name: 'repeatEvery' })
    repeat_every?: number;

    @Expose()
    type!: 'daily' | 'weekly' | 'monthly' | 'yearly';

    @Expose({ name: 'dayOfWeek' })
    day_of_week?: string;

    @Expose({ name: 'dayOfMonth' })
    day_of_month?: number;

    @Expose()
    dayType?: DAY_TYPE;

    clean() {
        switch(this.type) {
            case 'daily':
                delete this.day_of_week;
                delete this.day_of_month;
                delete this.repeat_every;
                delete this.month;
                delete this.month_period;
                break;

            case 'weekly':
                delete this.day_of_month;
                delete this.month;
                delete this.month_period;
                break;

            case 'monthly':
                delete this.month;
                delete this.repeat_every;
                if (this.dayType !== DAY_TYPE.SPECIFIC) {
                    delete this.day_of_month;
                } else {
                    delete this.month_period;
                    delete this.day_of_week;
                }
                break;

            default: // NOTE yearly
                if (this.dayType !== DAY_TYPE.SPECIFIC) {
                    delete this.day_of_month;
                } else {
                    delete this.month_period;
                    delete this.day_of_week;
                }
                delete this.repeat_every;
                break;
        }

        delete this.dayType;

        return this;
    }
}
