

import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Component, Vue } from 'vue-property-decorator';
import { ChartData, ChartOptions } from 'chart.js';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import DayRateGraphTooltip from '@/modules/cars/components/rates-graphs/day-rate-tooltip-graph.vue';
import RatesGraphTooltip from './rates-graph-tooltip.vue';

@Component({
    components: {
        CustomGraph, LoaderWrapper, RatesGraphTooltip, DayRateGraphTooltip,
    },
})
export default class RatesGraphCompset extends Vue {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(CarsFiltersServiceS) private carFilterService!: CarsFiltersService;

    private disabledDays: number[] = [];

    tooltipDay: Day | null = null;
    tooltipFocusElement: HTMLElement | null = null;

    onTooltipClick(day: Day) {
        if (!this.isDisabledDay(day)) {
            this.$router.push({ name: 'cars-rates.graph.day-popup', params: { day: String(day) } });
        }
    }

    get NoDataDays() {
        return this.disabledDays;
    }

    isDisabledDay(day: Day) {
        if (this.disabledDays.length === 0) {
            return false;
        }
        if (this.disabledDays.find(item => item === Number(day)) === undefined) {
            return false;
        }
        return true;
    }

    get chartData(): ChartData {
        const { currentCompany } = this.userService;

        if (!this.documentFiltersService.days || !currentCompany) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const compData: (number | null)[] = [];
        const myCompanyData: (number | null)[] = [];
        let lastMedian: null | number = null;
        const lastMin: null | number = null;
        const lastMax: null | number = null;

        const minPrices: (number | null)[] = [];
        const maxPrices: (number | null)[] = [];
        this.disabledDays = [];

        this.documentFiltersService.days.forEach(day => {
            const compPrice = this.carsService.competitorMedian(day);
            const minPrice = this.carsService.competitorMin(day);
            const maxPrice = this.carsService.competitorMax(day);

            if (this.carsService.isNoData(day)) {
                this.disabledDays.push(day);
            }

            if (compPrice) {
                lastMedian = compPrice;
                compData.push(compPrice);
                minPrices.push(minPrice);
                maxPrices.push(maxPrice);
            } else {
                compData.push(lastMedian || this.carsService.competitorMedian(day + 1 as Day));
                minPrices.push(lastMin || this.carsService.competitorMin(day + 1 as Day));
                maxPrices.push(lastMax || this.carsService.competitorMax(day + 1 as Day));
            }

            const myPrice = this.carsService.getPrice(day, currentCompany);
            myCompanyData.push(myPrice);
        });

        const currentColor: string = this.userService.currentCompany ? PROVIDER_COLORS[this.userService.currentCompany] : '#555555';

        return {
            labels: this.documentFiltersService.days,
            datasets: [
                {
                    label: this.userService.currentCompany || undefined,
                    data: myCompanyData,
                    borderColor: currentColor,
                    pointRadius: 3,
                    borderWidth: 3,
                    pointBackgroundColor: currentColor,
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'Median',
                    borderDash: [8, 4],
                    data: compData,
                    borderColor: '#79CFF3',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'Min',
                    data: minPrices,
                    borderDash: [0, 1],
                    borderColor: 'black',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
                {
                    label: 'Max',
                    data: maxPrices,
                    borderDash: [0, 1],
                    fill: 2,
                    backgroundColor: '#E9F7FD',
                    borderColor: 'black',
                    lineTension: 0,
                    borderJoinStyle: 'round',
                },
            ],
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 0,
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                    },
                    offset: true,
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    parseLabel(label: string | null) {
        return label ? String(parseInt(label, 10)) : null;
    }

    passCurrentDay(day: string | null) {
        this.$emit('current-day', this.parseLabel(day));
        this.tooltipDay = this.parseLabel(day) as unknown as Day;
        this.tooltipDay = this.tooltipDay && +this.tooltipDay as Day;
    }

    setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }
}
