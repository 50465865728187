import { inject, injectable } from '@/inversify';
import * as _ from 'lodash';
import RankingApiService, { RankingApiServiceS, RankingDownloadExcelForm } from '@/modules/ranking/ranking-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import RankingStore from '@/modules/ranking/store/ranking.store';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import RankingCommonService, { RankingCommonServiceS } from '../common/modules/ranking/ranking-common.service';
import CustomNotificationService, { CustomNotificationServiceS } from '../common/modules/custom-notification/custom-notification.service';
import RankingDocumentItem from './models/ranking-document-item.model';

interface RankingPublicInterface {
    /**
     * List of existing providers in the current document
     */
    providers: string[] | null;

    /**
     * Returns a list of all hotels ids in the current document by `provider`
     *
     * @param provider
     */
    getHotelIds(provider: string): number[] | null;

    /**
     * Returns a hotel data list by `provider`
     *
     * @param provider
     */
    getAllHotels(provider: string): ReturnType<RankingCommonService['hotels']>;

    /**
     * Returns a hotel data of the main hotel by `provider`
     *
     * @param provider
     */
    getMainHotel(provider: string): RankingDocumentItem | null;

    /**
     * Returns a rating range between all competitors
     *
     * @param provider
     */
    getMinMaxCompetitiveRating(provider: string): [number, number] | null;

    /**
     * Returns a review count range between all competitors
     *
     * @param provider
     */
    getMinMaxCompetitiveReview(provider: string): [number, number] | null;

    /**
     * Downloads an excel file with the current document data
     *
     * @param form
     */
    downloadExcel(form: RankingDownloadExcelForm): Promise<void>;
}

export const RankingServiceS = Symbol.for('RankingServiceS');
@injectable()
export default class RankingService implements RankingPublicInterface {
    @inject(UserServiceS)
    private userService!: UserService;

    @inject(RankingApiServiceS)
    private rankingApiService!: RankingApiService;

    @inject(KEY.DocumentFiltersService)
    private documentFiltersService!: DocumentFiltersService;

    @inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @inject(HelperServiceS)
    private helperService!: HelperService;

    @inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    @inject(RankingCommonServiceS)
    private rankingCommonService!: RankingCommonService;

    @inject(CustomNotificationServiceS)
    private customNotificationService!: CustomNotificationService;

    readonly storeState: RankingStore = this.storeFacade.getState('RankingStore');

    constructor(storeState: RankingStore | null = null) {
        if (storeState) {
            this.storeState = storeState;
        } else {
            this.storeFacade.watch(() => [
                this.documentFiltersService.storeState.settings.compsetId,
                this.providersService.storeState.providers,
                this.providersService.storeState.providerList,
                this.userService.currentHotelId,
                this.userService.viewAs,
                // @ts-ignore
            ], ((newValue, oldValue) => {
                const [newCompsetId, newProviders, newCurrentHotelId, newViewAs] = newValue;
                const [oldCompsetId, oldProviders, oldCurrentHotelId, oldViewAs] = oldValue;

                if (newCompsetId === oldCompsetId
                    && newCurrentHotelId === oldCurrentHotelId
                    && newViewAs === oldViewAs
                    && _.isEqual(newProviders, oldProviders)) {
                    return;
                }

                this.storeState.loading.reset.call(this.storeState.loading);
            }));
        }
    }

    private get currentHotelId() {
        return this.userService.currentHotelId;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.document;
    }

    get providers() {
        if (!this.data) {
            return null;
        }
        return this.rankingCommonService.providers(this.data);
    }

    getHotelIds(provider: string) {
        if (!this.data) {
            return null;
        }
        return this.rankingCommonService.hotelIds(this.data, provider);
    }

    getAllHotels(provider: string) {
        if (!this.data) {
            return null;
        }
        return this.rankingCommonService.hotels(this.data, provider);
    }

    getMainHotel(provider: string) {
        if (!this.data || !this.currentHotelId) {
            return null;
        }

        const hotels = this.rankingCommonService.hotels(this.data, provider);

        if (!hotels) {
            return null;
        }

        return hotels[this.currentHotelId];
    }

    getMinMaxCompetitiveRating(provider: string): [number, number] | null {
        const competitors = this.getCompetitiveHotels(provider);

        if (!competitors) {
            return null;
        }

        return this.rankingCommonService.minMaxRating(competitors);
    }

    getMinMaxCompetitiveReview(provider: string): [number, number] | null {
        const competitors = this.getCompetitiveHotels(provider);

        if (!competitors) {
            return null;
        }

        return this.rankingCommonService.minMaxReview(competitors);
    }

    async downloadExcel(form: RankingDownloadExcelForm) {
        const excelData = await this.rankingApiService.getExcelDocument(form);

        if (excelData) {
            this.customNotificationService
                .handleExcel(excelData);
        }
    }

    get loading() {
        return this.storeState.loading;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    private getCompetitiveHotels(provider: string) {
        if (!this.data || !this.currentHotelId) {
            return null;
        }

        const hotels = this.rankingCommonService.hotels(this.data, provider);

        if (!hotels) {
            return null;
        }

        const { [this.currentHotelId]: _, ...competitors } = hotels;

        return competitors;
    }

    private async loadData(settings?: DocumentFiltersModel): Promise<boolean> {
        let s = settings;

        if (!s) {
            s = this.documentFiltersService.storeState.settings;

            if (!s.compsetId) {
                this.documentFiltersService.resetCompset();
            }
        }

        if (!s || s.compsetId === null || this.providersService.data === null) {
            return false;
        }

        this.storeState.document = null;

        const rankingDocument = await this.rankingApiService.getRanking(s);
        if (rankingDocument) {
            this.storeState.document = rankingDocument;
        }

        return true;
    }
}
