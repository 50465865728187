
import moment from 'moment';
import { Component } from 'vue-property-decorator';
import { inject } from '@/inversify';

import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import ClipText from '@/modules/common/filters/clip-text.filter';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import type PromotionsService from '../promotions.service';

interface TableData {
    hotelId: number;
    hotelName: string;
    percentString: string;
    percent: number;
    isMainHotel: boolean;
    isCompset: boolean;
}

@Component({
    extends: DayTooltipTemplate,
})
export default class PromotionsPercentTooltip extends DayTooltipTemplate {
    @inject(KEY.PromotionsService)
    private promotionsService!: PromotionsService;

    @inject(KEY.DocumentFiltersService)
    private documentFilterService!: DocumentFiltersService;

    @inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    protected reversedChangingSide = true;

    public get hotelList() {
        return (this.promotionsService.data
            ? this.promotionsService.data.hotels
            : [])
            .filter(hotelId => hotelId === this.currentHotelId || this.documentFilterService.competitors!.includes(hotelId));
    }

    public get isActive() {
        return !!this.focusElement && !!this.tableData.length;
    }

    private get currentHotelId() {
        return +this.$route.params.hotelId;
    }

    private get cellDay() {
        if (!this.focusElement) return null;

        return +this.focusElement.dataset.day!;
    }

    private get dataset() {
        if (!this.focusElement) return {};

        return this.focusElement.dataset;
    }

    public get date() {
        const { year, month } = this.documentFilterService;

        if (!this.cellDay) return '---';

        const d = new Date(year, month, this.cellDay);

        return moment(d).format('D MMMM YYYY');
    }

    public getCompsetRow(tableData: TableData[]) {
        const { currentCompset } = this.compsetsService;

        if (!currentCompset) return null;

        const hotelName = String(this.$t(`compsetRate.${currentCompset.type}`));

        const validPercents = tableData
            .filter(row => !row.isMainHotel && !!row.percent)
            .map(row => row.percent);

        if (!validPercents.length) return null;

        validPercents.sort((a, b) => b - a);

        let percent = 0;
        const middlePercentIndex = Math.floor(validPercents.length / 2);

        switch (currentCompset.type) {
            case COMPSET_TYPE.LOW:
                percent = Math.max(...validPercents);
                break;

            case COMPSET_TYPE.HIGH:
                percent = Math.min(...validPercents);
                break;

            default:
                percent = validPercents.length % 2
                    ? validPercents[middlePercentIndex]
                    : (validPercents[middlePercentIndex] + validPercents[middlePercentIndex - 1]) / 2;
                break;
        }

        return {
            hotelId: -1,
            isCompset: true,
            isMainHotel: false,
            percentString: percent ? `${percent || 0}%` : this.$tc('na'),
            percent,
            hotelName,
        };
    }

    public get tableData() {
        const { cellDay, dataset } = this;

        if (!cellDay) return [];

        const rows = this.hotelList
            .map(hotelId => {
                const isMainHotel = +hotelId === this.currentHotelId;
                const hotelName = this.hotelsService.getHotelName(hotelId);
                const program = this.promotionsService
                    .getProgram(cellDay, dataset.provider!, +hotelId, dataset.program!);

                return {
                    hotelId,
                    hotelName: ClipText(hotelName || '', 20),
                    percentString: (program && program.percentage) ? `${program.percentage || 0}%` : this.$tc('na'),
                    percent: program ? (program.percentage || 0) : 0,
                    isMainHotel,
                    isCompset: false,
                };
            });

        const compsetRow = this.getCompsetRow(rows);

        if (compsetRow) {
            rows.push(compsetRow);
        }

        rows.sort((a, b) => b.percent - a.percent);

        return rows;
    }
}
