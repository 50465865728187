
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Vue, Component } from 'vue-property-decorator';
import { SCAN_STATUS } from '../constants';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../rates-analysis-filters.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '../rates-analysis.service';
import type RatesService from '../rates.service';

@Component
export default class RatesOutOfRangeMessage extends Vue {
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;

    isPending = false;

    get isScanAvailable() {
        return this.ratesService.isScanAvailable();
    }

    get isScanning() {
        return this.ratesService.scan.values.status === SCAN_STATUS.IN_PROGRESS;
    }

    get isAnalysisOutOfRange() {
        return this.ratesAnalysisService.isAllOutOfRange();
    }

    get isAnalysisMode() {
        return this.$route.name?.includes('.analysis') ?? false;
    }

    get comparisonField() {
        const { comparisonKey } = this.ratesAnalysisFiltersService;
        const field = this.ratesAnalysisFiltersService.comparisonFieldsDictionary.get(comparisonKey);
        return this.$tc(field!);
    }

    triggerScan() {
        this.isPending = true;
        this.ratesService.triggerScan()
            .then(() => { this.isPending = false; });
    }
}
