
import { inject } from '@/inversify';
import { Component, Prop, Vue } from 'vue-property-decorator';
import type Day from '@/modules/common/types/day.type';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import Calendar from '@/modules/common/components/ui-kit/calendar.vue';
import EventTypePoint from '@/modules/common/components/ui-kit/event-type-point.vue';
import isYearType from '@/modules/common/component-validators/is-year.validator';
import isMonthType from '@/modules/common/component-validators/is-month.validator';

@Component({ components: { Calendar, EventTypePoint } })
export default class EventsSimpleCalendar extends Vue {
    @inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    @Prop({
        type: Number,
        required: true,
        validator: isYearType,
    })
    year!: Year;

    @Prop({
        type: Number,
        required: true,
        validator: isMonthType,
    })
    month!: Month;

    get title(): string {
        return `${this.$t(`month.${this.month}`)} ${this.year}`;
    }

    hasHolidayEvents(day: Day) : boolean {
        const { year, month } = this;

        return this.eventsManagerService.hasDateHolidayEvents(new Date(year, month, day));
        // return Boolean(this.eventsManagerService.getCountryEventsByDay({ day, month: this.month, year: this.year }).length);
    }

    hasLocalEvents(day: Day): boolean {
        const { year, month } = this;
        return this.eventsManagerService.hasDateOtherEvents(new Date(year, month, day));
    }
}
