
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import type RatesService from '@/modules/rates/rates.service';
import { OtelRateDayPopupLoading } from '@/modules/open-telemetry/decorators';
import CommonDayPopup from './common-day-popup/index.vue';
import RatesDocumentAllModel from '../../rates/models/rates-document-all.model';
import RatesDocumentModel from '../../rates/models/rates-document.model';

@OtelRateDayPopupLoading
@Component({
    components: { CommonDayPopup },
})
export default class HotelAllChannelsDayPopup extends Vue {
    /** This component is only for all channels hotel rates page popup */

    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    @Prop({ type: Number })
    day!: Day;

    get documents() {
        if (!this.ratesService.data) {
            return null;
        }

        if (this.ratesService.data instanceof RatesDocumentModel) {
            return null;
        }

        const documents = {
            main: this.ratesService.data,
        } as Record<string, RatesDocumentAllModel | null>;

        return documents;
    }

    get compset() {
        return this.compsetsService.currentCompset;
    }

    get isDocumentLoading() {
        return this.ratesService.isLoading;
    }

    get hotelColors() {
        return {};
    }

    get hotelColorsRgb() {
        return {};
    }

    get documentSettings() {
        return this.documentFiltersService.settings;
    }

    get ratesSettings() {
        return {
            main: this.ratesService.settings,
        };
    }
}
