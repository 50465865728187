
import { Component, Vue } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import PageHeader from '../components/cluster/page-header.vue';
import ScoreTotal from '../components/cluster/score-total.vue';
import ScoreOvertime from '../components/cluster/score-overtime.vue';
import ScoreByHotel from '../components/cluster/score-by-hotel.vue';

@Component({
    components: {
        PageWrapper, PageHeader, ScoreTotal, ScoreOvertime, ScoreByHotel,
    },
})
export default class RvsClusterPage extends Vue {}
