
import { Component, Vue } from 'vue-property-decorator';
import RatesAnalysisTable from '@/modules/rates/components/analysis/table/rates-analysis-table.vue';
import TablePageLayout from '@/modules/rates/components/table-page-layout.vue';
import { OtelRateHotelPageLoading } from '@/modules/open-telemetry/decorators';

@OtelRateHotelPageLoading
@Component({
    components: {
        RatesAnalysisTable,
        TablePageLayout,
    },
})
export default class RatesAnalysisTablePage extends Vue {}
