
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import MarketsCalendar from '@/modules/markets/components/calendar/markets-calendar.vue';
import MarketsScan from '@/modules/markets/components/markets-scan.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import type MarketsService from '@/modules/markets/markets.service';
// @ts-ignore
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import type Day from '@/modules/common/types/day.type';
import MarketsPreviewTooltip from './calendar/markets-preview-tooltip.vue';

let timeout: ReturnType<typeof setTimeout>;

@Component({
    components: {
        ProviderCard,
        MarketsCalendar,
        MarketsScan,
        MarketsPreviewTooltip,
    },
})
export default class MarketsVisibility extends Vue {
    @inject(UserServiceS) userService!: UserService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(KEY.MarketsService) private marketsService!: MarketsService;

    tooltipFocusElement: HTMLElement | null = null;
    tooltipDay: Day | null = null;
    tooltipProvider: string = 'booking';

    get isLoading() {
        return true
            && this.marketsService.isLoading
            && this.sources.length;
    }

    get announcements() {
        return [
            {
                score: 75,
                title: 'Start Sponsored Campaign',
                description: 'We have scanned dates with low visibility on Booking.com Start Sponsored Campaign to boost those days',
                cost: 1.71,
            },
            {
                score: 71,
                title: 'Start Sponsored Campaign',
                description: 'We have scanned dates with low visibility on Booking.com Start Sponsored Campaign to boost those days',
                cost: 1.93,
            },
            {
                score: 84,
                cost: null,
            },
        ];
    }

    get sources(): Item[] {
        const { currentCompset } = this.compsetsService;

        if (!currentCompset) {
            return [];
        }

        return currentCompset.marketProviders
            .map(provider => ({
                value: provider,
                name: provider,
            }));
    }

    get hotelId() {
        const { currentHotelId } = this.userService;

        return +this.$route.params.hotelId || currentHotelId;
    }

    setTooltipFocus(e: MouseEvent) {
        if (!e.currentTarget) return;

        const linkEl = e.currentTarget as unknown as HTMLElement;
        const [target] = linkEl.children;

        this.tooltipFocusElement = target as HTMLElement;
        this.tooltipDay = +(linkEl.dataset.day || 1) as Day;
        this.tooltipProvider = linkEl.dataset.source as string;

        clearTimeout(timeout);
    }

    clearTooltipFocus() {
        timeout = setTimeout(() => {
            this.tooltipDay = null;
        }, 100);
    }
}
