
import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import { Component, Vue } from 'vue-property-decorator';
import CiTable, { DATA_TYPE } from '@/modules/common/components/ci-table';
import { ICell, ITableData } from '@/modules/common/components/ci-table/interfaces';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import type Day from '@/modules/common/types/day.type';
import PriceFilter from '@/modules/common/filters/price.filter';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import { BRAND_AS_BROKER_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import _ from 'lodash';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import CarsAnalysisFiltersService, { CarsAnalysisFiltersServiceS } from '../../cars-analysis-filters.service';
import CarsAnalysisService, { CarsAnalysisServiceS } from '../../cars-analysis.service';
import CarTableAvailabilityTooltip from '../../components/car-table-availability-tooltip.vue';

enum CarTableColumn {
    Date = 'Date',
    Events = 'Events',
    Availability = 'Availability',
    Median = 'Median',
    Diff = 'Diff',
    Latest = 'Latest data',
    Previous = 'Previous data',
}

@Component({
    components: {
        CiTable,
        CustomLoader,
    },
    filters: { PriceFilter },
})
export default class CarRatesAnalysisTable extends Vue {
    @inject(KEY.DocumentFiltersService) documentFiltersService!: DocumentFiltersService;
    @inject(CarsAnalysisFiltersServiceS) carsAnalysisFilterService!: CarsAnalysisFiltersService;
    @inject(CarsAnalysisServiceS) carsAnalysisService!: CarsAnalysisService;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    public isPreparing: boolean = true;
    public disabledColumns: string[] = [];

    beforeDestroy() {
        this.eventsManagerService.saveIsLoadEventByPOS(false);
    }

    readonly tableConfig = {
        height: '100%',
        width: '100%',
        cellSize: [{
            width: ['90px', '120px', '140px', ['100%', '100%', '100%']],
            height: ['50px'],
        }, {
            width: [['100%', '100%', '100%']],
            height: ['50px'],
        }],
    };

    get isLoading() {
        return this.isPreparing
            || (!this.analysisDocument && this.carsAnalysisService.isLoading)
            || this.carsService.isLoading;
    }

    get analysisDocument() {
        return this.isPreparing ? null : this.carsAnalysisService.data;
    }

    get currency(): string | null {
        const { currency } = this.carsService;
        return currency ? this.helperService.currencySymbol(currency) : '';
    }

    get average() {
        const daysCount = this.documentFiltersService.days.length;
        let availableDaysCount = 0;
        const res = this.documentFiltersService.days
            .map(day => {
                const percent = this.carsService.occupancy(day);
                const { totalCars, countCars } = this.carsService.fleetAvailability(day);
                return {
                    countCars,
                    totalCars,
                    percent,
                };
            })
            .reduce((cur, {
                countCars,
                totalCars,
                percent,
            }, i) => {
                if (percent) {
                    availableDaysCount += 1;
                }
                return {
                    countCars: ((countCars || 0) + cur.countCars) / (i === daysCount - 1 ? availableDaysCount : 1),
                    totalCars: ((totalCars || 0) + cur.totalCars) / (i === daysCount - 1 ? availableDaysCount : 1),
                    percent: ((percent || 0) + cur.percent) / (i === daysCount - 1 ? availableDaysCount : 1),
                };
            }, {
                countCars: 0,
                totalCars: 0,
                percent: 0,
            });
        return {
            countCars: Math.round(res.countCars),
            totalCars: Math.round(res.totalCars),
            percent: Math.round(res.percent),
        };
    }

    private transformDate(day: number): string {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const d = new Date(year, month, day);
        const dayName = d.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();

        return `${this.$t(dayName)} ${day}/${month + 1 < 10 ? 0 : ''}${month + 1}`;
    }

    get competitorsCompanies() {
        const { competitorsFilter } = this.carsFiltersService;
        const { competitors } = this.carsService.storeState.settings;
        const { brokersCompetitors, isBrokerToBroker } = this.carsService;
        if (isBrokerToBroker) {
            return brokersCompetitors || [];
        }
        if (!competitors) {
            return [];
        }
        return competitors.filter(item => competitorsFilter.includes(item));
    }

    mounted() {
        this.eventsManagerService.saveIsLoadEventByPOS(true);
        this.isPreparing = false;
    }

    get currentCompany() {
        const { isBrokerToBrand, currentBrandAsBroker } = this.carsService;
        if (isBrokerToBrand) {
            return (currentBrandAsBroker === BRAND_AS_BROKER_ANY ? this.userService.currentCompany : currentBrandAsBroker) || '';
        }
        return this.userService.currentCompany || '';
    }

    handleRowClick(day: Day) {
        this.$router.push({ name: 'cars-rates.analysis.table.day-popup', params: { day: String(day) } });
    }

    hasAvailablePrice(day: Day, company: string) {
        const car = this.getCarData(day, company) as CarsDocumentItemModel;
        return car.isAvailable && !!this.carsService.getCarPrice(car, company);
    }

    getPriceData(day: Day, competitor: string, compare: boolean = false) {
        const service = compare
            ? this.carsAnalysisService
            : this.carsService;
        const data = {
            value: '',
            hasPrice: false,
            style: {},
        };

        let car;
        if (this.carsAnalysisService.shouldComparePhases) {
            car = this.carsAnalysisService.getCarDataTableFromPhases(day, competitor, compare) as CarsDocumentItemModel;
        } else {
            car = service.getCarDataTable(day, competitor) as CarsDocumentItemModel;
        }
        const price = service.getCarPrice(car, competitor);
        const isNoData = this.carsService.isNoData(day);
        if (isNoData) {
            data.value = 'Out of range';
            data.style = '#818D98';
            return data;
        }
        if (service.allCars(day) === false || price === 0) {
            data.value = 'N/A';
            data.style = '#818D98';
            return data;
        }
        if (car.priceShown || car.priceNet || car.priceTotal) {
            const { isAvgPrice } = service.storeState.settings;
            const { currency } = service;
            const currencySymbol = this.helperService.currencySymbol(currency);
            data.hasPrice = true;
            data.value = `${currencySymbol} ${MIPriceFilter(price as number)} ${isAvgPrice ? '(avg.)' : ''}`;
            return data;
        }
        data.value = 'Sold Out';
        data.style = '#818D98';
        return data;
    }

    getCarData(day: Day, company: string) {
        return this.carsAnalysisService.getCarDataTable(day, company);
    }

    get comparisonValues() {
        this.disabledColumns = [];
        return this.carsAnalysisFilterService.comparisonValues;
    }

    get mainCompareTitle() {
        this.disabledColumns = [];

        return this.carsAnalysisFilterService.mainCompareTitle;
    }

    get showPriceDiff() {
        return this.carsService.showDiff;
    }

    formatPercent(value: number | string | null, maxFixed: number = 2, minFixed: number = 0) {
        if (value === null || value === 'NA') return 'NA';
        const formatConfig = {
            maximumFractionDigits: maxFixed,
            minimumFractionDigits: minFixed,
        };

        return `${(Number(value) * 100).toLocaleString('en-US', formatConfig)}%`;
    }

    formatPrice(value: number | string | null, maxFixed: number = 2, minFixed: number = 0) {
        if (value === null || value === 'NA') return 'NA';

        const formatConfig = {
            maximumFractionDigits: maxFixed,
            minimumFractionDigits: minFixed,
        };

        const formatedPrice = Number(value).toLocaleString('en-US', formatConfig);

        return `${this.currency} ${formatedPrice}`;
    }

    formatDiff(day: Day, competitor: string) {
        const diff = this.carsAnalysisService.getCarDifference(day, competitor, this.showPriceDiff);
        const formatMethod = this.showPriceDiff ? 'formatPrice' : 'formatPercent';

        return {
            value: this[formatMethod](diff),
            style: { color: diff < 0 ? '#E7472D' : 'grey' },
            onClick: () => this.handleRowClick(day),
        };
    }

    get tableData(): ITableData {
        const { days } = this.documentFiltersService;
        const dynamicColumns = [
            'Diff',
            CarTableColumn.Events,
            CarTableColumn.Availability,
            this.carsAnalysisService.shouldComparePhases ? CarTableColumn.Latest : String(this.mainCompareTitle),
            this.carsAnalysisService.shouldComparePhases ? CarTableColumn.Previous : this.comparisonValues.name,
        ];

        const mainValues = [] as ICell[];
        const compareValues = [] as ICell[];

        const insertPrice = (day: Day, type: 'main' | 'compare') => {
            const { value, style, hasPrice } = this.getPriceData(day, this.currentCompany, type === 'compare');
            const targetArray = {
                main: mainValues,
                compare: compareValues,
            }[type];

            targetArray
                .push({
                    value,
                    style: !hasPrice ? style : undefined,
                    onClick: () => this.handleRowClick(day),
                } as ICell);
        };

        days.forEach(day => {
            insertPrice(day, 'main');
            insertPrice(day, 'compare');
        });
        return [
            {
                isSticky: true,
                columns: [
                    {
                        title: CarTableColumn.Date,
                        subTitle: '',
                        data: days.map(day => ({
                            value: this.transformDate(day),
                            onClick: () => this.handleRowClick(day),
                        })),
                    }, {
                        title: CarTableColumn.Events,
                        subTitle: '',
                        dataType: DATA_TYPE.EVENT,
                        data: days.map(day => ({
                            onClick: () => this.handleRowClick(day),
                        })),
                    }, {
                        title: CarTableColumn.Availability,
                        subTitle: '',
                        dataType: DATA_TYPE.OCCUPANCY,
                        infoTooltip: CarTableAvailabilityTooltip,
                        data: days.map(day => {
                            const occupancy = Number(this.carsService.occupancy(day));
                            return {
                                value: occupancy ? `${occupancy}%` : '',
                                onClick: () => this.handleRowClick(day),
                            };
                        }),
                        titleStyle: { display: 'flex' },
                        options: {
                            occupancy: {
                                title: `Fleet Availability\n${this.average.countCars}\u00A0out\u00A0of\u00A0`
                                    + `${this.average.totalCars}\u00A0(${this.average.percent}%)`,
                                percent: this.average.percent / 100,
                            },
                        },
                    },
                    {
                        title: this.currentCompany,
                        titleStyle: { color: '#00759e' },
                        headerStyle: { width: '360px', 'min-width': '360px' },
                        dynamicColumns,
                        data: [
                            {
                                title: dynamicColumns[0],
                                data: days.map(day => this.formatDiff(day, this.currentCompany)),
                                headerStyle: { 'min-width': '120px' },
                            },
                            {
                                title: this.carsAnalysisService.shouldComparePhases ? CarTableColumn.Latest : String(this.mainCompareTitle) || '',
                                data: mainValues,
                                headerStyle: { 'min-width': '120px' },
                            },
                            {
                                title: this.carsAnalysisService.shouldComparePhases ? CarTableColumn.Previous : this.comparisonValues.name,
                                data: compareValues,
                                headerStyle: { 'min-width': '120px' },
                            }],
                    },
                ].filter(col => this.carsFiltersService.isAvailability || col.title !== CarTableColumn.Availability),
            },
            {
                columns: this.competitorsCompanies.map(competitor => ({
                    title: competitor,
                    headerStyle: { width: '100%', 'min-width': '360px' },
                    data: [
                        {
                            title: dynamicColumns[0],
                            data: days.map(day => this.formatDiff(day, competitor)),
                            headerStyle: { 'min-width': '120px' },
                        },
                        {
                            title: this.carsAnalysisService.shouldComparePhases ? CarTableColumn.Latest : String(this.mainCompareTitle) || '',
                            data: days.map(day => {
                                const car = this.getCarData(day, competitor) as CarsDocumentItemModel;
                                const price = this.carsService.getCarPrice(car, competitor);
                                const { value } = this.getPriceData(day, competitor, false);
                                const assessmentType = price ? this.carsService.getTableAssessment(price, day) : null;
                                const color = ['Out of range', 'N/A', 'Sold Out'].includes(value)
                                    ? '#818D98'
                                    : _.get({
                                        [ASSESSMENT_TYPES.BAD]: '#E7472D',
                                        [ASSESSMENT_TYPES.GOOD]: '#01B875',
                                    }, assessmentType || '', null);

                                return {
                                    value,
                                    style: {
                                        color,
                                    },
                                    onClick: () => this.handleRowClick(day),
                                };
                            }),
                            headerStyle: { 'min-width': '120px' },
                        },
                        {
                            title: this.carsAnalysisService.shouldComparePhases ? CarTableColumn.Previous : this.comparisonValues.name,
                            data: days.map(day => {
                                const car = this.getCarData(day, competitor) as CarsDocumentItemModel;
                                const price = this.carsService.getCarPrice(car, competitor);
                                const { value } = this.getPriceData(day, competitor, true);
                                const assessmentType = price ? this.carsService.getTableAssessment(price, day) : null;
                                const color = ['Out of range', 'N/A', 'Sold Out'].includes(value)
                                    ? '#818D98'
                                    : _.get({
                                        [ASSESSMENT_TYPES.BAD]: '#E7472D',
                                        [ASSESSMENT_TYPES.GOOD]: '#01B875',
                                    }, assessmentType || '', null);

                                return {
                                    value,
                                    style: {
                                        color,
                                    },
                                    onClick: () => this.handleRowClick(day),
                                };
                            }),
                            headerStyle: { 'min-width': '120px' },
                        },
                    ],
                })),
            },
        ];
    }
}
