import { container } from '@/inversify.container';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';

export default function RVSHotelLevelRoutes(basePath: string, prefix: string) {
    return {
        name: prefix,
        path: basePath,
        component: () => import('@/modules/rvs/pages/rvs-hotel.page.vue'),
        redirect: { name: `${prefix}.overview` },
        beforeEnter: use([
            container.get<FeaturesGuard>(FeaturesGuardS),
        ]),
        children: [
            {
                name: `${prefix}.overview`,
                path: 'overview',
                component: () => import('@/modules/rvs/pages/rvs-hotel-overview.page.vue'),
            },
            {
                name: `${prefix}.details`,
                path: 'details',
                component: () => import('@/modules/rvs/pages/rvs-hotel-details.page.vue'),
            },
        ],
    };
}
