
import { Component, Mixins } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import { OtelInsightsClusterPageLoading } from '@/modules/open-telemetry/decorators';
import ClusterPageMixin from '../mixins/cluster-page.mixin';
import InsightsClusterHeader from '../components/insights/insights-cluster-header.vue';
import InsightsClusterActions from '../components/insights/insights-cluster-actions.vue';
import InsightsClusterList from '../components/insights/insights-cluster-list/index.vue';

@OtelInsightsClusterPageLoading
@Component({
    components: {
        PageWrapper,
        InsightsClusterHeader,
        InsightsClusterActions,
        InsightsClusterList,
    },
})
export default class InsightsClusterPage extends Mixins(ClusterPageMixin) {}
