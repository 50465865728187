var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapper',{staticClass:"rvs-cluster-page",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('PageHeader')]},proxy:true},{key:"main",fn:function(){return [_c('ScoreTotal',{attrs:{"score":50,"diff":50,"isLoading":false}}),_c('ScoreOvertime',{attrs:{"scoreTrend":[50, 50, 50, 50, 50, 50, 50, 90, 50, 50, 50, 50, 50, 50, 90, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 90, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50 , 50, 50, 50, 50, 50, 40, 30, 50, 50, 50, 50 ,50, 50, 50, 50 ,50, 50, 50, 90, 50, 50, 50],"isLoading":false}}),_c('ScoreByHotel',{staticClass:"score-by-hotel-grid-item",attrs:{"scoreData":[{
                name: 'test1',
                x: 30,
                y: 20,
            }, {
                name: 'test2',
                x: 20,
                y: 10,
            }, {
                name: 'test3',
                x: 10,
                y: 5,
            }, {
                name: 'test4',
                x: 10,
                y: 6,
            }],"isLoading":false}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }