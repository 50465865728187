
/* eslint-disable no-param-reassign */
import { inject } from '@/inversify';
import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import { MAIN_DOCUMENT } from '@/modules/rates/constants';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import ClipText from '@/modules/common/filters/clip-text.filter';

import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import type RatesService from '@/modules/rates/rates.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type RatesPriceHistoryCommonService
    from '@/modules/price-history/rates-price-history-common.service';

import Day from '@/modules/common/types/day.type';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import HotelRooms from '@/modules/common/interfaces/hotelRooms.interface';
import RatesCheckinDayModel from '@/modules/rates/models/rates-checkin-day.model';
import { RatesCheckinDayAll } from '@/modules/rates/models/rates-provider-data.model';

import RatesPriceHistoryService, { RatesPriceHistoryServiceS } from '../../rates-price-history.service';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS } from '../../rates-price-history-all.service';
import RatesPriceHistoryModel from '../../models/rates-price-history.model';

interface TableData {
    hotelId: number | string;
    hotelName: string;
    roomName: string;
    price: number;
    rank: string;
    priceString: string | number;
    color: string;
    diff?: string;
    isGraphHidden: boolean;
    isMainHotel: boolean;
    isCompset: boolean;

    analysis?: {
        price: number;
        priceString: string | number;
    }[]
}

@Component({
    extends: DayTooltipTemplate,
    filters: {
        CutString(value: string) {
            if (!value) return value;
            return ClipText(value, 20);
        },
    },
})
export default class RatesPriceHistoryTooltip extends DayTooltipTemplate {
    @inject(RatesPriceHistoryServiceS) private ratesPriceHistoryService!: RatesPriceHistoryService;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(RatesPriceHistoryAllServiceS) private ratesPriceHistoryAll!: RatesPriceHistoryAllService;
    @inject(KEY.RatesPriceHistoryCommonService) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(ProvidersServiceS) private providersSerivce!: ProvidersService;
    @inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    @Prop({ type: Object, default: () => ({}) })
    private ignoreHotels!: { [hotelId: string]: boolean };

    @Prop({ type: String, default: PRICE_SHOWN.SHOWN })
    private priceShown!: PRICE_SHOWN;

    private get compsetId() {
        return this.isClusterPage
            ? this.$route.params.compsetId
            : null;
    }

    private get hotelColors() {
        return this.hotelsService.getHotelsGraphColor(this.compsetId) || {};
    }

    private get chartColors() {
        return this.userSettingsService.chartColors || [];
    }

    private get compareRooms() {
        const { comparisonValues } = this.ratesAnalysisFiltersService;
        return comparisonValues.map((_, docIndex) => this.getRoomsByDatakey(docIndex));
    }

    get date() {
        const { lastScanDate } = this.ratesPriceHistoryService;

        if (lastScanDate) {
            const d = new Date(lastScanDate);
            d.setDate(d.getDate() + this.day);
            return moment(d).format('MMM D, YYYY');
        }

        return this.$tc('rates.history');
    }

    get isActive() {
        const day = this.day as number;

        return !!this.focusElement && (day === 0 || !!day);
    }

    get isAnalysisMode() {
        if (!this.$route.name) return false;

        return this.$route.name.includes('.analysis');
    }

    get mainKey() {
        if (!this.isAnalysisMode) return this.$tc('titles.price');

        const v = String(this.ratesAnalysisFiltersService.mainCompareTitle!);
        return ClipText(v, 10);
    }

    get compareKeys() {
        if (!this.isAnalysisMode) return '';

        return this.ratesAnalysisFiltersService.comparisonValues
            .map(v => ClipText(this.$te(v.name) ? this.$tc(v.name) : v.name, 10));
    }

    get hotelsData() {
        const document = this.ratesPriceHistoryCommonService.documents.main as RatesPriceHistoryModel | null;

        if (!document || !document.trendData) {
            return {};
        }

        const dayKey = this.ratesPriceHistoryService.getDateByHistoryDay(-this.day);

        if (dayKey === null) {
            return {};
        }

        const { hotels } = (document.trendData[dayKey] as RatesCheckinDayModel) || {};

        return hotels;
    }

    get mainRooms() {
        return this.ratesCommonService.getAllRoomsByHotels(this.hotelsData);
    }

    get currentHotelId() {
        return +this.$route.params.hotelId;
    }

    get popupDay() {
        return +this.$route.params.day as Day;
    }

    get isClusterPage() {
        return (
            false
                || this.$route.name!.includes('cluster')
                || this.$route.name!.includes('chain')
        ) && !this.$route.name!.includes('.hotel');
    }

    get isAllChannelsMode() {
        return this.ratesPriceHistoryCommonService.isAllChannels;
    }

    get currentTableData() {
        return this.isAllChannelsMode
            ? this.tableDataAll
            : this.tableData;
    }

    get tableData() {
        const { hotels } = this.ratesPriceHistoryCommonService;

        if (!hotels) return [];

        const roomList = hotels
            .filter(hotel => !this.ignoreHotels[hotel])
            .map(this.toTableData.bind(this))
            .filter(row => !!row) as TableData[];

        if (this.compsetRow) {
            roomList.push(this.compsetRow);
        }

        roomList
            .sort((a, b) => +b.price - +a.price)
            .filter(row => !row.isCompset && row.price && row.price > 0)
            .forEach((row, i, arr) => {
                row.rank = String(arr.length - i);
            });

        const mainRoom = roomList.find(room => room.hotelId === this.currentHotelId);
        const compsetRoom = roomList.find(room => room.isCompset);
        const compsetIndex = compsetRoom ? roomList.indexOf(compsetRoom) : 0;

        let startIndex = compsetIndex - 3;
        let endIndex = compsetIndex + 4;

        const additionalEnd = -Math.min(0, startIndex);
        const additionalStart = Math.max(0, endIndex - roomList.length);

        startIndex -= additionalStart;
        startIndex = Math.max(0, startIndex);

        endIndex += additionalEnd;
        endIndex = Math.min(roomList.length, endIndex);

        const roomRange = roomList.slice(startIndex, endIndex);

        if (mainRoom) {
            const isMainRoomInRange = roomRange.includes(mainRoom);

            if (isMainRoomInRange) return roomRange;

            if (compsetRoom) {
                if (mainRoom.price > compsetRoom.price) {
                    roomRange.splice(0, 1, mainRoom);
                } else {
                    roomRange.splice(roomRange.length - 1, 1, mainRoom);
                }
            } else {
                roomRange.splice(roomRange.length - 1, 1, mainRoom);
            }
        }

        return roomRange;
    }

    get tableDataAll() {
        const { providers } = this.ratesPriceHistoryAll;

        if (!providers) {
            return [];
        }

        const getAveragePrice = (items: RatesCheckinDayAll) => {
            if (!items.average) {
                return 0;
            }

            const price = this.ratesCommonService.switchPrice(items.average.room, this.priceShown)!;

            return Number.isNaN(price)
                ? 0
                : price;
        };

        const { lastScanDate } = this.ratesPriceHistoryService;

        if (lastScanDate === null) return [];

        const providersDataset = this.ratesPriceHistoryAll
            .getSuitableProviderByDay(-this.day)!;

        const averagePrice = providersDataset
            ? getAveragePrice(providersDataset)
            : 0;

        const tablePriceHistoryData: TableData[] = providers
            .map((provider: string, index: number) => {
                const hotelName = (this.providersSerivce.getProviderLabel(provider)
                    || this.$t(`compsetRate.${provider}`)) as string;

                let row = {
                    hotelId: provider,
                    hotelName,
                    roomName: '---',
                    priceString: '---',
                    diff: '---',
                    price: -1,
                    color: provider === 'average'
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.chartColors[index],
                    isGraphHidden: false,
                    isMainHotel: false,
                    isCompset: false,
                    rank: '',
                } as TableData;

                const isProviderExists = providersDataset
                    && providersDataset[provider];

                if (!isProviderExists) {
                    return row;
                }

                const providerData = providersDataset[provider];

                const isMainHotel = provider === 'average';
                const isGraphHidden = false;
                const isCompset = false;
                const price = isMainHotel
                    ? averagePrice
                    : this.ratesCommonService.switchPrice(providerData.room, this.priceShown)!;

                const { pax } = providerData;

                const diff = !isMainHotel && price > 0
                    ? ((price - averagePrice) / averagePrice) * 100
                    : null;

                row = {
                    ...row,
                    isMainHotel,
                    isGraphHidden,
                    isCompset,
                    hotelName,
                    price,

                    diff: diff === null
                        ? '---'
                        : `${Math.round(diff)}%`,

                    color: isMainHotel
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.chartColors[index],
                };

                row.priceString = this.formatPrice(row.price, pax);

                return row;
            })
            .filter(hotel => !this.ignoreHotels[hotel.hotelId]);

        return tablePriceHistoryData.sort((a, b) => b.price - a.price);
    }

    private get compsetRow() {
        const document = this.ratesPriceHistoryCommonService.documents.main as RatesPriceHistoryModel | null;
        const { compset } = this.ratesPriceHistoryCommonService;

        if (!document || !document.trendData || !compset) {
            return null;
        }

        const dayKey = this.ratesPriceHistoryService.getDateByHistoryDay(-this.day);

        if (dayKey === null) {
            return null;
        }

        const { hotels } = (document.trendData[dayKey] as RatesCheckinDayModel) || {};

        if (!hotels || !Object.keys(hotels).length) {
            return null;
        }

        const { type } = compset;
        const { competitors } = this.documentFiltersService;
        const competitorRooms = competitors
            .reduce((roomsAcc, hotelId) => {
                const { rooms } = hotels[hotelId] || {};

                if (!rooms || !rooms[Number(Object.keys(rooms)[0])]) {
                    return roomsAcc;
                }

                return {
                    ...roomsAcc,
                    [hotelId]: hotels[hotelId].rooms[Number(Object.keys(hotels[hotelId].rooms)[0])][0],
                };
            }, {} as HotelRooms);

        const price = this.ratesCommonService.getCompsetPrice(competitorRooms, type, this.priceShown);

        if (!price) {
            return null;
        }

        const priceString = this.formatPrice(price);

        const row = {
            hotelId: -1,
            roomName: '',
            hotelName: String(this.$t(`compsetRate.${compset.type}`)),
            rank: '',
            diff: '---',
            price,
            priceString,
            color: 'transparent',

            isGraphHidden: false,
            isCompset: true,
            isMainHotel: false,
        } as TableData;

        if (this.isAnalysisMode) {
            const { comparisonValues } = this.ratesAnalysisFiltersService;
            const compsetData = comparisonValues
                .map((_, docIndex) => this.getCompsetData(this.compareRooms[docIndex], this.priceShown));

            row.analysis = compsetData.map(({ price }) => ({
                price: price || -1,
                priceString: this.formatPrice(price || -1),
            }));
        }

        return row;
    }

    private getRoomsByDatakey(documentIndex = MAIN_DOCUMENT) {
        const { comparisonValues, comparisonKey } = this.ratesAnalysisFiltersService;
        const isScanDateValid = !!this.getCurrentScanDate(this.day);
        const isAnalysis = documentIndex !== MAIN_DOCUMENT;
        const key = isAnalysis
            ? comparisonValues[documentIndex]?.name
            : 'main';

        if (!isScanDateValid || !key) return {};

        const diffDays = isAnalysis && comparisonKey === 'diffDays'
            ? comparisonValues[documentIndex].value as number
            : 0;

        this.ratesPriceHistoryService.setDataKey(key);

        return this.ratesPriceHistoryService
            .getSuitableRoomByDay(-this.day, diffDays);
    }

    private getCurrentScanDate(day: number) {
        const { lastScanDate } = this.ratesPriceHistoryService;

        if (!lastScanDate) return null;

        if (!day && day !== 0) return null;

        const currentScanDay = new Date(lastScanDate);
        currentScanDay.setDate(currentScanDay.getDate() + day);

        return currentScanDay;
    }

    private toTableData(hotelId: number): TableData | null {
        const { priceShown } = this;
        const isMainHotel = hotelId === this.currentHotelId;
        const roomData = this.mainRooms[hotelId];

        const hotelName = this.hotelsService.getHotelName(hotelId) || '---';
        const price = roomData
            ? this.ratesCommonService.switchPrice(roomData, priceShown)
            : -1;

        const roomName = price! >= 1 ? roomData.roomName || '' : '-';
        const pax = this.hotelsData[hotelId]?.pax;

        const priceString = this.formatPrice(price, pax);
        const hotelColor = this.hotelColors[hotelId];

        const row = {
            hotelId,
            hotelName,
            roomName,
            price,
            rank: '',
            priceString,
            diff: '---',
            color: isMainHotel
                ? CURRENT_HOTEL_GRAPH_COLOR
                : hotelColor,

            isGraphHidden: this.ignoreHotels[hotelId],
            isMainHotel,
            isCompset: false,
        } as TableData;

        if (priceString === '---') {
            row.roomName = '-';
        }

        if (this.isAnalysisMode) {
            const { comparisonValues } = this.ratesAnalysisFiltersService;
            row.analysis = comparisonValues
                .map((_, docIndex) => this.getCompareDataFor(+hotelId, docIndex));
        }

        return row;
    }

    private getCompsetData(rooms: HotelRooms, priceShown: PRICE_SHOWN) {
        const competitorRooms = {
            ...rooms,
        };

        delete competitorRooms[this.currentHotelId!];

        const compset = this.compsetId
            ? this.clusterCompsetsService.getCompsetById(this.compsetId)
            : this.compsetsService.currentCompset;

        if (!compset) return { price: null, compset: null };

        const price = this.ratesCommonService
            .getCompsetPrice(competitorRooms, compset!.type, priceShown);

        return { price, compset };
    }

    private getCompareDataFor(hotelId: number, documentIndex = 0) {
        const roomData = this.compareRooms[documentIndex][+hotelId];
        const price = this.ratesService.switchPrice(roomData);
        const pax = !this.day
            ? this.ratesAnalysisService.getCheckinDay(this.popupDay)?.hotels[hotelId]?.pax
            : null;

        return {
            price: price || -1,
            priceString: this.formatPrice(price, pax),
        };
    }

    private formatPrice(price: number | null, pax?: number[] | null) {
        const { currency } = this.ratesPriceHistoryService;
        const currencySymbol = this.helperService.currencySymbol(currency || '');

        if (price === null) return '---';

        switch (price) {
            case PRICE.SOLD_OUT:
                return pax
                    ? this.$tc('nog', 0, [pax])
                    : this.$tc('soldOut');
            case PRICE.NA:
                return '---';

            default:
                return currency
                    ? currencySymbol + price.toFixed(2).replace(/\.0+$/, '')
                    : price.toFixed(2).replace(/\.0+$/, '');
        }
    }
}
