
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import EventTypesPoints from '@/modules/common/components/event-types-points.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import isDayType from '@/modules/common/component-validators/is-date.validator';

@Component({
    components: {
        CalendarEventsContainer,
        CalendarItemWrapper,
        EventTypesPoints,
    },
})
export default class MarketsCalendarItemNA extends Vue {
    @inject(KEY.DocumentFiltersService) documentFiltersService!: DocumentFiltersService;
    @inject(UserServiceS) userService!: UserService;

    @Prop({
        required: true,
        type: Number,
        validator: isDayType,
    })
    private day!: Day;

    @Prop({
        required: true,
        type: String,
    })
    private source!: string;

    get hotelId() {
        return this.userService.currentHotelId;
    }

    get month(): Month {
        return this.documentFiltersService.storeState.settings.month;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get dayRoute() {
        const isDayMarketsOpened = this.$route.name!.includes('day-markets-source');
        return {
            name: isDayMarketsOpened ? this.$route.name! : `${this.$route.name!}.day-markets-source`,
            params: { day: String(this.day), source: String(this.source) },
        };
    }
}
