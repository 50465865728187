
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import moment from 'moment';
import CalendarChain from '@/modules/common/components/ui-kit/calendar-chain.vue';

import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type ClusterDiLiteService from '@/modules/cluster/cluster-di-lite.service';
import type ClusterService from '@/modules/cluster/cluster.service';

import { COLOR_NAME, ASSESSMENT_TYPES, ASSESSMENT_COLORS } from '@/modules/common/constants';
import type Day from '@/modules/common/types/day.type';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import PAGES from '@/modules/common/constants/pages.constant';
import DayTooltip from '../../_common/day-tooltip.vue';

@Component({
    components: {
        CalendarChain,
        DayTooltip,
    },
})
export default class DaysSet extends Vue {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.ClusterDiLiteService) private clusterDiLiteService!: ClusterDiLiteService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;

    @Prop({ type: Object })
    hotelData!: ClusterHotelsRatesModel;

    @Prop({ type: String, default: '' })
    title!: string;

    tooltip: {
        element: HTMLElement | null;
        color: COLOR_NAME;
        payload: {
            date: string;
            value: string;
        }
    } = {
        element: null,
        color: COLOR_NAME.WHITE,
        payload: {
            date: '',
            value: '',
        },
    };

    isCalendarLoading = false;

    get isOutOfRange() {
        return !this.compset || !this.compset.type;
    }

    get compset() {
        if (!this.hotelData.compsetMain) return null;

        return this.clusterService
            .getCompset(
                this.hotelData.compsetMain.id,
                this.hotelData.hotelId,
            )!;
    }

    get assessments() {
        const { month, year } = this.documentFiltersService;

        if (this.isCalendarLoading) {
            return [];
        }

        const dayData = this.documentFiltersService.days
            .map(d => this.clusterDiLiteService.getDayData(d, this.hotelData.compsetMain! || {}));

        return dayData.map((d, i) => {
            const day = i + 1 as Day;
            const isPastDay = this.documentFiltersService.isPreviousDay(day);

            const assessment: ASSESSMENT_TYPES = d
                ? this.clusterDiLiteService.getAssessment(this.hotelData.hotelId, d.highest, d.average)
                : ASSESSMENT_TYPES.NO_DATA;

            const colorModificator = ASSESSMENT_COLORS[assessment];
            const date = new Date(year, month, i + 1);

            let value = '';
            switch (assessment) {
                case ASSESSMENT_TYPES.GOOD:
                    value = this.$tc('dilite.match');
                    break;
                case ASSESSMENT_TYPES.NORMAL:
                    value = this.$tc('dilite.partial');
                    break;
                case ASSESSMENT_TYPES.BAD:
                    value = this.$tc('dilite.nomatch');
                    break;
                case ASSESSMENT_TYPES.SOLD_OUT:
                    value = this.$tc('soldOut');
                    break;
                case ASSESSMENT_TYPES.NO_DATA:
                    value = this.hotelData.compsetMain
                        ? this.$tc('noData')
                        : this.$tc('outOfRange');
                    break;
                default:
                    throw new Error('Wrong ASSESSMENT_TYPE');
            }

            return {
                isPastDay,
                isNA: false,
                colorModificator,
                payload: {
                    date: moment(date).format('MMM DD, YYYY'),
                    value,
                },
            };
        });
    }

    get isLoading() {
        return this.isCalendarLoading || !this.hotelData.documentLoaded;
    }

    // Lazy load of document, send request only if component is in viewport.
    async loadViewportedHotel(isVisible: boolean) {
        if (this.isCalendarLoading || !isVisible || this.hotelData.documentLoaded) {
            return;
        }

        this.isCalendarLoading = true;
        await this.clusterService
            .loadDaysData(PAGES.DILITE, this.hotelData.hotelId)
            .finally(() => {
                this.isCalendarLoading = false;
            });
    }

    handleHover(element: HTMLElement) {
        if (this.isCalendarLoading) {
            return;
        }

        const day = Number(element.getAttribute('data-day'));
        if (day === 0) {
            return;
        }

        const { payload, colorModificator } = this.assessments[day - 1];
        this.tooltip = {
            element,
            color: colorModificator,
            payload,
        };
    }

    handleClick(element: HTMLElement) {
        if (this.isCalendarLoading || this.isOutOfRange) {
            return;
        }

        const day = element.getAttribute('data-day')!;
        const { id: compsetId } = this.hotelData.compsetMain || this.hotelData.compsets[0] || {};

        if (!compsetId || !day) {
            return;
        }

        const hotelId = String(this.hotelData.hotelId);

        this.$router.push({
            name: `${this.$route.name}.day-rate`,
            params: {
                hotelId,
                compsetId,
                day,
            },
        });
    }

    handleOut() {
        this.tooltip.element = null;
    }
}
