import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';

import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import NumberOfGuestsService, { NumberOfGuestsServiceS } from '@/modules/number-of-guests/number-of-guests.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import { i18n } from '@/modules/translations/translations.service';

import type DiLiteAllChannelsService from '../di-lite-all-channels.service';
import { DILiteFilterDeviceLabels } from '../models/all-channels-settings.model';

@Component
export default class DiLiteFilterItemsMixin extends Vue {
    @inject(RoomTypesServiceS) protected roomTypesService!: RoomTypesService;
    @inject(MealTypesServiceS) protected mealTypesService!: MealTypesService;
    @inject(KEY.DiLiteAllChannelsService) protected allChannelsService!: DiLiteAllChannelsService;
    @inject(NumberOfGuestsServiceS) protected numberOfGuestsService!: NumberOfGuestsService;
    @inject(UserSettingsS) protected userSettingsService!: UserSettingsService;
    @inject(ProvidersServiceS) protected providersService!: ProvidersService;

    get priceTypeItems() {
        return [
            {
                name: this.$tc('price.lowest'),
                value: PRICE_TYPE.LOWEST,
            },
            {
                name: this.$tc('price.lowest_flex'),
                value: PRICE_TYPE.LOWEST_FLEX,
            },
            {
                name: this.$tc('price.best_flex'),
                value: PRICE_TYPE.BEST_FLEX,
            },
            {
                name: this.$tc('price.non_refundable'),
                value: PRICE_TYPE.NON_REFUNDABLE,
            },
        ];
    }

    get shownProviders() {
        return this.allChannelsService.shownProviders;
    }

    set shownProviders(value: string[]) {
        this.allChannelsService.shownProviders = value;

        const { provider } = this.allChannelsService;

        if (!value.includes(provider!) && provider !== 'bcom') {
            [this.allChannelsService.provider] = value;
        }
    }

    get shownProvidersItems() {
        return this.providersService
            .toItemsList(this.allChannelsService.providers);
    }

    get losItems() {
        const { availableLoses } = this.allChannelsService;

        return availableLoses
            .map(e => ({
                value: e,
                name: this.$t('filters.los.num', [e]).toString(),
            }));
    }

    get posItems() {
        const { compset } = this.allChannelsService;

        const posList = compset
            ? compset.pos
            : ['US'];

        return posList.map(name => ({ name, value: name }));
    }

    get numberOfGuestsItems() {
        return Array
            .from({ length: 10 })
            .map((_, i) => i + 1)
            .map(e => ({
                value: e,
                name: this.$tc('filters.guests', e, [e]).toString(),
            }));
    }

    get roomTypeItems() {
        if (!this.roomTypesService.rooms) {
            return [{
                value: ANY_ROOM_TYPE.id,
                name: i18n.tc(ANY_ROOM_TYPE.displayName),
            }];
        }

        return this.roomTypesService.rooms
            .filter((roomType: RoomTypeModel) => !!roomType.name)
            .map(
                (roomType: RoomTypeModel) => ({
                    value: roomType.id,
                    name: i18n.tc(roomType.displayName),
                }),
            );
    }

    get providers() {
        return this.allChannelsService.providers;
    }

    get mealTypeItems() {
        const { mealTypes } = this.mealTypesService;
        if (!mealTypes) {
            return [];
        }
        return mealTypes.map((mealType: MealTypeModel) => ({
            value: mealType.id as (number | string),
            name: this.$tc(mealType.displayName),
        }));
    }

    get devicesItems() {
        return Object
            .entries(DILiteFilterDeviceLabels)
            .map(([value, name]) => ({ value, name: this.$tc(name) }));
    }
}
