
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type DeepCompsetAnalysisService from '@/modules/deep-analysis/deep-analysis.service';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/cluster/cluster-deep-analysis.service';

@Component
export default class ExcelBtn extends Vue {
    @inject(DeepCompsetAnalysisClusterServiceS) private deepCompsetAnalysisClusterService!: DeepCompsetAnalysisClusterService;
    @inject(KEY.DeepCompsetAnalysisService) private deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    @Prop({
        type: Boolean,
        default: false,
    })
    isCluster!: boolean;

    async handleClick() {
        if (this.isCluster) {
            await this.deepCompsetAnalysisClusterService.downloadExcel();
        } else {
            await this.deepCompsetAnalysisService.downloadExcel();
        }
    }
}
