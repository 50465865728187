
import { Component } from 'vue-property-decorator';
import SubpageController from '../subpage-controller.vue';

import EmailReportSectionGeneral from '../sections/email-reports-general.section.vue';
import EmailReportSectionHotel from '../sections/email-reports-hotel.section.vue';
import EmailReportSection from '../sections/email-reports.section.vue';

import { SettingsTab } from '../../interfaces/settings-tab.abstract';

@Component({
    components: {
        SubpageController,
        EmailReportSectionGeneral,
        EmailReportSectionHotel,
        EmailReportSection,
    },
})
export default class ReportPropertiesTab extends SettingsTab {
    static title = 'settings.reports.htmlTitle';

    get isGeneral() {
        return !this.$route.name?.startsWith('hotel.');
    }
}
