
import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import AllChannels from '@/modules/di-lite/components/all-channels/all-channels.vue';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';
import ExcelBtn from '@/modules/common/components/excel-btn.vue';
import type DiLiteMarketService from '../../di-lite-market.service';
import AllChannelsFilters from './filter-bar.vue';

@Component({
    components: {
        AllChannels,
        AllChannelsFilters,
        CustomTooltip,
        ExcelBtn,
    },
})
export default class AllChannelsSection extends Vue {
    @inject(KEY.DiLiteMarketService) private diLiteMarketService!: DiLiteMarketService;

    get isExcelLoading() {
        return this.diLiteMarketService.isExcelLoading;
    }
}

