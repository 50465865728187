

import WeekdayLetter from '@/modules/cluster/filters/weekend-letter.filter';
import type Day from '@/modules/common/types/day.type';
import { inject } from '@/inversify';
import _ from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import LocationAvailabilityService, { LocationAvailabilityServiceS } from '../location-availability.service';

@Component({
    filters: {
        WeekdayLetter,
    },
    components: {
        LoaderWrapper,
    },
})
export default class LocationAvailabilityTable extends Vue {
    @inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
    @inject(LocationAvailabilityServiceS) private lAvailabilityService!: LocationAvailabilityService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(UserServiceS) private userService!: UserService;

    private tableHeight = '0px';

    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.height;
            this.lAvailabilityService.resetLoading();
        });
    }

    @Watch('isClosedOnly')
    onClosedOnlyChange() {
        this.scrollTop();
    }

    @Watch('documents')
    onPosChange() {
        this.scrollTop();
    }

    scrollTop() {
        const el: HTMLElement | null = document.querySelector('.locations-list');
        if (el) {
            el.scrollTo({ top: 0 });
        }
    }

    get height() {
        const el: HTMLElement | null = document.querySelector('.locations-list');
        const footer: HTMLElement | null = document.querySelector('footer');

        if (el && footer) {
            const table = el.getBoundingClientRect();
            const footerBox = footer.getBoundingClientRect();
            return `calc(100vh - ${table.top + footerBox.height + 35}px)`;
        }

        return '100vh';
    }

    get currentCompany() {
        return this.userService.currentCompany;
    }

    get documents() {
        return this.lAvailabilityService.documents;
    }

    get locations() {
        const { providers } = this;
        if (!providers) {
            return [];
        }

        let { currentPickupLocations } = this.lAvailabilityFiltersService;
        currentPickupLocations = currentPickupLocations
            .filter(location => !this.documents || (Object.keys(this.documents)).includes(location.value));
        if (this.isClosedOnly) {
            return currentPickupLocations
                .filter(location => providers.some(provider => this.isAvailableProvider(location.value, provider)));
        }

        return currentPickupLocations;
    }

    get providers() {
        const { providers } = this.lAvailabilityFiltersService;
        if (providers && this.currentCompany && providers.some(provider => provider === this.currentCompany)) {
            const sortedProviders = providers.filter(provider => provider !== this.currentCompany);
            sortedProviders.unshift(this.currentCompany);
            return sortedProviders;
        }
        return providers;
    }

    get monthDays() {
        return this.documentFiltersService.days;
    }

    get closedDays() {
        return this.lAvailabilityService.closedDays;
    }

    get isClosedOnly() {
        return this.lAvailabilityFiltersService.isClosedOnly;
    }

    isAvailableProvider(location: string, provider: string) {
        const closedDaysCount = this.getClosedDaysCount(location, provider);
        const isAllClosed = closedDaysCount === this.monthDays.length;
        return !this.isClosedOnly || (this.isClosedOnly && isAllClosed);
    }

    isMonday(day: Day) {
        return this.date(day).getDay() === 1;
    }

    isSunday(day: Day) {
        return this.date(day).getDay() === 0;
    }

    isToday(day: Day) {
        return this.documentFiltersService.isCurrentDay(day);
    }

    date(day: Day) {
        const { month, year } = this.documentFiltersService;
        return new Date(year, month, day);
    }

    getStatus(day: Day, locationId: string, provider: string) {
        const locationsAvailability = this.lAvailabilityService.getLocationsAvailability(locationId, provider, String(day));

        if (locationsAvailability === false) {
            return 'available';
        }
        if (locationsAvailability === true) {
            return 'unavailable';
        }

        return 'no-data';
    }

    getClosedDaysCount(locationId: string, provider: string) {
        return _.get(this.closedDays, `${locationId}.${provider}`, 0);
    }
}

