import { container } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type MarketsHistoryService from '@/modules/common/modules/markets-history/markets-history.service';
import type RatesPriceHistoryCommonService from '@/modules/price-history/rates-price-history-common.service';
import { otelBaseComponentLoading } from '../_utils';

/**
 * Add logs to all rates day popup Vue components
 */
export function OtelRateDayPopupLoading(constructor: Function) {
    const ratesPriceHistoryCommonService = container.get<RatesPriceHistoryCommonService>(KEY.RatesPriceHistoryCommonService);
    const { ratesSettings, documentSettings, loading } = ratesPriceHistoryCommonService;

    const settings = {
        ...ratesSettings,
        ...documentSettings,
    } as Record<string, any>;

    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to all markets day popup Vue components
 */
export function OtelMarketDayPopupLoading(constructor: Function) {
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);
    const marketsHistoryService = container.get<MarketsHistoryService>(KEY.MarketsHistoryService);

    const settings = {
        ...marketsHistoryService.marketSettings,
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const { loading } = marketsHistoryService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}
