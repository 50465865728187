
import { Mixins, Component } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import { OtelBenchmarkClusterPageLoading } from '@/modules/open-telemetry/decorators';
import DeepAnalysisClusterTable from '../components/deep-analysis/da-hotels-list/index.vue';
import DeepAnalysisHeader from '../components/deep-analysis/da-cluster-header.vue';
import DeepAnalysisActions from '../components/deep-analysis/da-cluster-actions.vue';
import DeepAnalysisClusterInfo from '../components/deep-analysis/da-cluster-info-text.vue';
import ClusterPagination from '../components/_common/cluster-pagination.vue';
import ClusterPageMixin from '../mixins/cluster-page.mixin';

@OtelBenchmarkClusterPageLoading
@Component({
    components: {
        PageWrapper,
        DeepAnalysisHeader,
        DeepAnalysisActions,
        DeepAnalysisClusterInfo,
        DeepAnalysisClusterTable,
        ClusterPagination,
    },
})
export default class DeepAnalysisPage extends Mixins(ClusterPageMixin) {}
