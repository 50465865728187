
import { Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import CarPriceTable from '@/modules/cars/components/car-price-table.vue';
import moment from 'moment';

@Component({
    extends: DayTooltipTemplate,
    components: {
        CarPriceTable,
    },
})
export default class DayRateTooltip extends DayTooltipTemplate {
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(KEY.DocumentFiltersService) private documentFilterService!: DocumentFiltersService;

    get date() {
        const { day } = this;
        const { year, month } = this.documentFilterService;
        const date = new Date(year, month, day);

        const formatter = new Intl
            .DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        return formatter.format(date);
    }

    get shopDate() {
        const date = this.carsService.calculateShopDate(this.day);

        if (!date) {
            return null;
        }

        return moment(date).format('DD/MM/YYYY');
    }

    get occupancyTitle() {
        if (!this.occupancyLevel) {
            return null;
        }

        return `${this.countCars} out of ${this.totalCars} (${this.occupancyLevel}%)`;
    }

    get occupancyLevel() {
        return this.carsService.occupancy(this.day) || 0;
    }

    get countCars() {
        const { countCars } = this.carsService.fleetAvailability(this.day);
        return countCars;
    }

    get totalCars() {
        const { totalCars } = this.carsService.fleetAvailability(this.day);
        return totalCars;
    }

    get isOutOfRange() {
        const cars = this.carsService.allCars(this.day);
        return cars === null;
    }
}
