
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import RankingHotelCompsetGroup from '@/modules/cluster/components/ranking/ranking-hotel-compset-group.vue';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import CustomTooltipV2, { TOOLTIP_DIRECTION } from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import Skeleton from '../_common/hotels-list/skeleton.vue';
import RankingChainProvider from './ranking-chain-provider.vue';
import ClusterPagination from '../_common/cluster-pagination.vue';

type HintData = {
    provider: string;
    compsetId: string;
    hotelId: number;
    document: Object;
};

@Component({
    components: {
        RankingHotelCompsetGroup,
        ProviderCard,
        Skeleton,
        ClusterPagination,
        CustomTooltipV2,
        RankingChainProvider,
    },
})
export default class RankingChainList extends Vue {
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(ClusterRankingServiceS) private clusterRankingService!: ClusterRankingService;
    @inject(HelperServiceS) private helperService!: HelperService;

    isFirstLoading = true;
    height = '0px';
    hint: {
        rect: DOMRect | null;
        data: HintData | null;
    } = {
        rect: null,
        data: null,
    };
    tooltipDirection = TOOLTIP_DIRECTION.LEFT_RIGHT;

    get providers() {
        return this.clusterRankingService.providers;
    }

    get hotels() {
        if (this.clusterRankingService.clusterRankingsData) {
            this.clusterRankingService.clusterRankingsData
                .sort((a, b) => a.hotelName.localeCompare(b.hotelName));
        }
        return this.clusterRankingService.clusterRankingsData;
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    get isSearchQuery() {
        return this.clusterService.clusterHotelSearchQuery;
    }

    getLogo(provider: string) {
        return this.helperService.providerLogo(provider);
    }

    setTooltip(data: { position: DOMRect, payload: HintData } | null) {
        if (!data?.payload) {
            this.hint.rect = null;
            return;
        }

        this.hint.rect = data.position;
        this.hint.data = data.payload;
    }
}
