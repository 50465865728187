
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import Calendar from '@/modules/common/components/ui-kit/calendar.vue';
import { ASSESSMENT_TYPES } from '@/modules/common/constants';
import Day from '@/modules/common/types/day.type';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';

import RVSCalendarService, { RVSCalendarServiceS } from '../../rvs-calendar.service';
import ScoreCard from '../_score-card.vue';

@Component({
    components: {
        Calendar,
        DateDocumentFilter,
        ScoreCard,
    },
})
export default class RVSCalendar extends Vue {
    @inject(RVSCalendarServiceS) private rvsCalendarService!: RVSCalendarService;
    @inject(KEY.DocumentFiltersService) private documentFilterService!: DocumentFiltersService;

    get year() {
        return this.documentFilterService.year;
    }

    get month() {
        return this.documentFilterService.month;
    }

    get isLoading() {
        const { data, isLoading } = this.rvsCalendarService;

        return !data || isLoading;
    }

    get currentDay() {
        return this.rvsCalendarService.storeState.currentDay;
    }

    hasData(day: Day) {
        return !this.rvsCalendarService.isNoData(day);
    }

    setCurrentDay(day: Day) {
        this.rvsCalendarService.storeState.currentDay = day;
    }

    getAverage(day: Day) {
        return this.rvsCalendarService.getCompsetAverageValue(day);
    }

    getMainValue(day: Day) {
        return this.rvsCalendarService.getMainValue(day);
    }

    getDiff(day: Day) {
        return this.rvsCalendarService.getDifference(day);
    }

    getColor(day: Day) {
        const diff = this.rvsCalendarService.getAssessment(day);

        switch (diff) {
            case ASSESSMENT_TYPES.BAD: return 'red';
            case ASSESSMENT_TYPES.GOOD: return 'green';
            default: return 'yellow';
        }
    }
}
