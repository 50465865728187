import VueRouter from 'vue-router';

const routerData = {
    router: null as unknown as VueRouter,
};

export function initRouterData(router: VueRouter) {
    routerData.router = router;
}

export { routerData };
