
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import ToggleDiff from '@/modules/common/modules/rates/components/toggle-diff.vue';

import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import RatesAnalysisComparison from '@/modules/rates/components/analysis/rates-analysis-filters.vue';
import type RatesService from '../rates.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '../rates-analysis.service';
import RatesOutOfRangeMessage from './rates-out-of-range-message.vue';

@Component({
    components: {
        RatesActions,
        RatesHeader,
        RatesOutOfRangeMessage,
        RatesAnalysisComparison,
        PageWrapper,
        TogglePrices,
        ToggleDiff,
    },
})
export default class TablePageLayout extends Vue {
    @inject(KEY.RatesService)
    private ratesService!: RatesService;

    @inject(RatesAnalysisServiceS)
    private ratesAnalysisService!: RatesAnalysisService;

    get showPriceDiff() {
        return this.ratesService.showDiff;
    }

    get showDiffToggle() {
        return !this.isAllChannelsMode
            && this.ratesService.data
            && !this.isMultiDocMode;
    }

    get isLoading() {
        const { isLoading: ratesLoading } = this.ratesService;
        const { isLoading: ratesAnalysisLoading } = this.ratesAnalysisService;

        if (this.isAnalysisMode) {
            return ratesLoading || ratesAnalysisLoading;
        }

        return ratesLoading;
    }

    get isOutOfRange() {
        if (this.isAnalysisMode) {
            return this.ratesAnalysisService.isAllOutOfRange()
                || this.ratesService.isOutOfRange();
        }

        return this.ratesService.isOutOfRange();
    }

    get isMultiDocMode() {
        return this.isAnalysisMode
            && this.ratesAnalysisService.isMultiDocMode;
    }

    get isNoAnalysisData() {
        return !this.ratesAnalysisService.isLoading
            && !this.ratesAnalysisService.data.length;
    }

    get isAnalysisMode() {
        return this.$route.name?.includes('.analysis') ?? false;
    }

    get isAllChannelsMode() {
        return this.ratesService.isAllChannelsMode;
    }
}
