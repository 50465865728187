
import { Vue, Component } from 'vue-property-decorator';
import RankingProviders from '@/modules/ranking/components/ranking-providers.vue';
import RankingActions from '@/modules/ranking/components/ranking-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RankingHeader from '@/modules/ranking/components/ranking-header.vue';
import { OtelRankingHotelPageLoading } from '@/modules/open-telemetry/decorators';

@OtelRankingHotelPageLoading
@Component({
    components: {
        RankingProviders,
        RankingActions,
        PageWrapper,
        RankingHeader,
    },
})
export default class RankingPage extends Vue {}
