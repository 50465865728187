

import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import type DeepCompsetAnalysisService from '@/modules/deep-analysis/deep-analysis.service';
import SkeletonLine from '@/modules/cluster/components/_common/skeleton-line.vue';

@Component({ components: { SkeletonLine } })
export default class DeepAnalysisInfo extends Vue {
    @inject(KEY.DeepCompsetAnalysisService) deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    get text() {
        const isBook = this.deepCompsetAnalysisService.showBy.startsWith('book');
        const days = this.deepCompsetAnalysisService.showBy.split('.')[1];
        return isBook
            ? this.$t('deepcomp.cal.lastmsg', [days]).toString()
            : this.$t('deepcomp.cal.nextmsg', [days]).toString();
    }

    get comparedValueName() {
        const { compareTo } = this.deepCompsetAnalysisService;
        return compareTo === COMPARE_TO.COMPSET_AVG ? this.$tc('filters.deepcomp.avg') : this.$tc('filters.deepcomp.last');
    }

    get skeleton() {
        return this.deepCompsetAnalysisService.isLoading;
    }

    get date() {
        const { updatedAt } = this.deepCompsetAnalysisService;
        if (!updatedAt) return null;
        return moment(updatedAt).format('MMM, D YYYY');
    }
}

