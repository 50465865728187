
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import EventTypesPoints from '@/modules/common/components/event-types-points.vue';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import isDayType from '@/modules/common/component-validators/is-date.validator';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import type { IResponsiveStyle } from '@/modules/cars/modules/fleet/fleet-settings.types';

interface ITableData {
    brokerName: string,
    total: number,
    myBroker: boolean,
}

@Component({
    filters: { MIPriceFilter },
    components: {
        CalendarEventsContainer,
        CalendarItemWrapper,
        EventTypesPoints,
    },
})
export default class FleetCalendarItem extends Vue {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(FleetServiceS) private fleetService!: FleetService;
    @inject(UserServiceS) userService!: UserService;

    @Prop({
        required: true,
        type: Number,
        validator: isDayType,
    })
    private day!: Day;

    @Prop({
        required: true,
        type: String,
    })
    private source!: string;

    @Prop({
        required: true,
    })
    private styles!: IResponsiveStyle;

    myCardColor() {
        const { position, brokerCount } = this.positionData;
        const percentPosition: number = (position / brokerCount) * 100;
        return {
            high: position === 1,
            'med-high': percentPosition <= 50 && position !== 1,
            'med-low': percentPosition > 50 && position !== brokerCount,
            low: position === brokerCount && brokerCount !== 1,
        };
    }

    get month(): Month {
        return this.documentFiltersService.storeState.settings.month;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get number(): number | null {
        return this.fleetService.getNumberCar(this.day, this.source);
    }

    get eventsData() {
        if (!this.day) {
            return {};
        }

        const data: any = {};
        data[this.day] = {
            local: false,
            holiday: false,
        };
        data[this.day].local = this.localEvents;
        data[this.day].holiday = this.hasHolidayEvents;

        return data;
    }

    get positionData() {
        const data = this.fleetService.brokersDataByDay(this.day, this.source);
        let tableData: ITableData[] = [];
        if (!data) {
            return {
                brokerCount: 0,
                position: 0,
            };
        }

        tableData = data;

        tableData.sort((a, b) => b.total - a.total);

        return {
            brokerCount: tableData.length,
            position: tableData.findIndex(item => item.brokerName === this.userService.currentCompany) + 1,
        };
    }

    get localEvents(): boolean {
        return false;
        // NOTE: These methods are removed during refactoring
        // const myEvents = this.eventsManagerService
        //     .getMyEventsByDay({ day: this.day as Day, isPOSBased: this.userService.isCarUser as boolean });
        // const marketEvents = this.eventsManagerService
        //     .getMarketEventsByDay({ day: this.day as Day, isPOSBased: this.userService.isCarUser as boolean });
        // const chainEvents = this.eventsManagerService
        //     .getChainEventsByDay({ day: this.day as Day, isPOSBased: this.userService.isCarUser as boolean });
        // return Boolean(myEvents.length || marketEvents.length || chainEvents.length);
    }

    get hasHolidayEvents() : boolean {
        return false;
        // NOTE: This method is removed during refactoring
        // return Boolean(this.eventsManagerService
        //     .getCountryEventsByDay({
        //         day: this.day, month: this.month, year: this.year, isPOSBased: this.userService.isCarUser as boolean,
        //     }).length);
    }
}
