
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';

import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import UserSettings, { UserSettingsS } from '@/modules/user/user-settings.service';

@Component({
    components: {
        CustomSelect,
        CustomMultiSelect,
    },
})
export default class EmailReportsSubpage extends Vue {
    static title = 'settings.reports.htmlTitle';

    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(UserSettingsS) private userSettings!: UserSettings;

    isChanged = false;
    isError = false;
    isSaving = false;
    statusText = '';

    yesNoItems = [
        { name: this.$tc('yes'), value: true },
        { name: this.$tc('no'), value: false },
    ];

    updateChangeStatus() {
        this.isChanged = true;
    }

    rangeItems = [5, 7, 14].map(value => ({
        name: this.$t('numDays', [value]).toString(),
        value,
    }));

    get compsetSettings() {
        return this.userSettings.compsetSettings;
    }

    get subscriptions() {
        return this.userSettings.subscriptions;
    }

    get sourceList() {
        return this.userSettings.storeState.compsetAllowedReportProviders
            .filter(value => value !== 'all')
            .map(value => {
                const name = this.providersService
                    .getProviderLabel(value);
                return { name, value };
            });
    }

    async saveSettings() {
        this.statusText = '';
        this.isError = false;
        this.isSaving = true;

        try {
            await this.userSettings.saveReportsSettings(+this.$route.params.hotelId || undefined);

            this.statusText = this.$tc('saved');
            this.isChanged = false;
        } catch {
            this.statusText = this.$tc('somethingWrong');
            this.isError = true;
        } finally {
            this.isSaving = false;
        }
    }
}
