
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type RankingService from '@/modules/ranking/ranking.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';

import CustomPopover from '@/modules/common/components/ui-kit/custom-popover.vue';
import PAGES from '@/modules/common/constants/pages.constant';
import RankingChainRange from './ranking-chain-range.vue';
import ClusterRankingModel from '../../models/cluster-ranking.model';

const EMPTY_HOTEL = new ClusterRankingModel();
EMPTY_HOTEL.hotelName = '______________';

@Component({
    components: {
        RankingChainRange,
        CustomPopover,
    },
})
export default class RankingHotelCompsetGroup extends Vue {
    @inject(UserServiceS) private userService!: UserService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.RankingService) private rankingService!: RankingService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(ClusterRankingServiceS) private clusterRankingService!: ClusterRankingService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    @Prop({ type: Number })
    hotelId!: number;

    isDocLoading = false;

    get providers() {
        return this.clusterRankingService.providers;
    }

    get hotel() {
        return this.clusterRankingService.getHotelBy({ hotelId: this.hotelId }) || EMPTY_HOTEL;
    }

    get mainCompset() {
        if (!this.hotel.compsets || !this.hotel.compsetMain) {
            return null;
        }

        return this.hotel.compsets.find(
            compset => compset.id === this.hotel.compsetMain?.id,
        ) || this.hotel.compsets[0];
    }

    get isLoading() {
        return this.isDocLoading || !this.hotel.documentLoaded;
    }

    // Lazy load of document, send request only if component is in viewport.
    loadViewportedHotel(isVisible: boolean) {
        if (this.isDocLoading || !isVisible || this.hotel.documentLoaded) {
            return;
        }

        this.isDocLoading = true;
        this.clusterService
            .loadDaysData(PAGES.RANKING, this.hotelId)
            .finally(() => { this.isDocLoading = false; });
    }

    handleHover(e: MouseEvent, payload: Record<string, any> | null) {
        if (!payload) {
            this.$emit('hover', null);
            return;
        }
        const position = (e.target as HTMLElement).getBoundingClientRect();
        this.$emit('hover', { position, payload });
    }

    async goToHotel() {
        const compset = this.hotel.compsetMain?.id ? this.hotel.compsets.find(c => c.id === this.hotel.compsetMain!.id) : this.hotel.compsets[0];
        this.userViewService.goToHotelPage(this.hotelId, compset);
    }
}
