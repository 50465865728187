import { Expose, plainToClass, Transform } from 'class-transformer';
import { RoomsGroup } from '../types';
import { RoomGroupModel } from './rooms-group.model';

type FornovaId = number;
type BEResponse = {
    providers: {
        [provider: string]: {
            [fornovaId: number]: {
                [groupId: number]: {
                    // eslint-disable-next-line camelcase
                    group_name: string;
                    mergedRooms: string[];
                }
            }
        }
    }
};

export default class ArchivedRoomsModel {
    @Expose()
    @Transform((_, plain: BEResponse) => {
        const model = {} as {
            [provider: string]: {
                [fornovaId: number]: RoomsGroup[];
            }
        };

        Object
            .entries(plain.providers)
            .forEach(([provider, hotels]) => {
                Object
                    .entries(hotels)
                    .forEach(([hotelId, roomGroups]) => {
                        model[provider] = model[provider] || {};
                        model[provider][+hotelId] = model[provider][+hotelId] || [];

                        Object.keys(roomGroups).forEach(groupId => {
                            const instance = plainToClass(RoomGroupModel, { ...roomGroups[+groupId], groupId }, { excludeExtraneousValues: true });
                            model[provider][+hotelId].push(instance);
                        });
                    });
            });

        return model;
    })
    providers!: {
        [provider: string]: Record<FornovaId, RoomsGroup[]>;
    };
}
