
import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Year from '@/modules/common/types/year.type';
import type Month from '@/modules/common/types/month.type';
import MonthChanger from '@/modules/common/components/month-changer.vue';
import EventsDayList from '@/modules/events/components/events-day-list.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import EventCollection from '../models/event-collection.model';

@Component({
    components: {
        MonthChanger, EventsDayList,
    },
})
export default class EventsMonthListContainer extends Vue {
    @inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    get month(): Month {
        return this.documentFiltersService.storeState.settings.month;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get monthEvents() {
        const { year, month } = this.documentFiltersService;

        return this.documentFiltersService.days.map(day => {
            const date = new Date(year, month, day);
            return this.eventsManagerService.getEventCollection(date) || new EventCollection();
        });
    }

    isLastItem(index: string) :boolean {
        return Object.keys(this.monthEvents).length - 1 === Number(index);
    }
}
