import { Expose, plainToClass, Transform } from 'class-transformer';
import { RoomsGroup } from '../types';
import { RoomGroupModel } from './rooms-group.model';

type BEResponse = {
    providers: {
        [provider: string]: {
            [fornovaId: number]: {
                [groupId: number]: {
                    type: number;
                    new: boolean;
                    // eslint-disable-next-line camelcase
                    group_name: string;
                    mergedRooms: string[];
                    isAutoArchived: boolean;
                }
            }
        }
    }
};

export default class HotelsRoomTypeModel {
    @Expose()
    @Transform((_, plain: BEResponse) => {
        const model = {} as {
            [provider: string]: {
                [fornovaId: number]: {
                    [roomTypeId: number]: RoomsGroup[];
                }
            }
        };

        Object
            .entries(plain.providers)
            .forEach(([provider, hotels]) => {
                Object
                    .entries(hotels)
                    .forEach(([hotelId, roomGroups]) => {
                        model[provider] ??= {};
                        model[provider][+hotelId] ??= {};

                        Object
                            .entries(roomGroups)
                            .forEach(([groupId, group]) => {
                                model[provider][+hotelId][group.type] ??= [];

                                const instance = plainToClass(RoomGroupModel, { ...group, groupId }, { excludeExtraneousValues: true });
                                model[provider][+hotelId][group.type].push(instance);
                            });
                    });
            });

        return model;
    })
    providers!: {
        [provider: string]: {
            [fornovaId: number]: {
                [roomTypeId: number]: RoomsGroup[];
            }
        }
    };

    @Expose()
    @Transform((_, plain: BEResponse) => {
        const hotels = Object
            .values(plain.providers)
            .map(hotelList => Object.keys(hotelList))
            .flat();

        return Array.from(new Set(hotels));
    })
    hotels!: number[];
}
