
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Component } from 'vue-property-decorator';

import RVSWeightSettingsService from '@/modules/settings/rvs/rvs-weight-settings.service';
import FEATURES from '@/modules/common/constants/features.constant';

import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import TabsController from '@/modules/common/components/tabs-controller.vue';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';

import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

import { SettingsTab } from '../../interfaces/settings-tab.abstract';

type ColumnWidth = number | 'auto' | string;

@Component({
    components: {
        TabsController,
        CustomSwitch,
        CustomSelect,
        CustomMultiSelect,
    },
})
export default class RVSWeightTab extends SettingsTab {
    @inject(UserServiceS)
    private static userService: UserService;

    static title = 'settings.rvs.title';

    static showSolver(): boolean {
        return !!(this.userService.enabledFeatures || {})[FEATURES.RVS];
    }

    @inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @inject(KEY.RVSWeightSettingsService)
    private rvsWeightSettingsService!: RVSWeightSettingsService;

    columns: Record<string, ColumnWidth> = {
        type: '30%',
        'settings.rvs.weight': 150,
        lock: 'auto',
    };

    tableData_: Record<string, number> = {};

    lockedFields: string[] = [];
    hotelsToSave: number[] = [];
    statusMessage = '';

    isSaving = false;
    isError = false;

    get hotels() {
        const items = this.hotelsService.myHotels.map(h => ({
            name: h.name,
            value: h.id,
            disabled: h.id === this.currentHotelId,
        }));

        return items;
    }

    get currentHotelId() {
        return +this.$route.params.hotelId;
    }

    get tabs() {
        return [
            'Default',
        ];
    }

    get isClusterUser() {
        return true;
    }

    get isAbleToSave() {
        return Math.round(this.totalPercent) === 100;
    }

    get isAbleToSaveMultiple() {
        return this.isAbleToSave && !!this.hotelsToSave.length;
    }

    get totalPercent() {
        return +Object.values(this.tableData).reduce((acc, value) => acc + +value, 0).toFixed(2);
    }

    get isLoading() {
        return this.rvsWeightSettingsService.isLoading;
    }

    get tableData() {
        const { data } = this.rvsWeightSettingsService;
        if (!data || this.isLoading) return Object.fromEntries(Array.from({ length: 8 }).map((_, i) => [`weight${i}`, 0.0]));

        this.tableData_ = { ...data.weights };
        return this.tableData_;
    }

    async recalculateWeights(fieldChanged: string, newValue: number) {
        if (!newValue) return;

        this.tableData[fieldChanged] = newValue;
        await this.$nextTick();

        const unlockedFields = Object.keys(this.tableData)
            .filter(key => key !== fieldChanged && !this.lockedFields.includes(key));

        if (!+newValue) return;

        const deltaPercent = 100 - this.totalPercent;
        const part = deltaPercent / unlockedFields.length;

        unlockedFields.forEach(key => {
            this.tableData[key] = Math.max(0, +(+this.tableData[key] + part).toFixed(2));
        });
    }

    toggleLock(key: string) {
        if (this.lockedFields.includes(key)) {
            this.lockedFields = this.lockedFields.filter(k => k !== key);
            return;
        }

        this.lockedFields.push(key);
    }

    save() {
        this.isSaving = true;
        this.isError = false;
        this.rvsWeightSettingsService
            .updateWeights(this.currentHotelId, this.tableData)
            .then(() => {
                this.statusMessage = 'saved';
            })
            .catch(() => {
                this.isError = true;
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    batchSave() {
        this.isSaving = true;
        this.isError = false;
        this.rvsWeightSettingsService
            .updateWeights([this.currentHotelId, ...this.hotelsToSave], this.tableData)
            .then(() => {
                this.statusMessage = 'saved';
            })
            .catch(() => {
                this.isError = true;
                this.statusMessage = 'somethingWrong';
            })
            .finally(() => {
                this.isSaving = false;
            });
    }
}
