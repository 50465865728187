
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import EventTypePoint from '@/modules/common/components/ui-kit/event-type-point.vue';
import type Day from '@/modules/common/types/day.type';
import isDayType from '@/modules/common/component-validators/is-date.validator';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';

@Component({
    components: {
        EventTypePoint,
    },
})
export default class EventTypesPoints extends Vue {
    @inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @inject(KEY.DocumentFiltersService)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({
        type: Number,
        required: true,
        validator: isDayType,
    })
    public day!: Day;

    get hasHolidayEvents() : boolean {
        return this.eventsManagerService.hasDateHolidayEvents(this.date);
    }

    get hasLocalEvents() : boolean {
        const hotelId = +this.$route.params.hotelId;
        return this.eventsManagerService.hasDateOtherEvents(this.date, hotelId);
    }

    get date(): Date {
        const { year, month } = this.documentFiltersService;
        const { day } = this;

        return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
    }
}
