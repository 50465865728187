
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type RatesFiltersService from '@/modules/rates/rates-filters.service';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import LosCheckboxSelect, { Item } from '@/modules/common/components/ui-kit/los-checkbox-select.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

const MAX_SELECTED = 3;
const MIN_SELECTED = 1;

@Component({
    components: { LosCheckboxSelect },
})
export default class LosDocumentFilter extends Vue {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.RatesFiltersService) private ratesFiltersService!: RatesFiltersService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(UserServiceS) private userService!: UserService;

    @Prop({ type: Boolean, default: false })
    disable!: boolean;

    @Prop({ type: Number })
    private value!: number;

    @Prop({ type: Boolean, default: true })
    customization!: boolean;

    private selectMode: boolean = true;
    private isShowError: boolean = false;
    private selectedCheckboxes: number[] = [];
    private errors: any = {};

    mounted() {
        this.selectedCheckboxItems = this.documentFiltersService.losItems.map(item => Number(item.value));
        this.processThePageSpecificLogic();
    }

    get isCustomizationAllowed() {
        const pageName = this.$route.name || '';

        return !pageName.includes('promotions');
    }

    get isAdmin() {
        return this.userService.isAdmin;
    }

    get supportedLosNumber() {
        return MAX_SELECTED;
    }

    get minSelected() {
        return MIN_SELECTED;
    }

    set los(item: Item) {
        const { model } = this.$vnode.data as { model: any };

        if (model) {
            this.$emit('input', item.value);
            return;
        }

        const los = item ? Number(item.value) : null;
        this.documentFiltersService.saveLos(los);
    }

    get los() {
        const { model } = this.$vnode.data as { model: any };

        let los: number | null;

        if (model) {
            los = this.value;
        } else {
            ({ los } = this.documentFiltersService.storeState.settings);
        }

        return this.losItems
            .find(item => item.value === los) || { name: this.$t('filters.los.num', [1]).toString(), value: 1 };
    }

    get selectedCheckboxItems() {
        return this.selectedCheckboxes;
    }

    set selectedCheckboxItems(val: number[]) {
        this.selectedCheckboxes = val;
    }

    get losItems(): Item<number>[] {
        const routeName = this.$route.name || '';
        const isRatesPage = routeName.includes('rates');
        const isPromotionsPage = routeName.includes('promotions');
        const { losItems } = this.documentFiltersService;

        let items = [] as {
            value: number;
            name: string;
            isAutoscan: boolean;
            isSelected: boolean;
        }[];

        if (isPromotionsPage) {
            items = (DEFAULT_LOS).map((los: number) => ({
                value: los,
                name: this.$t('filters.los.num', [los]).toString(),
                isAutoscan: los === 1,
                isSelected: this.selectedCheckboxItems.findIndex(item => los === item) !== -1,
            }));
        } else if (isRatesPage && this.ratesFiltersService.isScanDisabledProvider) {
            items = losItems
                .map(item => ({
                    ...item,
                    name: this.$t('filters.los.num', [item.value]).toString(),
                    isAutoscan: true,
                    isSelected: this.selectedCheckboxItems.findIndex(val => item.value === val) !== -1,
                }));
        } else {
            items = (DEFAULT_LOS)
                .map((los: number) => ({
                    value: los,
                    name: this.$t('filters.los.num', [los]).toString(),
                    isAutoscan: losItems.findIndex(item => los === Number(item.value)) !== -1,
                    isSelected: this.selectedCheckboxItems.findIndex(item => los === item) !== -1,
                }))
                .sort((a, b) => {
                    if (a.isAutoscan === b.isAutoscan) {
                        return 0;
                    }

                    return a.isAutoscan ? -1 : 1;
                });
        }

        const { model } = this.$vnode.data as { model: any };
        const actualValue = model ? this.value : this.documentFiltersService.los;
        const isCurrentLosAvailable = items.some(item => item.value === actualValue);

        if (!isCurrentLosAvailable) {
            [this.los] = items;
        }

        return items;
    }

    get isSelectMode() {
        return this.selectMode;
    }

    get isMaxSelected() {
        return this.errors.maxSelected;
    }

    get isMinSelected() {
        return this.errors.minSelected;
    }

    get showError() {
        return this.isShowError;
    }

    set showError(val: boolean) {
        this.isShowError = val;
    }

    handleChangeCheckbox(val: number) {
        const newValue = [val];
        const difference = this.selectedCheckboxItems
            .filter(x => !newValue.includes(Number(x)))
            .concat(newValue.filter(x => !this.selectedCheckboxItems.includes(x)));
        this.selectedCheckboxItems = [...new Set(difference)];
    }

    handleClick() {
        this.selectMode = !this.selectMode;
    }

    handleCancel() {
        this.handleClick();
        const { losItems } = this.documentFiltersService;
        this.selectedCheckboxItems = losItems.map(item => Number(item.value));
    }

    handleError(errors: any) {
        this.showError = true;
        this.errors = { ...errors };
    }

    private processThePageSpecificLogic() {
        const pageName = this.$route.name || '';

        // NOTE: Disable autoscans customization for the promotions page
        //       since only LOS1 suports it (CI-4295)
        if (pageName.includes('promotions')) {
            this.selectMode = true;
        }
    }

    async handleUpdate() {
        if (this.selectedCheckboxItems.length === 0) {
            this.showError = true;
            this.errors = { minSelected: true };
            return;
        }
        await this.compsetsService.updateCompsetLos(this.selectedCheckboxItems);
        this.handleClick();
        this.showError = false;
    }
}
