

import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import InsightsDateRange from '@/modules/document-filters/components/insights-date-range.vue';
import type InsightsService from '../insights.service';
import InsightsHintTooltip from './insights-hint-tooltip.vue';

@Component({
    components: {
        InsightsDateRange,
        InsightsHintTooltip,
    },
})
export default class InsightsHeader extends Vue {
    @inject(UserServiceS) private userService!: UserService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(KEY.InsightsService) private insightsService!: InsightsService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    get isChainOrCluser() {
        return this.userService.isChainOrClusterUser;
    }

    get hotelName(): string | null {
        const { currentHotelId } = this.userService;
        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }

    toCluster() {
        this.userViewService.goToClusterPage();
    }
}
