
import { Component, Vue, Prop } from 'vue-property-decorator';
import FrequencyFilter from './frequency-filter.vue';
import RepeatEveryFilter from './repeat-every-filter.vue';
import DayFilter from './day-filter.vue';
import TimeFilter from './time-filter.vue';
import MonthFilter from './month-filter.vue';
import { SCHEDULER_CONFIG } from '../../../constants';
import type { ISchedulerConfig } from '../../../interfaces';

@Component({
    components: {
        FrequencyFilter,
        RepeatEveryFilter,
        DayFilter,
        TimeFilter,
        MonthFilter,
    },
})
export default class FrequencySection extends Vue {
    @Prop({ type: Object, required: true })
    value!: ISchedulerConfig;

    get isShowDayFilter() {
        return SCHEDULER_CONFIG.DAILY !== this.value.type;
    }

    get isShowMonthFilter() {
        return SCHEDULER_CONFIG.YEARLY === this.value.type;
    }

    get isShowRepeatEveryFilter() {
        return SCHEDULER_CONFIG.WEEKLY === this.value.type;
    }
}
