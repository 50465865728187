
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import RatesColorPicker from '@/modules/rates/components/calendar/rates-color-picker.vue';
import RatesCalendar from '@/modules/rates/components/calendar/rates-calendar.vue';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import { OtelRateHotelPageLoading } from '@/modules/open-telemetry/decorators';
import RatesOutOfRangeMessage from '../components/rates-out-of-range-message.vue';
import type RatesFiltersService from '../rates-filters.service';
import type RatesService from '../rates.service';

@OtelRateHotelPageLoading
@Component({
    components: {
        DateDocumentFilter,
        RatesColorPicker,
        RatesCalendar,
        RatesActions,
        PageWrapper,
        RatesHeader,
        TogglePrices,
        RatesOutOfRangeMessage,
    },
})
export default class RatesCalendarPage extends Vue {
    @inject(KEY.RatesFiltersService) private ratesFiltersService!: RatesFiltersService;
    @inject(KEY.RatesService) private ratesService!: RatesService;

    get disableBtn() {
        return this.ratesFiltersService.isProviderAll || this.ratesFiltersService.storeState.isPriceShownSwitchDisabled;
    }

    get isOutOfRange() {
        return this.ratesService.isOutOfRange() && !this.ratesService.isLoading;
    }
}
