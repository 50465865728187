
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Component, Mixins } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesClusterHeader from '@/modules/cluster/components/rates/rates-cluster-header.vue';
import RatesClusterActions from '@/modules/cluster/components/rates/rates-cluster-actions.vue';
import RatesClusterList from '@/modules/cluster/components/rates/list/rates-cluster-list.vue';
import ColorPickerGroup from '@/modules/common/components/ui-kit/color-picker-group.vue';
import { COLOR_NAME } from '@/modules/common/constants';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import { OtelRateClusterPageLoading } from '@/modules/open-telemetry/decorators';
import ClusterPageMixin from '../mixins/cluster-page.mixin';
import type ClusterRatesService from '../cluster-rates.service';

@OtelRateClusterPageLoading
@Component({
    components: {
        PageWrapper,
        RatesClusterHeader,
        RatesClusterActions,
        RatesClusterList,
        ColorPickerGroup,
        TogglePrices,
    },
})
export default class RatesClusterListPage extends Mixins(ClusterPageMixin) {
    @inject(KEY.ClusterRatesService) private clusterRatesService!: ClusterRatesService;

    get clusterColorThresholds() {
        return this.clusterRatesService.colorThresholds;
    }

    set clusterColorThresholds(value: [number, number]) {
        this.clusterRatesService.colorThresholds = value;
    }

    get colorPickerProps() {
        return {
            colors: [COLOR_NAME.GREEN, COLOR_NAME.RED],
            min: -30,
            max: 30,
        };
    }
}
