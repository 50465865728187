
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import DotsSpinner from '@/modules/common/components/dots-spinner.vue';
import type InsightFiltersService from '@/modules/document-filters/insight-filters.service';
import InsightCard from './insight-card.vue';
import type InsightsService from '../insights.service';
import { InsightDayRoute } from '../constants';

@Component({
    components: {
        InsightCard,
        DotsSpinner,
    },
})
export default class InsightGrid extends Vue {
    @inject(KEY.InsightsService) insightsService!: InsightsService;
    @inject(KEY.InsightFiltersService) private insightFiltersService!: InsightFiltersService;

    updated() {
        // Load more insights in case no scroll on screen, but there are still some not loaded data (for high screen res).
        const grid = this.$refs.grid as HTMLElement;
        const elH = grid.clientHeight;
        const elS = grid.scrollHeight;

        if (elS <= elH && this.insightsService.count > this.insightsService.skip && !this.insightsService.isInsightLoading) {
            this.loadMoreInsights();
        }
    }

    handleGridScroll(e: UIEvent) {
        // Load more insights if scroll comes to bottom and there are still some not loaded data.
        if (this.insightsService.count <= this.insightsService.skip) {
            return;
        }

        const el = e.target as HTMLElement;
        const loadingOffset = el.scrollHeight * 0.2;

        if (el.scrollTop + el.offsetHeight > el.scrollHeight - loadingOffset && !this.insightsService.isInsightLoading) {
            this.loadMoreInsights();
        }
    }

    get insights() {
        return this.insightsService.groups.map(ins => {
            const typeWithPopup = InsightDayRoute[ins.type.toUpperCase() as keyof typeof InsightDayRoute];

            return {
                id: ins.groupId,
                insightType: {
                    value: ins.type,
                    subType: ins.subType,
                },
                read: ins.read,
                deleted: !!ins.deleted,
                content: {
                    link: (typeWithPopup && !ins.deleted)
                        ? this.$router.resolve({ name: 'hotel.insights.day-insight', params: { groupId: ins.groupId } }).route.path
                        : null,
                },
                date: new Date(ins.date),
            };
        });
    }

    loadMoreInsights() {
        this.insightsService.loadMoreInsights();
    }
}
