
import { inject } from '@/inversify';
import { Component, Mixins } from 'vue-property-decorator';

import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import DiLiteFilterItemsMixin from '../../mixins/filter-items.mixin';

@Component({
    components: {
        CustomSelect,
        CustomMultiSelect,
        TogglePrices,
    },
})
export default class DiLiteAllChannelsFilters extends Mixins(DiLiteFilterItemsMixin) {
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(CompsetsServiceS) private compsetsServce!: CompsetsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    get isLoading() {
        return this.allChannelsService.isLoading;
    }

    get compsets() {
        return (this.compsetsServce.compsets || [])
            .filter(c => c.ownerHotelId === Number(this.$route.params.hotelId))
            .map(compset => ({
                value: compset.id,
                name: `${compset.name} (${this.$t(`compset.${compset.type}`)})`,
            }));
    }

    get compsetId() {
        return this.documentFiltersService.compsetId!;
    }

    set compsetId(newValue: string) {
        this.documentFiltersService.updateCompset(newValue);
    }

    get pos() {
        const { pos } = this.documentFiltersService.settings;

        const isPosExists = this.posItems
            .some(item => item.value === pos);

        if (!isPosExists) {
            this.documentFiltersService.settings.pos = this.posItems[0].value;
        }

        return pos;
    }

    set pos(newValue) {
        this.documentFiltersService.settings.pos = newValue;
    }

    get device() {
        return this.allChannelsService.settings.device;
    }

    set device(newValue) {
        this.allChannelsService.settings.device = newValue;
    }

    get los() {
        return this.documentFiltersService.settings.los;
    }

    set los(newValue) {
        this.documentFiltersService.settings.los = newValue;
    }

    get ng() {
        if (this.allChannelsService.settings.numberOfGuests === null) {
            this.allChannelsService.settings.numberOfGuests = this.numberOfGuestsService.defaultNumberOfGuests;
        }

        return this.allChannelsService.settings.numberOfGuests;
    }

    set ng(newValue) {
        this.allChannelsService.settings.numberOfGuests = newValue;
    }

    get priceType() {
        return this.allChannelsService.settings.priceType;
    }

    set priceType(newValue) {
        this.allChannelsService.settings.priceType = newValue;
    }

    get roomType() {
        return this.allChannelsService.settings.roomTypeId;
    }

    set roomType(newValue) {
        this.allChannelsService.settings.roomTypeId = newValue;
    }

    get mealType() {
        return this.allChannelsService.settings.mealTypeId;
    }

    set mealType(newValue) {
        this.allChannelsService.settings.mealTypeId = newValue;
    }
}
