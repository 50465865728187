
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import Calendar from '@/modules/common/components/ui-kit/calendar.vue';
import RatesAnalysisCalendarItem from '@/modules/rates/components/analysis/calendar/rates-analysis-calendar-item.vue';
import CalendarItemNoData from '@/modules/common/components/calendar-item-no-data.vue';
import CalendarItemNoAvailability from '@/modules/common/components/calendar-item-no-availability.vue';
import CalendarItemEmpty from '@/modules/common/components/calendar-item-empty.vue';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import type RatesService from '@/modules/rates/rates.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';

@Component({
    filters: {
        PercentFilter,
        PriceFilter,
    },
    components: {
        Calendar,
        RatesAnalysisCalendarItem,
        CalendarItemNoData,
        CalendarItemEmpty,
        CalendarItemNoAvailability,
    },
})
export default class RatesAnalysisCalendar extends Vue {
    @inject(KEY.DocumentFiltersService)
    private documentFiltersService!: DocumentFiltersService;

    @inject(KEY.RatesService)
    private ratesService!: RatesService;

    @inject(RatesAnalysisServiceS)
    private ratesAnalysisService!: RatesAnalysisService;

    @inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    private pickedDay: number | null = null;

    get year() {
        return this.documentFiltersService.year;
    }

    get month() {
        return this.documentFiltersService.month;
    }

    get chosenPickedDay() {
        return this.pickedDay;
    }

    get isHaveData() {
        return this.ratesAnalysisService.data.length;
    }

    get isLoading() {
        return (!this.ratesAnalysisService.data[0] && this.ratesAnalysisService.isLoading)
            || this.ratesService.isLoading;
    }

    get noCompareValues() {
        return !this.ratesAnalysisFiltersService.comparisonValues.length;
    }

    change(value: number | null) {
        this.pickedDay = value;
    }
}
