import { inject, injectable } from '@/inversify';

import Stateable from '@/modules/common/interfaces/stateable.interface';
import Percent from '@/modules/common/types/percent.type';
import type Day from '@/modules/common/types/day.type';

import RatesStore from '@/modules/rates/store/rates.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import { KEY } from '@/inversify.keys';
import RatesDocumentAllModel from './models/rates-document-all.model';
import RatesDocumentItemModel from './models/rates-document-item.model';
import { PRICE_SHOWN } from './constants';
import type DocumentFiltersService from '../document-filters/document-filters.service';

export const RatesAllServiceS = Symbol.for('RatesAllServiceS');
@injectable()
export default class RatesAllService implements Stateable {
    @inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @inject(KEY.DocumentFiltersService)
    private documentFiltersService!: DocumentFiltersService;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');

    constructor(storeState: RatesStore | null = null) {
        if (storeState) {
            this.storeState = storeState;
        }
    }

    getCardAssessment(day: Day, percentFromFilterAll: number | null) : ASSESSMENTS_TYPES | null {
        const compset = this.compsetsService.currentCompset;
        if (!compset || !percentFromFilterAll) {
            return null;
        }

        if (this.getValidPrices(day).length < 2) {
            return null;
        }

        return this.ratesCommonService.getCardAssessment(percentFromFilterAll, compset);
    }

    myPriceAvg(day: Day) {
        const doc = this.storeState.document as RatesDocumentAllModel;
        const checkinDates = doc && doc.checkinDates && doc.checkinDates[day];
        if (!checkinDates) {
            return null;
        }

        const providers = Object
            .keys(checkinDates)
            .filter(provider => provider !== 'average');

        let numberOfPrices = 0;

        const price = providers.reduce((totalPrice, provider) => {
            const providerPrice = this.getProviderPrice(day, provider);

            if (!providerPrice) {
                return totalPrice;
            }

            numberOfPrices += 1;
            return totalPrice + providerPrice;
        }, 0);

        if (!price) {
            return null;
        }

        return price / numberOfPrices;
    }

    getCheckinDay(day: Day) {
        const doc = this.storeState.document as RatesDocumentAllModel;
        const checkinDate = doc && doc.checkinDates && doc.checkinDates[day];
        if (!checkinDate) {
            return {};
        }
        return checkinDate;
    }

    getCheckinDatesFromData(day: Day, data: RatesDocumentAllModel) {
        const doc = data as RatesDocumentAllModel;
        const checkinDate = doc && doc.checkinDates && doc.checkinDates[day];
        if (!checkinDate) {
            return null;
        }
        return checkinDate;
    }

    getProviderPrice(day: Day, provider: string, priceShown?: PRICE_SHOWN) {
        const data = this.getCheckinDay(day);
        const actualPriceShown = priceShown || this.documentFiltersService.settings.priceShown;

        if (!data[provider] || !data[provider].rooms) {
            return null;
        }

        const { rooms } = data[provider];
        const room = rooms[Number(Object.keys(rooms)[0])] as RatesDocumentItemModel[];

        return this.ratesCommonService.getRoomPrice(room[0], actualPriceShown);
    }

    getAverageRoomsPrice(day: Day, priceShown?: PRICE_SHOWN) {
        const actualPriceShown = priceShown || this.documentFiltersService.priceShown;
        const prices = this.getValidPrices(day, actualPriceShown);

        if (!prices.length) {
            return 0;
        }
        const avg = Math.round(prices.reduce((a, p) => a + p, 0) / prices.length * 100) / 100;
        return avg;
    }

    getHighestPrice(day: Day, priceShown?: PRICE_SHOWN) {
        const prices = this.getValidPrices(day, priceShown);
        const max = Math.max(...prices);
        return max === -Infinity ? 0 : max;
    }

    getLowestPrice(day: Day, priceShown?: PRICE_SHOWN) {
        const prices = this.getValidPrices(day, priceShown);
        const min = Math.min(...prices);
        return min === Infinity ? 0 : min;
    }

    getValidPrices(day: Day, priceShown?: PRICE_SHOWN) {
        const actualPriceShown = priceShown || this.documentFiltersService.settings.priceShown;
        const providers = Object.keys(this.getCheckinDay(day)).filter(p => p !== 'average');
        const prices: number[] = [];

        providers.forEach(provider => {
            const price = this.getProviderPrice(day, provider, actualPriceShown);
            if (price && price > 0) {
                prices.push(price);
            }
        });
        return prices;
    }

    priceDiff(myPrice: number, comparePrice: number): Percent | null {
        return (myPrice - comparePrice) / comparePrice;
    }

    isNoData(day: Day) {
        if (!this.storeState.document || !this.storeState.document.checkinDates) {
            return true;
        }

        const allRooms = this.getCheckinDay(day);
        const isNoRooms = !Object.keys(allRooms).length;

        if (isNoRooms) {
            return true;
        }

        return !this.storeState.document.checkinDates[day];
    }

    isOutOfRange() {
        return !this.storeState.document;
    }

    isSoldOut(day: Day, provider: string) {
        if (!this.storeState.document || !this.storeState.document.checkinDates) {
            return false;
        }

        const price = this.getProviderPrice(day, provider);

        return !price;
    }

    isNoAverage(day: Day) {
        if (!this.storeState.document || !this.storeState.document.checkinDates) {
            return false;
        }

        const allRooms = this.getCheckinDay(day);
        return !Object.entries(allRooms).find(([_, value]) => !!value.statistics && !!value.statistics.lowest);
    }
}
