
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
// @ts-ignore
import ColorPicker, { Item } from '@/modules/common/components/ui-kit/color-picker.vue';
import Percent from '@/modules/common/types/percent.type';
import PercentFilter from '@/modules/common/filters/percent.filter';
import COMPSET_TYPE from '@/modules/compsets/constants/compset-type.constant';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import type RatesFiltersService from '@/modules/rates/rates-filters.service';

@Component({
    components: { ColorPicker },
    filters: { PercentFilter },
})
export default class RatesColorPicker extends Vue {
    @inject(CompsetsServiceS) compsetsService!: CompsetsService;
    @inject(KEY.RatesFiltersService) private ratesFiltersService!: RatesFiltersService;

    get from(): Percent | null {
        return this.compsetsService.minThreshold();
    }
    set from(value) {
        if (value === null || this.to === null || value > this.to) {
            return;
        }
        this.compsetsService.updateThresholds(value, this.to);
    }

    get filterAll() {
        return this.ratesFiltersService.isProviderAll;
    }

    get isAnalysisPage() {
        return this.$route.path.includes('analysis');
    }

    get to() {
        return this.compsetsService.maxThreshold();
    }
    set to(value) {
        if (value === null || this.from === null || this.from > value) {
            return;
        }
        this.compsetsService.updateThresholds(this.from, value);
    }

    get fromColor() {
        if (this.compsetsService.currentCompset) {
            const { type } = this.compsetsService.currentCompset;

            if (type === COMPSET_TYPE.LOW) {
                return 'red';
            }
        }

        return 'green';
    }
    get toColor() {
        if (this.compsetsService.currentCompset) {
            const { type } = this.compsetsService.currentCompset;

            if (type === COMPSET_TYPE.LOW) {
                return 'green';
            }
        }

        return 'red';
    }

    get fromItems(): Item[] {
        return this.compsetsService.thresholdRange().to
            .map((value: number): Item => ({ value, name: `<${Math.round(value * 100)}%`, disabled: value > (this.to || 0) }));
    }
    get toItems(): Item[] {
        return this.compsetsService.thresholdRange().from
            .map((value: number): Item => ({ value, name: `>${Math.round(value * 100)}%`, disabled: value < (this.from || 0) }));
    }
}
