
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import EventsDayList from '@/modules/events/components/events-day-list.vue';
import { inject } from '@/inversify';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import EventsModel from '../../models/events.model';
import EventGroup from '../../interfaces/event-group.enum';

@Component({
    components: {
        ModalWrapper,
        LoaderWrapper,
        PopupEventsContainer,
        EventsDayList,
    },
})

export default class EventsDayPopup extends Vue {
    @inject(EventsManagerServiceS)
    eventsManagerService!: EventsManagerService;

    @inject(KEY.DocumentFiltersService)
    private documentFiltersService!: DocumentFiltersService;

    @Prop({ required: true })
    private day!: number;

    get isIgnoredMode() {
        return this.$route.query.showIgnored === 'true';
    }

    get holidayEvents() {
        return this.currentMonthEventsCollection?.holiday || [];
    }

    get myEvents() {
        return this.currentMonthEventsCollection?.my || [];
    }

    get marketEvents() {
        return this.currentMonthEventsCollection?.market || [];
    }

    get chainEvents() {
        return this.currentMonthEventsCollection?.chain || [];
    }

    get currentMonthEventsCollection() {
        const { day } = this;
        const { year, month } = this.documentFiltersService;
        const date = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

        if (this.isIgnoredMode) {
            return this.eventsManagerService.ignoredEvents.getCollection(date);
        }

        return this.eventsManagerService.getEventCollection(date, +this.$route.params.hotelId);
    }

    closeModal() {
        const targetName = this.$route.name!.split('.');
        targetName.pop();

        this.$router.push({
            name: targetName.join('.'),
            params: {
                ...this.$route.params,
            },
            query: this.$route.query,
        });
    }
}
