
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import type ClusterRatesService from '@/modules/cluster/cluster-rates.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import type RatesFiltersService from '@/modules/rates/rates-filters.service';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterRoomTypeFilter extends Vue {
    @inject(KEY.ClusterRatesService) clusterRatesService!: ClusterRatesService;
    @inject(KEY.RatesFiltersService) ratesFiltersService!: RatesFiltersService;
    @inject(RoomTypesServiceS) roomTypesService!: RoomTypesService;

    get value() {
        return this.clusterRatesService.storeState.settings.roomTypeId;
    }

    set value(value: number) {
        this.clusterRatesService.storeState.settings.roomTypeId = value;
        this.ratesFiltersService.settings.roomTypeId = value;
    }

    get items(): Item[] {
        return this.roomTypesService.rooms
            .map(room => ({
                value: room.id,
                name: this.$te(room.displayName) ? this.$tc(room.displayName) : room.name,
            }));
    }
}
