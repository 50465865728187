import { inject, injectable } from '@/inversify';
import { event } from 'vue-gtag';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import RankingModel from '@/modules/ranking/models/ranking-document.model';
import { plainToClass } from 'class-transformer';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import DownloadExcelModel from '../rates/models/download-excel.model';
import UserService, { UserServiceS } from '../user/user.service';
import { otelDownload } from '../open-telemetry/decorators';

export interface RankingDownloadExcelForm {
    compsetId: string;
}

export const RankingApiServiceS = Symbol.for('RankingApiServiceS');
@injectable()
export default class RankingApiService {
    @inject(ApiServiceS) private apiService!: ApiService;
    @inject(UserServiceS) private userService!: UserService;

    async getRanking(documentSettings: DocumentFiltersModel) {
        const { data } = await this.apiService.get(`market/compset/${documentSettings.compsetId}/customer-review`);

        if (!data) {
            return null;
        }

        const rankingDocument = plainToClass(RankingModel, <RankingModel>data, { excludeExtraneousValues: true });

        // const error = await this.validatorService.validateResponse(rankingDocument);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return rankingDocument;
    }

    @otelDownload('ranking')
    async getExcelDocument(form: RankingDownloadExcelForm) {
        const { compsetId } = form;

        event('download_excel', {
            chainId: this.userService.chainId,
            userId: this.userService.id,
        });

        const { data } = await this.apiService.post(
            `market/excel-guest-reviews/${compsetId}?fornova_id=${this.userService.currentHotelId}`,
            {},
        );

        if (!data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel> data, { excludeExtraneousValues: true });
    }
}
