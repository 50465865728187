
import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';

import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';

import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import type Day from '@/modules/common/types/day.type';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import { DEFAULT_GRAPH_COLORS as GRAPH_COLORS } from '@/modules/common/constants/default-graph-colors.constant';
import DayRateGraphTooltip from '@/modules/cars/components/rates-graphs/day-rate-tooltip-graph.vue';
import RatesGraphTooltip from './rates-graph-tooltip.vue';

@Component({
    components: {
        CustomGraph, LoaderWrapper, RatesGraphTooltip, DayRateGraphTooltip,
    },
})
export default class RatesGraphHotels extends Vue {
    @inject(KEY.DocumentFiltersService) documentFiltersService!: DocumentFiltersService;
    @inject(UserServiceS) protected userService!: UserService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    tooltipDay: Day | null = null;
    tooltipFocusElement: HTMLElement | null = null;

    onTooltipClick(day: Day) {
        this.$router.push({ name: 'cars-rates.graph.day-popup', params: { day: String(day) } });
    }

    get chartData(): ChartData {
        const datasets: ChartDataSets[] = [];
        const { competitors } = this.carsFiltersService;
        const { brokersCompetitors } = this.carsService;
        const { isBrokerToBrand, isBrokerToBroker } = this.carsService;
        const { currentCompany } = this.userService;
        if (!this.documentFiltersService.days || !competitors || !brokersCompetitors || !currentCompany) {
            return {
                labels: [],
                datasets: [],
            };
        }

        let companies = [...competitors];

        if (isBrokerToBroker) {
            companies = [...brokersCompetitors];
        }
        companies = companies.filter(company => this.carsFiltersService.isProviderAvailableInCurrentPOS(company));
        companies.unshift(currentCompany);

        companies.forEach(nameOfCompany => {
            const data: (number | null)[] = [];
            let company = nameOfCompany;
            this.documentFiltersService.days.forEach(day => {
                const allCars = this.carsService.allCars(day) as {
                    [company: string]: CarsDocumentItemModel
                };

                if (!allCars) {
                    data.push(null);
                    return;
                }

                if (isBrokerToBrand && nameOfCompany === currentCompany) {
                    const search = Object.keys(allCars).find(brand => brand.split(',')[0] === currentCompany);
                    if (search) {
                        company = search;
                    }
                }

                if (isBrokerToBroker) {
                    const search = Object.keys(allCars).find(brand => brand.split(',')[0] === nameOfCompany);
                    if (search) {
                        company = search;
                    }
                }

                const car = allCars[company];
                if (car) {
                    data.push(this.carsService.getCarPrice(car, company));
                } else {
                    data.push(null);
                }
            });

            datasets.push({
                label: String(company),
                data,
                borderColor: this.borderColor(company),
                borderWidth: company.split(',')[0] === currentCompany ? 3 : 2,
                lineTension: 0,
                borderJoinStyle: 'round',
            });
        });

        return {
            labels: this.documentFiltersService.days,
            datasets,
        };
    }

    get options(): ChartOptions {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    tension: 0,
                },
                point: {
                    radius: 3,
                    backgroundColor: '#ECF1F5',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                    },
                    offset: true,
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        };
    }

    borderColor(companyName: string) {
        const { currentCompany } = this.userService;
        const { isBrokerToBrand, isBrokerToBroker } = this.carsService;

        if (isBrokerToBrand || isBrokerToBroker) {
            const [broker] = companyName.split(',');
            return PROVIDER_COLORS[broker] || GRAPH_COLORS.pop() || '#123456';
        }

        return currentCompany && companyName === currentCompany ? PROVIDER_COLORS[currentCompany] : this.colorByHotel(companyName);
    }

    colorByHotel(companyName: string) {
        return this.carsService.carsGraphColor[companyName];
    }

    parseLabel(label: string | null) {
        return label ? String(parseInt(label, 10)) : null;
    }

    passCurrentDay(day: string | null) {
        this.$emit('current-day', this.parseLabel(day));
        this.tooltipDay = this.parseLabel(day) as unknown as Day;
        this.tooltipDay = this.tooltipDay && +this.tooltipDay as Day;
    }

    setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }

    // get currentCompany() {
    //     const { isBrokerToBrand, currentBrandAsBroker } = this.carsService;
    //     const { currentCompany } = this.userService;
    //     if (!currentCompany) {
    //         return '';
    //     }
    //     if (isBrokerToBrand) {
    //         return currentBrandAsBroker === BRAND_AS_BROKER_ANY ? currentCompany : currentBrandAsBroker;
    //     }
    //     return currentCompany;
    // }
}
