

import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type DiLiteMarketService from '@/modules/di-lite/di-lite-market.service';
import BMLGraph from '@/modules/di-lite/components/market/bml-graph.vue';
import RootCauseAnalysisGraph from '@/modules/di-lite/components/market/root-cause-analysis-graph.vue';
import TopWholesalers from '@/modules/di-lite/components/market/top-wholesalers.vue';
import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip.vue';

@Component({
    components: {
        BMLGraph,
        RootCauseAnalysisGraph,
        TopWholesalers,
        CustomTooltip,
    },
})
export default class MarketSection extends Vue {
    @inject(KEY.DiLiteMarketService) private diLiteMarketService!: DiLiteMarketService;
}
