import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import type RatesService from '@/modules/rates/rates.service';
import type { RateTrendsService, TrendsDocument } from './types';
import CompsetsService, { CompsetsServiceS } from '../compsets/compsets.service';
import RateTrendsStore from './store/rate-trends.store';
import { HotelRatesToTrendsModel } from './models/hotel-rates-to-trends.model';
import { PRICE_SHOWN } from '../rates/constants';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import RatesDocumentModel from '../rates/models/rates-document.model';
import ProvidersService, { ProvidersServiceS } from '../providers/providers.service';

@injectable()
export class HotelRateTrendsService implements RateTrendsService {
    /** New version of price history service. This one only for rates hotel level popup */

    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(CompsetsServiceS) private compsetService!: CompsetsService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;

    private readonly storeState: RateTrendsStore = this.storeFacade.getState('RateTrendsStore');

    constructor() {
        // Dependencies to reset whole document
        this.storeFacade.watch(
            () => [
                this.ratesService.data,
                this.date,
            ],
            () => {
                // Rates document is set immediately and trends loading starts dynamicaly
                if (this.ratesService.isLoading || this.compsetService.isLoading) {
                    return;
                }

                this.setRatesDocumentIntoTrends();
                this.storeState.trendsLoading.reset();
            },
        );
    }

    get trendsDocument() {
        this.helperService.dynamicLoading(this.storeState.trendsLoading, this.loadData.bind(this));
        return this.storeState.trendsDocument;
    }

    get trendIndex() {
        return this.storeState.trendIndex;
    }

    get filters() {
        const { los, pos, competitors } = this.documentFiltersService.settings;
        const {
            provider, roomTypeId, mealTypeId, numberOfGuests, priceType,
        } = this.ratesService.settings;
        const mealType = this.mealTypesService.getMealType(mealTypeId);
        const roomType = this.roomTypesService.getRoomType(roomTypeId);
        const providerLabel = provider ? this.providersService.getProviderLabel(provider) : '';

        if (los === null
            || !pos
            || !competitors
            || !providerLabel
            || !roomType?.displayName
            || !mealType?.displayName
            || numberOfGuests === null
            || !priceType
            || !this.compset?.ownerHotelId
        ) {
            return null;
        }

        return {
            los,
            pos,
            entities: competitors.map(String).concat([String(this.compset.ownerHotelId)]),
            provider: providerLabel,
            roomType: roomType.displayName,
            numberOfGuests,
            priceType,
            mealType: mealType.displayName,
            compset: this.compset,
        };
    }

    get date() {
        return this.storeState.date;
    }

    set date(d: Date | null) {
        this.storeState.date = d;
    }

    get localPriceShown() {
        return this.storeState.localPriceShown;
    }

    set localPriceShown(p: PRICE_SHOWN) {
        this.storeState.localPriceShown = p;
    }

    get isRatesDocumentLoading() {
        return false;
    }

    get isTrendsDocumentLoading() {
        return false;
    }

    get compset() {
        return this.compsetService.currentCompset;
    }

    get ratesDocument() {
        return this.ratesService.data as RatesDocumentModel | null;
    }

    /** Loads trends data and use ratesDocument as its 1st item */
    private async loadData() {
        // [TODO] add trends loading logic
        return true;
    }

    /** Inits trends document with ratesDocument as `0` element. All data there is rewriten */
    private setRatesDocumentIntoTrends() {
        if (!this.ratesService.data || !this.date) {
            this.storeState.trendsDocument = null;
            return;
        }

        this.storeState.trendsDocument = plainToClass(
            HotelRatesToTrendsModel,
            { ...this.ratesService.data, date: this.date },
            { excludeExtraneousValues: true },
        ) as TrendsDocument;
    }
}
