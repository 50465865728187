
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';

import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import UserSettings, { UserSettingsS } from '@/modules/user/user-settings.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';

@Component({
    components: {
        CustomSelect,
        CustomMultiSelect,
    },
})
export default class EmailReportsSubpage extends Vue {
    static title = 'settings.reports.htmlTitle';

    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(UserSettingsS) private userSettings!: UserSettings;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    isChanged = false;
    isError = false;
    statusText = '';

    yesNoItems = [
        { name: this.$tc('yes'), value: true },
        { name: this.$tc('no'), value: false },
    ];

    updateChangeStatus() {
        this.isChanged = true;
    }

    rangeItems = [5, 7, 14].map(value => ({
        name: this.$t('numDays', [value]).toString(),
        value,
    }));

    selectedCompsets_: string[] | null = null;

    get compsetsItems() {
        if (this.isGeneralMode) return [];
        const compsets = this.compsetsService.compsets || [];

        return compsets.map(compset => ({
            name: compset.name,
            value: compset.id,
        }));
    }

    get compsetSettings() {
        return this.userSettings.compsetSettings;
    }

    get subscriptions() {
        return this.userSettings.subscriptions;
    }

    get isCompsetSettingsLoading() {
        return this.userSettings.isCompsetSettingsLoading;
    }

    get isGeneralMode() {
        return this.$route.name!.includes('cluster.settings');
    }

    get isSaving() {
        return this.userSettings.isSaving;
    }

    get isAbleToSave() {
        return this.isChanged && !this.isSaving;
    }

    get sourceList() {
        return this.userSettings.storeState.compsetAllowedReportProviders
            .filter(value => value !== 'all')
            .map(value => {
                const name = this.providersService
                    .getProviderLabel(value);
                return { name, value };
            });
    }

    async saveSettings() {
        try {
            await this.userSettings.saveReportsSettings(+this.$route.params.hotelId || undefined);
            this.statusText = this.$tc('saved');
            this.isChanged = false;
        } catch {
            this.statusText = this.$tc('somethingWrong');
            this.isError = true;
        }
    }
}
