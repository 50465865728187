

import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ReportsList from './reports-list.vue';
import NewReport from './new-report';
import type {
    IFilterItem, IProperties, ISchedulerConfig, IRecipient, ICustomColumn, IForm,
} from '../interfaces';
import ScheduledItemModel from '../models/scheduled-item.model';
import ReportsService, { ReportsServiceS } from '../reports.service';

enum PAGES {
    LIST,
    NEW,
    EDIT,
}

@Component({
    components: {
        ReportsList,
        NewReport,
    },
})
export default class CommonPopup extends Vue {
    @inject(ReportsServiceS) private reportsService!: ReportsService;

    @Prop({ type: Object, required: true })
    properties!: IProperties;

    @Prop({ type: Array, default: [] })
    customColumns!: ICustomColumn[];

    @Prop({ type: Array, default: [] })
    filters!: IFilterItem[];

    @Prop({ type: Object, required: true })
    frequency!: ISchedulerConfig;

    @Prop({ type: Array, required: true })
    recipients!: IRecipient[];

    @Prop({ type: Boolean, default: false })
    isServiceLoading!: boolean;

    page = PAGES.LIST;
    reportToEdit: ScheduledItemModel | null = null;
    formKey = Math.random();

    errors: Error[] = [];
    isSaving = false;

    beforeDestroy() {
        if (this.isSettingsPage) {
            return;
        }

        this.reportsService.resetReports();
    }

    beforeMount() {
        this.checkReportToEdit();
    }

    get PAGES() {
        return PAGES;
    }

    get isSettingsPage() {
        return this.$route.name!.includes('.settings.');
    }

    private checkReportToEdit() {
        const { reportId } = this.$route.params;

        if (!reportId) {
            return;
        }

        const reportData = this.reportsService.getReport(reportId);

        if (!reportData) {
            this.closePopup();
            return;
        }

        this.toEdit(reportData);
    }

    private assignFormToFilters(form: ScheduledItemModel) {
        if (!form || !form.filters) {
            return;
        }

        this.filters.forEach(f => {
            const value = f.name === 'compset'
                ? form!.compsetId
                : form!.filters![f.name];

            // NOTE: if is complex select
            if (!Array.isArray(value) && typeof value === 'object') {
                this.$set(f, 'value', [value.key, value.value]);
            } else {
                this.$set(f, 'value', value || (f.options[0] as Item).value);
            }
        });
    }

    get reportToEditCustomColumns(): ICustomColumn[] {
        if (!this.reportToEdit || !this.reportToEdit.filters || !this.reportToEdit.filters.columnsOptions) {
            return this.customColumns;
        }

        const editColumns = this.customColumns.map(cc => {
            const value = this.reportToEdit!.columnsOptions![cc.name];
            return {
                ...cc,
                value,
            };
        });

        return editColumns;
    }

    get reportToEditProperties(): IProperties {
        if (!this.reportToEdit || !this.reportToEdit.numberOfDays) {
            return this.properties;
        }

        const editProperties = {
            ...this.properties,
        };

        if (this.properties.dateRange) {
            editProperties.dateRange = {
                options: [...this.properties.dateRange.options],
                value: this.reportToEdit.numberOfDays,
            };
        }

        return editProperties;
    }

    get reportToEditFrequency(): ISchedulerConfig {
        if (!this.reportToEdit || !this.reportToEdit.schedulerConfig) {
            return this.frequency;
        }

        const editFrequency = Object.fromEntries(
            Object.entries(this.frequency).map(([key, value]) => {
                const editReportValue = this.reportToEdit!.schedulerConfig[key as keyof ISchedulerConfig];
                if (editReportValue !== null && editReportValue !== undefined) {
                    return [key, editReportValue];
                }
                return [key, value];
            }),
        ) as ISchedulerConfig;

        return editFrequency;
    }

    get reportToEditRecipients(): IRecipient[] {
        if (!this.reportToEdit || !this.reportToEdit.recipients) {
            return this.recipients;
        }

        const editRecipients = this.reportToEdit.recipients.map(r => ({
            value: r,
            isValid: true,
        }));

        return editRecipients;
    }

    get isLoading() {
        if (this.isSettingsPage) {
            return false;
        }

        return this.isServiceLoading
            || this.reportsService.loading.isLoading()
            || !this.reportsService.loading.isInitialized;
    }

    private resetFilters() {
        this.reportToEdit = null;
        this.formKey = Math.random();
        this.$emit('input', {});

        this.filters.forEach(f => {
            const { value } = f;

            if (!Array.isArray(value) && typeof value === 'object') {
                this.$set(f, 'value', [null, null]);
            } else {
                this.$set(f, 'value', null);
            }
        });
    }

    toEdit(report: ScheduledItemModel) {
        this.reportToEdit = report;
        this.assignFormToFilters(report);
        this.switchPage(PAGES.EDIT);
    }

    createReport() {
        this.resetFilters();
        this.switchPage(PAGES.NEW);
    }

    switchPage(page: PAGES) {
        this.page = page;

        if (page === PAGES.LIST && this.isSettingsPage) {
            this.closePopup();
        }
    }

    private closePopup() {
        this.$router.push({
            name: this.$route.name!.split('.').slice(0, -1).join('.'),
            params: { ...this.$route.params },
            query: { ...this.$route.query },
        });
    }

    async handleNewReport(form: IForm) {
        this.isSaving = true;
        try {
            await this.reportsService.addReport(form);
        } catch (e) {
            this.errors.push(e as Error);
            return;
        } finally {
            this.isSaving = false;
        }

        this.switchPage(PAGES.LIST);

        this.errors = [];
    }

    async handleEditReport(form: IForm) {
        if (!this.reportToEdit) {
            return;
        }

        this.isSaving = true;

        try {
            await this.reportsService.updateReport(this.reportToEdit.id, form);
        } catch (e) {
            this.errors.push(e as Error);
            return;
        } finally {
            this.isSaving = false;
        }

        this.switchPage(PAGES.LIST);

        this.errors = [];
        this.reportToEdit = null;
    }

    async handleDeleteReport(id: string) {
        await this.reportsService.deleteReport(id);
    }
}
