
import { inject } from '@/inversify';
import { Component, Prop, Vue } from 'vue-property-decorator';

import MarketName from '@/modules/market/components/market-name.vue';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import type Day from '@/modules/common/types/day.type';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import Occupancy from '@/modules/common/components/ui-kit/occupancy/index.vue';
import Demand from '@/modules/common/components/ui-kit/demand/index.vue';

import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import type MarketsService from '@/modules/markets/markets.service';
import type ClusterMarketsService from '@/modules/cluster/cluster-markets.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import MarketsCommonService, { MarketsCommonServiceS } from '../../markets/markets-common.service';
import type MarketsHistoryService from '../markets-history.service';

@Component({
    components: {
        MarketName,
        Demand,
        Occupancy,
    },
})
export default class MarketsStatistics extends Vue {
    @inject(KEY.MarketsHistoryService) private marketsHistoryService!: MarketsHistoryService;
    @inject(KEY.MarketsService) private marketsService!: MarketsService;
    @inject(MarketsCommonServiceS) private marketsCommonService!: MarketsCommonService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.ClusterMarketsService) private clusterMarketsService!: ClusterMarketsService;

    @Prop({ required: false })
    tableDay!: number;

    @Prop({ required: false })
    skeleton!: boolean;

    get day() {
        return +this.$route.params.day as Day;
    }

    get compsetId() {
        return this.$route.params.compsetId || this.documentFiltersService.compsetId;
    }

    get marketId() {
        const compset = this.compsetsService.getCompset(this.compsetId);

        return compset?.marketId;
    }

    get hotelsOnMarket() {
        if (!this.document) {
            return 0;
        }

        return this.marketsCommonService
            .getNumberOfHotels(this.day, this.hotelId, this.document);
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    get hotelCount() {
        const { marketHistoryHotels } = this.marketsHistoryService;
        return marketHistoryHotels?.length;
    }

    get validHotels() {
        const { marketHistoryHotels, dayIndex } = this.marketsHistoryService;
        const hotelMarket = this.marketsHistoryService.getSuitableMarketByDay(dayIndex);

        if (!hotelMarket || !marketHistoryHotels) {
            return [];
        }

        return marketHistoryHotels.map(hotel => [hotel, hotelMarket[hotel]?.position])
            .filter(([, position]) => !!position);
    }

    get myHotelPosition() {
        const position = this.validHotels
            .sort(([, positionA], [, positionB]) => positionA - positionB)
            .findIndex(([hotel]) => hotel === this.hotelId);

        return position + 1;
    }

    get provider() {
        return this.$route.params.source;
    }

    get document() {
        return !this.isHotelPage
            ? this.clusterMarketsService.hotels
                .find(hotel => hotel.hotelId === this.hotelId)?.compsetMain as unknown as MarketsDocumentModel
            : this.marketsService.data[this.provider];
    }

    get isNoData() {
        const isNoData = this.marketsCommonService.isNoData(this.day, this.document);

        return isNoData;
    }

    get isHotelPage() {
        return this.$route.name!.startsWith('hotel.');
    }

    get isNA() {
        return this.marketsCommonService.isNA(this.day, this.hotelId, this.document);
    }

    get demand() {
        return this.marketsCommonService.getDemand(this.day, this.document);
    }

    get occupancy() {
        return this.marketsCommonService.getOccupancy(this.day, this.document);
    }

    get dayColor() {
        if (this.skeleton || !this.document) {
            return {};
        }

        if (this.isNoData || this.isNA) {
            return {
                'no-data': this.isNoData,
                'is-na': this.isNA,
            };
        }

        const color = this.marketsHistoryService.getCardAssessment(this.hotelId);

        return {
            'statistics__metric--high': color === ASSESSMENT_TYPES.GOOD,
            'statistics__metric--med-high': color === ASSESSMENT_TYPES.NORMAL,
            'statistics__metric--med-low': color === ASSESSMENT_TYPES.FAIR,
            'statistics__metric--low': color === ASSESSMENT_TYPES.BAD,
        };
    }
}
