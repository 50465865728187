import { inject, injectable } from '@/inversify';
import CarsFilterApiService, { CarsFilterApiServiceS } from '@/modules/cars/cars-filter-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import {
    COUNTRIES_ANY, DOORS_ANY, MILEAGE_ANY, PAYMENT_TERMS_ANY, TRANSMISSION_ANY, BROKER_TO_BROKER, BROKER_TO_BRAND,
} from '@/modules/cars/constants/car-filter-types.constant';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import _ from 'lodash';
import ParityStore from '@/modules/cars/modules/parity/store/parity.store';
import CAR_FUEL_TYPE from '@/modules/cars/constants/car-fuel-type.constant';
import filterLorsByAllowance from '@/modules/cars/utils/filter-lors-by-allowance.util';
import restrictCarsFilters from '@/modules/cars/utils/restrict-cars-filters.util';
import { KEY } from '@/inversify.keys';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CarsFiltersStore from './store/cars-filters.store';
import CarsService, { CarsServiceS } from './cars.service';
import ICarFilterOption from './interfaces/car-filter-option.interface';
import BranchesModel from './models/branches.model';
import { BROKER, BRAND } from './constants/data-source-mode.constant';
import UserStore from '../user/store/user.store';
import type DocumentFiltersService from '../document-filters/document-filters.service';

export const CarsFiltersServiceS = Symbol.for('CarsFiltersServiceS');
@injectable()
export default class CarsFiltersService {
    @inject(CarsFilterApiServiceS) private carFilterApiService!: CarsFilterApiService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    readonly storeState: CarsFiltersStore = this.storeFacade.getState('CarsFiltersStore');
    readonly parityStoreState: ParityStore = this.storeFacade.getState('ParityStore');
    readonly userStoreState: UserStore = this.storeFacade.getState('UserStore');

    private initFilterWatcher: any;

    private tempHideLocations = [
        'Lyft Los Angeles 3',
        'Lyft Los Angeles 4',
        'Lyft Los Angeles 5',
        'Lyft Los Angeles 6',
        'Lyft Los Angeles 11',
        'Lyft Los Angeles 8',
        'Lyft Los Angeles 10',
        'Lyft Los Angeles 9',
        'Lyft Los Angeles 7',
        'Lyft Los Angeles 13',
        'Lyft Los Angeles 12',
        'Lyft Los Angeles 2',
        'Lyft San Francisco 1',
        'Lyft San Francisco 2',
        'Lyft Los Angeles 1',
        'Lyft New York 1',
        'Lyft New York 2',
        'Lyft New York 4',
        'Lyft Atlanta 1',
        'Lyft Chicago 3',
        'Lyft Chicago 2',
        'Lyft Chicago 1',
        'Lyft Atlanta 2',
    ];

    constructor() {
        this.initFilterWatcher = {};
        if (this.userService.isCarUser) {
            this.storeFacade.watch(() => this.carsService.storeState.settings.chain, async () => {
                this.storeState.loading.reset();
                this.applyDocumentChangeWatcher('Init');
            });
            this.storeFacade.watch(() => this.settings, this.initCurrentFilterValues.bind(this));
            this.storeFacade.watch(() => this.carsService.storeState.settings.pickUpCityCode, async () => {
                this.refreshDoorsFilters();
                this.refreshTransmissionFilters();
                this.refreshMileageFilters();
                this.refreshPaymentTermsFilters();
            });
        }

        this.applyDocumentChangeWatcher('Init');

        this.storeFacade.watch(() => this.carsService.storeState.document, async () => {
            const { dataSource, isAvgPrice } = this.carsService.storeState.settings;
            if (!isAvgPrice) {
                if (dataSource) {
                    const data = this.carsService.storeState.settings.dataSourceCompetitors[dataSource];
                    this.carsService.storeState.settings.competitors = data || this.competitorsFilter;
                }

                if (!this.carsService.storeState.settings.carClassesChange) {
                    this.initCarClasses();
                }

                if (!this.carsService.storeState.settings.carFuelTypeChange) {
                    this.initCarFuelTypes();
                }
            }
        });

        this.storeFacade.watch(() => this.carsService.storeState.document, async () => {
            this.updateDataSources();
        });
    }

    // WatchFor will specify which functionality that enabled the watcher
    applyDocumentChangeWatcher(watchFor: string) {
        if (watchFor in this.initFilterWatcher) {
            this.initFilterWatcher[watchFor]();// If there's watcher already running for the requested function then disable it then init
        }
        this.initFilterWatcher[watchFor] = this.storeFacade.watch(() => this.carsService.storeState.document, async () => {
            this.initFilters();
            if (this.carsService.storeState.document) {
                this.initFilterWatcher[watchFor]();// Kill watcher when data loaded for first time
                delete this.initFilterWatcher[watchFor];
            }
        });
    }

    initFilters() {
        this.initCompetitorFilters();
        this.initDoorsFilter();
        this.initTransmissionFilter();
        this.initMileageFilter();
        this.initPaymentTermsFilter();
    }

    async loadFilters() {
        const chain = this.carsService.currentChain;

        let filters = await this.carFilterApiService.getCarFilters(chain);

        if (!filters) {
            return false;
        }

        if (filters.locations) {
            filters.locations = filters.locations.filter(
                item => this.tempHideLocations.findIndex(hideItem => hideItem.toLowerCase() === item.locationName.toLowerCase()) === -1,
            );

            const userInfo = this.userStoreState.user || { countries: [], locations: [] };
            filters = restrictCarsFilters(filters, userInfo);
        }

        this.carsService.storeState.settings.country = COUNTRIES_ANY;

        this.storeState.settings = { ...filters, fuelTypes: CAR_FUEL_TYPE };
        return true;
    }

    async loadBranches() {
        const branch = await this.carFilterApiService.chainBranches();
        if (!branch) {
            return false;
        }

        const parent = branch;
        const children = branch.childChains || [];
        parent.childChains = [];
        parent.isBroker = true;

        let branches: BranchesModel[] = [parent, ...children];

        const { currentChain } = this.carsService;
        if (currentChain) {
            branches = branches.map(item => {
                const chain = item;
                if (currentChain.chainId === chain.chainId) {
                    chain.isCurrent = true;
                    this.userService.currentCompany = chain.chainName;
                }
                return chain;
            });
        } else {
            branches[0].isCurrent = true;
        }

        this.storeState.branches = branches;

        return true;
    }

    get settings() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        return this.storeState.settings;
    }

    get branches() {
        this.helperService.dynamicLoading(this.storeState.branchesLoading, this.loadBranches.bind(this));
        return this.storeState.branches;
    }

    get competitors() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        const { currentDocumentCompetitors, competitors } = this.carsService.storeState.settings;
        if (currentDocumentCompetitors === null || competitors === null) {
            return this.carsService.storeState.settings.competitors;
        }
        return currentDocumentCompetitors.filter(item => competitors.includes(item));
    }

    async initCurrentFilterValues() {
        await this.initPickUpCity();
        await this.initDataSource();
        await this.initLor();
        await this.initPos();
    }

    async initPickUpCity() {
        if (this.carsService.storeState.settings.pickUpCityCode) {
            return;
        }
        const { locations } = this.settings;

        if (locations && locations.length) {
            const [defaultLocation] = locations;
            this.carsService.storeState.settings.pickUpCityCode = defaultLocation.locationId;
        }
    }

    filterPickUpCitiesByCountry(country: string) {
        const {
            locations, countries, routes, availability,
        } = this.storeState.settings;

        if (!locations || !routes || !availability) {
            return [];
        }

        const availableLocations = locations.filter(location => {
            const isAvailableRoute = routes.find(route => route.pickUpLocationId === location.locationId);
            const isAvailableGroup = availability.find(group => group.id === location.locationId);

            return isAvailableGroup && isAvailableRoute;
        });

        if (!country || country === COUNTRIES_ANY || !countries) {
            return availableLocations;
        }

        const selectedCountryCodes = countries[country];

        return availableLocations.filter(location => selectedCountryCodes.find(code => code === location.locationId));
    }

    async initDataSource() {
        if (this.carsService.storeState.settings.dataSource) {
            return;
        }
        const { dataSources } = this.settings;
        const { chainName } = this.userService;

        if (dataSources && dataSources.length) {
            const [defaultDataSource] = dataSources;
            this.carsService.storeState.settings.dataSourceCompetitors[defaultDataSource] = [];
            this.carsService.storeState.settings.dataSource = defaultDataSource;
        }
        if (this.availableDataSources) {
            this.carsService.storeState.settings.brokersCompetitors = this.availableDataSources.filter(item => ![chainName, 'Brand'].includes(item));
            this.parityStoreState.settings.dataSources = this.availableDataSources.filter(item => ![chainName].includes(item));
        }
    }

    updateDataSources() {
        const { chainName } = this.userService;
        if (chainName && this.availableDataSources && this.carsService.dataSourcesFromDoc && this.carsService.dataSourcesFromDoc.length > 1) {
            const providers = this.availableDataSources.filter(item => ![chainName, 'Brand'].includes(item));
            this.carsService.storeState.settings.brokersCompetitors = _.intersection(
                providers,
                this.carsService.dataSourcesFromDoc,
                this.carsService.storeState.settings.brokersCompetitors,
            );
        }
    }

    get availableDataSources() {
        const { pickUpCityCode } = this.carsService.storeState.settings;
        const { availability, chainMode } = this.storeState.settings;
        const { dataSources } = this.settings;
        const { dataSourcesFromDoc } = this.carsService;

        if (!pickUpCityCode || !availability) {
            return null;
        }

        const currentLocation = availability.find(location => location.id === pickUpCityCode);

        if (!currentLocation) {
            return [];
        }

        const availableDataSources = Object.keys(currentLocation.path).filter(item => (dataSources || []).includes(item));

        if (chainMode !== BROKER || !dataSourcesFromDoc.length) {
            return availableDataSources;
        }

        return _.intersection(availableDataSources, dataSourcesFromDoc);
    }

    async initLor() {
        if (this.carsService.storeState.settings.lor) {
            return;
        }
        let { lor } = this.settings;
        if (this.userService && this.userService.user && this.userService.user.chainName === 'Rentalcars' && lor) {
            const lorsToInclude = [3, 7, 10, 14];
            lor = lor.filter(item => lorsToInclude.includes(item));
        }
        if (lor && lor.length) {
            const [defaultLor] = lor;
            this.carsService.storeState.settings.lor = defaultLor;
        }
    }

    get availableLors() {
        const { pickUpCityCode, pos } = this.carsService.storeState.settings;
        const { availability, allowedLORs } = this.storeState.settings;
        const { dataSource, currentDataSourceMode } = this.carsService.storeState.settings;
        const { currentCompany } = this.userService;

        if (!availability || !dataSource || !pos || pos === 'N/A' || !currentCompany) {
            return [];
        }

        const currentLocation = availability.find(location => location.id === pickUpCityCode);

        if (!currentLocation) {
            return [];
        }

        const { brokersCompetitors } = this.carsService.storeState.settings;

        let brokers: string[] = [];

        if (this.isBrokerMode && currentDataSourceMode === BROKER_TO_BROKER && brokersCompetitors) {
            brokers = _.intersection(brokersCompetitors, Object.keys(currentLocation.path));
        } else if (this.isBrokerMode && currentDataSourceMode === BROKER_TO_BRAND) {
            brokers = _.intersection(Object.keys(currentLocation.path), [currentCompany, 'Brand']);
        } else {
            brokers = _.intersection(Object.keys(currentLocation.path), [dataSource]);
        }

        const lors = brokers.flatMap(broker => Object.keys(currentLocation.path[broker][pos] || { }));

        const loksByAvailability = _.uniq(lors);

        return filterLorsByAllowance({ pos, allowedLORs, lorsToFilter: loksByAvailability });
    }

    initPos() {
        if (this.carsService.storeState.settings.pos) {
            return;
        }
        const { pos, defaultLocationsPOS } = this.settings;
        const { pickUpCityCode } = this.carsService.storeState.settings;

        if (pos && pos.length) {
            const locationDefaultPOS = pickUpCityCode ? defaultLocationsPOS[pickUpCityCode] : null;
            this.carsService.storeState.settings.pos = locationDefaultPOS || pos[0];
        }
    }

    get availablePos() {
        const carSettings = this.carsService.storeState.settings;
        const { brokersCompetitors } = this.carsService.storeState.settings;
        const { availability } = this.storeState.settings;
        const { currentDataSourceMode } = this.carsService.storeState.settings;
        const { currentCompany } = this.userService;
        const dataSourcesByDOC: string[] = _.get(this.carsService.storeState, 'document.dataSource', []);

        if (!availability || !currentCompany) {
            return null;
        }

        const {
            clusters,
        } = this.settings;

        const { clusterMode } = this.settings.features;

        const {
            country, cluster, isAvgPrice, pickUpCityCode,
        } = this.carsService.storeState.settings;

        if (!country) {
            return [];
        }

        let pickUpCityCodes: string[] = [];
        if (cluster && country && clusters && clusterMode) {
            pickUpCityCodes = clusters[country][cluster];
        } else if (carSettings.pickUpCityCode) {
            pickUpCityCodes.push(carSettings.pickUpCityCode);
        }

        const currentDoc = availability.filter(doc => pickUpCityCodes.includes(doc.id));

        if (!currentDoc || !carSettings.dataSource || !dataSourcesByDOC) {
            return null;
        }
        let locationPos: string[] = [];
        const locationBYPOS: {[key: string]: string[]} = {};
        currentDoc.forEach(doc => {
            if (carSettings && carSettings.dataSource) {
                let brokers = [];
                if (this.isBrokerMode && currentDataSourceMode === BROKER_TO_BROKER && brokersCompetitors) {
                    const competitors = (dataSourcesByDOC || []).length ? dataSourcesByDOC : brokersCompetitors;
                    brokers = _.intersection(competitors, Object.keys(doc.path));
                } else if (this.isBrokerMode && currentDataSourceMode === BROKER_TO_BRAND) {
                    brokers = [currentCompany, 'Brand'];
                } else {
                    brokers = [carSettings.dataSource];
                }

                brokers.forEach(brokerName => {
                    Object.keys(doc.path[brokerName] || { }).forEach(pos => {
                        locationPos.push(pos);

                        if (!(pos in locationBYPOS)) {
                            locationBYPOS[pos] = [];
                        }

                        locationBYPOS[pos].push(doc.id);
                    });
                });
            }
        });

        locationPos = _.uniq(locationPos);
        let availablePos: string[] = [];
        const allLocation = this.filterPickUpCitiesByCountry(country);
        if (cluster && country && clusters && clusterMode) {
            const locationsIds = clusters[country][cluster];
            const filteredLocations = allLocation.filter(location => locationsIds.includes(location.locationId));
            let clusterLocations: string[] = [];
            if (isAvgPrice) {
                clusterLocations = filteredLocations.map(location => location.locationId);
            } else {
                if (!pickUpCityCode) {
                    return null;
                }

                const currentLocation = allLocation.find(location => location.locationId === pickUpCityCode);

                if (!currentLocation) {
                    return null;
                }
                clusterLocations.push(currentLocation.locationId);
            }

            locationPos.forEach(pos => {
                if (carSettings.dataSource) {
                    const availableLocation = Object.values(locationBYPOS[pos]);
                    const diffResult = _.difference(clusterLocations, availableLocation);
                    if (diffResult.length === 0) {
                        availablePos.push(pos);
                    }
                }
            });
        } else {
            availablePos = locationPos;
        }

        if (!availablePos || availablePos.length === 0) {
            this.carsService.storeState.settings.pos = 'N/A';
            return null;
        }
        return availablePos;
    }

    get countriesFilter(): string[] {
        const { countries } = this.storeState.settings;

        if (!countries) {
            return [COUNTRIES_ANY];
        }

        const countryNames = new Set([COUNTRIES_ANY].concat(Object.keys(countries)));

        return Array.from(countryNames);
    }

    async initCountries() {
        this.carsService.storeState.settings.country = COUNTRIES_ANY;
    }

    resetCompetitorsFilter() {
        const mainDocuments = this.carsService.storeState.document;
        if (mainDocuments && mainDocuments.checkinDates) {
            Object.keys(mainDocuments.checkinDates || {}).forEach(day => {
                Object.keys(mainDocuments.checkinDates[Number(day)] || {}).forEach(company => {
                    if (this.documentFiltersService.settings.documentVendors) {
                        this.documentFiltersService.settings.documentVendors.add(company);
                    }
                });
            });
        }
    }

    get competitorsFilter(): string[] {
        const { currentCompany } = this.userService;
        const { allowedBrands } = this.storeState.settings;
        if (this.carsService.storeState.analysis.documents) {
            this.resetCompetitorsFilter();
        }
        const documentVendors = this.documentFiltersService.settings.documentVendors ? [...this.documentFiltersService.settings.documentVendors] : [];
        if (this.carsService.storeState.settings.dataSource === 'Brand') {
            if (allowedBrands === null) {
                return [] as string[];
            }
            return allowedBrands.filter((brand: string) => brand !== currentCompany)
                .filter(item => documentVendors.includes(item))
                .filter(item => this.isProviderAvailableInCurrentPOS(item));
        }

        return this.companyCompetitors.filter(item => this.isProviderAvailableInCurrentPOS(item));
    }

    get companyCompetitors() {
        const { currentCompany } = this.userService;
        const filter = new Set();
        const { document } = this.carsService.storeState;

        if (!document || currentCompany === null) {
            return Array.from(filter) as string[];
        }

        const { checkinDates } = document;

        Object.entries(checkinDates).forEach(([key, companies]) => {
            if (!companies) {
                return;
            }
            Object.keys(companies).forEach(companyName => {
                filter.add(companyName);
            });
        });
        filter.delete(currentCompany);

        return Array.from(filter) as string[];
    }

    initCompetitorFilters() {
        const { dataSource } = this.carsService.storeState.settings;
        this.carsService.storeState.settings.competitors = this.competitorsFilter.length
            ? this.competitorsFilter
            : null;

        if (dataSource) {
            this.carsService.storeState.settings.dataSourceCompetitors[dataSource] = this.carsService.storeState.settings.competitors;
        }
    }

    refreshTransmissionFilters() {
        const [defaultTransmissionFilter] = this.transmissionFilter.filter(option => !option.disabled);
        this.carsService.storeState.settings.transmission = defaultTransmissionFilter.value;
    }

    refreshDoorsFilters() {
        const [defaultDoorsFilter] = this.doorsFilter.filter(option => !option.disabled);
        this.carsService.storeState.settings.doors = defaultDoorsFilter.value;
    }

    refreshMileageFilters() {
        const [defaultMileageFilter] = this.mileageFilter.filter(option => !option.disabled);
        this.carsService.storeState.settings.mileage = defaultMileageFilter.value;
    }

    refreshPaymentTermsFilters() {
        const [defaultPaymentTerm] = this.addPaymentTerms.filter(option => !option.disabled);
        this.carsService.storeState.settings.paymentTerms = defaultPaymentTerm.value;
    }

    get allCarClasses(): string[] {
        return this.storeState.settings.carClass ? this.storeState.settings.carClass : [];
    }

    get allCompetitors(): string[] {
        return this.competitorsFilter ? this.competitorsFilter : [];
    }

    get carClassFilter(): string[] {
        const filter = new Set();
        const { currentCompany } = this.userService;
        const { document } = this.carsService.storeState;

        if (!document || currentCompany === null) {
            return Array.from(filter) as string[];
        }

        const { checkinDates } = document;

        Object.entries(checkinDates).forEach(([day, companies]) => {
            if (!companies) {
                return;
            }
            Object.entries(companies).forEach(([companyName, carClasses]) => {
                Object.keys(carClasses).forEach(carClass => {
                    filter.add(carClass);
                });
            });
        });

        return Array.from(filter) as string[];
    }

    get allowedBrands(): string[] {
        return [...(this.settings.allowedBrands || [])];
    }

    get brandsFilter(): string[] {
        return [...(this.settings.brands || [])];
    }

    initCarClasses() {
        // Disable commercial car class at first load. Temporary solution
        this.carsService.storeState.settings.carClasses = this.carClassFilter.filter(carClass => carClass !== 'Commercial');
    }

    initCarFuelTypes() {
        this.carsService.storeState.settings.carFuelTypes = [...CAR_FUEL_TYPE];
    }

    getLocationCodeByName(name: string) {
        const { locations } = this.storeState.settings;

        if (!locations || !locations.length) {
            return null;
        }

        const defaultLocation = locations.find(loc => loc.locationName === name);
        return defaultLocation ? defaultLocation.locationId : null;
    }

    getLocationNameByCode(code: string) {
        const { locations } = this.storeState.settings;

        if (!locations || !locations.length) {
            return null;
        }

        const defaultLocation = locations.find(loc => loc.locationId === code);
        return defaultLocation ? defaultLocation.locationName : null;
    }

    get addPaymentTerms(): ICarFilterOption[] {
        return this.extractCarFilters('paymentTerms', PAYMENT_TERMS_ANY);
    }
    initPaymentTermsFilter() {
        const currentFilterValue = this.carsService.storeState.settings.paymentTerms;

        if (!this.addPaymentTerms.find(filterValue => filterValue.value === currentFilterValue)) {
            const [defaultPaymentTerm] = this.addPaymentTerms.filter(option => !option.disabled);
            this.carsService.storeState.settings.paymentTerms = defaultPaymentTerm.value;
        }
    }

    get doorsFilter(): ICarFilterOption[] {
        return this.extractCarFilters('doors', DOORS_ANY);
    }
    initDoorsFilter() {
        const currentFilterValue = this.carsService.storeState.settings.doors;

        if (!this.doorsFilter.find(filterValue => filterValue.value === currentFilterValue)) {
            const [defaultDoorsFilter] = this.doorsFilter.filter(option => !option.disabled);
            this.carsService.storeState.settings.doors = defaultDoorsFilter.value;
        }
    }

    get transmissionFilter(): ICarFilterOption[] {
        return this.extractCarFilters('transmission', TRANSMISSION_ANY);
    }
    initTransmissionFilter() {
        const currentFilterValue = this.carsService.storeState.settings.transmission;

        if (!this.transmissionFilter.find(filterValue => filterValue.value === currentFilterValue)) {
            const [defaultTransmissionFilter] = this.transmissionFilter.filter(option => !option.disabled);
            this.carsService.storeState.settings.transmission = defaultTransmissionFilter.value;
        }
    }

    get mileageFilter(): ICarFilterOption[] {
        return this.extractCarFilters('mileage', MILEAGE_ANY);
    }
    initMileageFilter() {
        const currentFilterValue = this.carsService.storeState.settings.mileage;

        if (!this.mileageFilter.find(filterValue => filterValue.value === currentFilterValue)) {
            const [defaultMileageFilter] = this.mileageFilter.filter(option => !option.disabled);
            this.carsService.storeState.settings.mileage = defaultMileageFilter.value;
        }
    }

    getHiddenProvidersInPOS(pos: string): string[] {
        return this.storeState.settings.posProviderHidden[pos] || [];
    }

    isProviderAvailableInCurrentPOS(provider: string): boolean {
        const { pos } = this.carsService.storeState.settings;
        const hiddenProviders = pos ? this.getHiddenProvidersInPOS(pos) : [];
        return !hiddenProviders.find(item => item.toLowerCase() === provider.toLowerCase());
    }

    extractCarFilters(filterFieldName: 'mileage' | 'transmission' | 'doors' | 'paymentTerms', any: string | null): ICarFilterOption[] {
        const filters: ICarFilterOption[] = [];
        const { currentCompany } = this.userService;
        const { document } = this.carsService.storeState;

        if (any !== null) {
            filters.push({ value: any, disabled: false });
        }

        const currentFilterValues = this.storeState.settings[filterFieldName];

        if (currentFilterValues) {
            currentFilterValues.forEach(item => filters.push({ value: item, disabled: true }));
        }

        if (!document || currentCompany === null) {
            return filters;
        }

        const { checkinDates } = document;

        Object.entries(checkinDates).forEach(([day, companies]) => {
            if (!companies) {
                return;
            }
            Object.entries(companies).forEach(([companyName, carClasses]) => {
                Object.entries(carClasses).forEach(([carClassKey, carClass]) => {
                    carClass.forEach(carItem => {
                        const currentFilterValue = carItem[filterFieldName];
                        const valueInFiltersIndex = filters.findIndex(filter => filter.value === currentFilterValue);

                        if (currentFilterValue && (valueInFiltersIndex === -1 || filters[valueInFiltersIndex].disabled)) {
                            if (valueInFiltersIndex === -1) {
                                filters.push({ value: currentFilterValue, disabled: false });
                            } else {
                                filters[valueInFiltersIndex].disabled = false;
                            }
                        }
                    });
                });
            });
        });

        return filters;
    }

    get allChildren() {
        const { branches } = this;
        const currentCain = this.carsService.currentChain;
        return branches.filter(item => currentCain && item.chainId !== currentCain.chainId);
    }

    set chain(value: BranchesModel) {
        const chain = value;
        let { branches } = this;

        branches = branches.map(item => {
            const chainItem = item;
            if (chain.chainId === chainItem.chainId) {
                chainItem.isCurrent = true;
            } else {
                chainItem.isCurrent = false;
            }
            return chainItem;
        });

        chain.isCurrent = true;
        this.carsService.storeState.settings.chain = chain;
        this.carsService.currentChain = chain;
        this.storeState.branches = branches;
    }

    get clusters() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        return this.storeState.settings.clusters;
    }

    get clusterMode() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        return this.storeState.settings.features.clusterMode;
    }

    get currentChainCode() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        return this.storeState.settings.chainCode;
    }

    get chainMode() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        return this.storeState.settings.chainMode;
    }

    get isAvailability() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        return this.storeState.settings.isAvailability;
    }

    get countryCodes() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        return this.storeState.settings.countryCodes;
    }

    get isBrokerMode() {
        const { chainMode } = this.settings;
        return chainMode === BROKER;
    }

    get isBrandMode() {
        const { chainMode } = this.settings;
        return chainMode === BRAND;
    }

    get carProvidersDisplayNamesMap() {
        const { carProviders } = this.storeState.settings;
        return _.reduce(
            carProviders,
            (acc, { groupedDocumentName, displayName }) => ({
                ...acc,
                [groupedDocumentName]: displayName,
            }),
            { },
        );
    }

    get selectedLocationName() {
        const { locations } = this.settings;
        const { pickUpCityCode } = this.carsService.storeState.settings;
        const current = (locations || []).find(location => location.locationId === pickUpCityCode);
        return current ? current.locationName : '';
    }

    get allowedLocationPerPos() {
        const { allowedPosCodeLocations } = this.settings;
        if (!allowedPosCodeLocations) {
            return [];
        }
        const allowedLocationPerPos = new Map();
        Object.keys(allowedPosCodeLocations).forEach(pos => {
            Object.keys(allowedPosCodeLocations[pos]).forEach(location => {
                if (!allowedLocationPerPos.has(location)) {
                    allowedLocationPerPos.set(location, [pos]);
                } else {
                    allowedLocationPerPos.set(location, [...allowedLocationPerPos.get(location), pos]);
                }
            });
        });
        return allowedLocationPerPos;
    }
}
