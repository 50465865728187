
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Component, Vue } from 'vue-property-decorator';
import CompsetDocumentFilter from '@/modules/document-filters/components/compset-type-document-filter.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import ExcelBtn from '@/modules/common/components/excel-btn.vue';
import type RankingService from '../ranking.service';

@Component({
    components: {
        CustomSelect,
        CompsetDocumentFilter,
        ExcelBtn,
    },
})
export default class RankingActions extends Vue {
    @inject(KEY.RankingService) private rankingService!: RankingService;

    get isLoading() {
        return this.rankingService.isLoading;
    }
}
