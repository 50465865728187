
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import moment from 'moment';

import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import type RatesFiltersService from '@/modules/rates/rates-filters.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import type ClusterRatesService from '@/modules/cluster/cluster-rates.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import WeekdayLetter from '@/modules/cluster/filters/weekend-letter.filter';

import ListHeader from '../../_common/list-days-header.vue';
import HotelsList from '../../_common/hotels-list/index.vue';
import RatesDaysSet from './rates-days-set.vue';

@Component({
    components: {
        ListHeader,
        HotelsList,
    },
    filters: {
        DateFilter: (value: (Date | null)) => {
            if (!value) return '';
            return moment(value).format('D/M');
        },

        WeekdayLetter,
    },
})
export default class RatesClusterList extends Vue {
    @inject(KEY.ClusterRatesService) private clusterRatesService!: ClusterRatesService;
    @inject(KEY.RatesFiltersService) private ratesFiltersService!: RatesFiltersService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    get scoreSorting() {
        return this.clusterRatesService.scoreSorting;
    }

    get hotelNameSorting() {
        return this.clusterRatesService.hotelNameSorting;
    }

    get tableData() {
        if (this.hotels === null || !this.hotels.length) {
            return [];
        }

        return this.hotels.map(h => ({
            table: [{
                value: h.hotelName,
                onClick: () => this.toHotel(h),
            }, {
                value: h.totalScore,
            }, {
                value: h.newTotalScore !== 0
                    ? `${h.newTotalScore > 0 ? '+' : ''}${Math.round(h.newTotalScore)}`
                    : 0,

                class: h.newTotalScore && (h.newTotalScore > 0 ? 'green-change' : 'red-change'),
            }],
            component: {
                element: RatesDaysSet,
                props: { hotelData: h },
            },
        }));
    }

    get headerData() {
        return [
            {
                value: 'hotelName',
                name: this.$tc('titles.hotelName'),
                sort: this.hotelNameSorting,
            },
            {
                value: 'totalScore',
                name: this.$tc('score.rateValueScore'),
                sort: this.scoreSorting,
            },
            {
                value: 'newTotalScore',
                name: this.$tc('titles.change'),
            },
        ];
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    get hotels() {
        return this.clusterRatesService.hotels;
    }

    get pagesCount() {
        return this.clusterRatesService.hotelsCount;
    }

    handleSort(field: string) {
        switch (field) {
            case 'hotelName':
                this.clusterRatesService.toggleHotelNameSort();
                break;
            case 'totalScore':
                this.clusterRatesService.toggleScoreSort();
                break;
            default:
                break;
        }
    }

    private async toHotel(hotelData: ClusterHotelsRatesModel) {
        const compset = hotelData.compsetMain?.id ? hotelData.compsets.find(c => c.id === hotelData.compsetMain!.id) : hotelData.compsets[0];
        this.userViewService.goToHotelPage(hotelData.hotelId, compset);
    }
}
