
import { inject } from '@/inversify';
import { Component, Mixins } from 'vue-property-decorator';

import { PRICE_SHOWN } from '@/modules/rates/constants';
import CustomDateRangePicker from '@/modules/common/components/ui-kit/custom-date-range-picker.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import DownloadReportForm, { DownloadReportControlMixin } from '@/modules/common/components/download-report-form.vue';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import Item from '@/modules/common/interfaces/item.interface';
import DiLiteFilterItemsMixin from '../mixins/filter-items.mixin';
import type DiLiteMarketService from '../di-lite-market.service';
import { DILiteDownloadExcelForm } from '../di-lite-market-api.service';

const DAY_RANGE_PRESETS = [30, 60, 90];

@Component({
    components: {
        DownloadReportForm,
    },
    mixins: [
        DiLiteFilterItemsMixin,
    ],
})
export default class DownloadExcelPage extends Mixins(DiLiteFilterItemsMixin, DownloadReportControlMixin) {
    @inject(KEY.DiLiteMarketService)
    private diLiteMarketService!: DiLiteMarketService;

    @inject(KEY.DocumentFiltersService)
    private documentFiltersService!: DocumentFiltersService;

    isLoading = false;
    form: DILiteDownloadExcelForm = {} as DILiteDownloadExcelForm;

    get attrs() {
        const { filters, buttons, properties } = this;
        const { isLoading } = this;

        return {
            filters,
            buttons,
            isLoading,
            properties,
            dataType: 'titles.dilite',
        };
    }

    private get properties() {
        return [
            {
                label: this.$tc('rates.datesCount'),
                key: 'daysCount',
                default: -1,
                component: CustomSelect,
                props: {
                    items: DAY_RANGE_PRESETS
                        .map(days => ({
                            name: this.$t('insights.nextDays', [days]).toString(),
                            value: days,
                        }))
                        .concat([{
                            name: this.$tc('rates.customRange'),
                            value: -1,
                        }]),
                },
                handlers: {
                    input: this.onDaysCountChange,
                },
            },
            {
                label: this.$tc('dateRange'),
                key: 'monthrange',
                component: CustomDateRangePicker,
                default: [],
                props: {
                    maxDays: 365,
                },
                handlers: {
                    input: this.onDateRangeChange,
                },
            },
        ];
    }

    private get isRangeValid() {
        const { monthrange } = this.form;

        if (!monthrange) return false;

        const maxRange = 365;
        const [start, end] = monthrange.map((date: string) => new Date(date));

        if (!start || !end) return false;

        const diff = Math.abs(start.getTime() - end.getTime());
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        return diffDays <= maxRange;
    }

    private get buttons() {
        return [
            {
                label: this.$tc('popup.download'),
                onClick: this.downloadExcel.bind(this),
            },
        ];
    }

    private get filters() {
        const { los: mainLos, pos: mainPos } = this.allChannelsService.filters;
        const { numberOfGuests: mainNumberOfGuests } = this.allChannelsService.settings;
        const { mealTypeId: mainMealType } = this.allChannelsService.settings;
        const { roomTypeId: mainRoomType } = this.allChannelsService.settings;
        const { priceShown: mainPriceShown } = this.documentFiltersService;
        const { device: mainDevice } = this.allChannelsService.settings;

        const roomTypeItems = this.roomTypeItems
            .filter(i => i.value !== -1)
            .map(i => ({ ...i, name: this.$tc(i.name) }));

        let mealTypeItems = this.mealTypeItems
            .filter(i => i.value !== -1);

        if (!roomTypeItems.length) return [];
        if (!mealTypeItems.length) return [];

        mealTypeItems = mealTypeItems.map(v => ({
            name: this.$tc(v.name),
            value: this.mealTypesService.getMealType(+v.value)!.name,
        }));

        const defaultMealType = mainMealType === -1
            ? [...mealTypeItems]
            : mealTypeItems.filter(i => i.value === this.mealTypesService.getMealType(mainMealType)!.name);

        const defaultRoomType = mainRoomType === -1
            ? [...roomTypeItems]
            : roomTypeItems.filter(i => i.value === mainRoomType);

        const defaultPriceType = this.allChannelsService.settings.priceType || 'lowest';

        const defaulPriceShown = `${(mainPriceShown || PRICE_SHOWN).toLowerCase()}_price`;

        return [
            {
                label: this.$tc('titles.priceType'),
                key: 'price_type',
                options: this.priceTypeItems,
                default: defaultPriceType,
            },
            {
                label: this.$tc('titles.los'),
                key: 'los',
                options: this.losItems,
                default: mainLos || this.losItems[0].value,
            },
            {
                label: this.$tc('titles.pos'),
                key: 'pos',
                options: this.posItems,
                default: mainPos || this.posItems[0].value,
            },
            {
                label: this.$tc('titles.numberOfGuests'),
                key: 'occupancy',
                options: this.numberOfGuestsItems,
                default: mainNumberOfGuests || 2,
            },
            {
                label: this.$tc('titles.roomType'),
                key: 'room_type',
                options: roomTypeItems,
                default: defaultRoomType,
                multiselect: true,
            },
            {
                label: this.$tc('titles.mealType'),
                key: 'meal_type',
                options: mealTypeItems,
                default: defaultMealType,
                multiselect: true,
            },
            {
                label: this.$tc('titles.device'),
                key: 'device_name',
                options: this.devicesItems,
                default: mainDevice || this.devicesItems[0].value,
            },
            {
                label: this.$tc('titles.price'),
                key: 'price',
                options: [
                    { value: 'shown_price', name: this.$tc('filters.price.shown') },
                    { value: 'net_price', name: this.$tc('filters.price.net') },
                    { value: 'total_price', name: this.$tc('filters.price.total') },
                ],
                default: defaulPriceShown,
            },
        ];
    }

    private async downloadExcel(form: DILiteDownloadExcelForm) {
        const mealType = ((form.meal_type || []) as unknown as Item[])
            .map(i => i.value) as string[];

        const roomType = ((form.room_type || []) as unknown as Item[])
            .map(i => i.name) as string[];

        if (!this.validateForm()) return;

        try {
            this.isLoading = true;
            const hotelId = +this.$route.params.hotelId;
            await this.diLiteMarketService.downloadExcel({
                ...form,
                room_type: roomType,
                meal_type: mealType,
                device_name: form.device_name || undefined,
                daysCount: undefined,
                start_date: form.monthrange![0],
                end_date: form.monthrange![1],
                monthrange: undefined,
            }, hotelId);
            this.closeForm();
        } catch (_) {
            this.triggerFormError(this.$tc('err.nodata'));
        } finally {
            this.isLoading = false;
        }
    }

    private validateForm() {
        const { monthrange, meal_type: mealTypes, room_type: roomTypes } = this.form;
        const { pos, los } = this.form;

        if (monthrange && !monthrange.length) {
            this.triggerFormError(this.$tc('rates.err.selectRange'));
            return false;
        }

        if (!this.isRangeValid) {
            this.triggerFormError(this.$tc('rates.err.dateRange'));
            return false;
        }

        if (!pos) {
            this.triggerFormError(this.$tc('rates.err.selectPos'));
            return false;
        }

        if (!los) {
            this.triggerFormError(this.$tc('rates.err.selectLos'));
            return false;
        }

        if (!mealTypes || !mealTypes.length) {
            this.triggerFormError(this.$tc('rates.err.selectMeal'));
            return false;
        }

        if (!roomTypes || !roomTypes.length) {
            this.triggerFormError(this.$tc('rates.err.selectRoom'));
            return false;
        }

        return true;
    }

    private onDaysCountChange(value: any) {
        if (value === -1) {
            this.form.monthrange = [];
            return;
        }

        const startDate = new Date(new Date().toISOString().split('T')[0]);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + value);

        this.form.monthrange = [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]];
    }

    private onDateRangeChange(range: [string, string]) {
        const today = new Date();
        const start = new Date(range[0]);

        const isStartToday = start.getDate() === today.getDate()
            && start.getMonth() === today.getMonth()
            && start.getFullYear() === today.getFullYear();

        if (!isStartToday) {
            this.form.daysCount = -1;
            return;
        }

        const end = new Date(range[1]);
        const deltaDays = Math.ceil(Math.abs(start.getTime() - end.getTime()) / (1000 * 3600 * 24)) + 1;

        if (DAY_RANGE_PRESETS.includes(deltaDays)) {
            this.form.daysCount = deltaDays;
        } else {
            this.form.daysCount = -1;
        }
    }
}
