
import { Component, Vue } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RVSPageTabs from '../components/hotel/rvs-page-tabs.vue';
import RVSHeader from '../components/hotel/rvs-header.vue';

@Component({
    components: {
        PageWrapper,
        RVSHeader,
        RVSPageTabs,
    },
})
export default class RVSIndexPage extends Vue {}
