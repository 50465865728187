
import { Component, Vue } from 'vue-property-decorator';
import { OtelRateHotelPageLoading } from '@/modules/open-telemetry/decorators';
import RatesTable from '../components/table/rates-table.vue';
import TablePageLayout from '../components/table-page-layout.vue';

@OtelRateHotelPageLoading
@Component({
    components: {
        RatesTable,
        TablePageLayout,
    },
})
export default class RatesTablePage extends Vue {}
