import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';
import BmlDataModel from '@/modules/di-lite/models/market-bml-data.model';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import DownloadExcelModel from '../rates/models/download-excel.model';
import { otelDownload } from '../open-telemetry/decorators';

/* eslint-disable */
export interface DILiteDownloadExcelForm {
    pos: string;// OPTIONAL
    los: number;// OPTIONAL
    meal_type: string[];// OPTIONAL
    room_type: string[];// OPTIONAL
    occupancy: number;// OPTIONAL
    price_type: string;// OPTIONAL
    price: string;// OPTIONAL
    device_name?:  string;
    monthrange?: string[];
    daysCount?: number;
    start_date?: string;
    end_date?: string;
}
/* eslint-enable */

export const DiLiteMarketApiServiceS = Symbol.for('DiLiteMarketApiServiceS');
@injectable()
export default class DiLiteMarketApiService {
    @inject(ApiServiceS) private apiService!: ApiService;

    async getTestReservationData(marketIds: number[]): Promise<{[name: string]: number} | null> {
        const { data } = await this.apiService.post('market/light-di/tr-chart', { market_ids: marketIds });

        if (!data) {
            return null;
        }

        return data.data as {[name: string]: number};
    }

    async getRcaData(source: string[], marketIds: number[]): Promise<{[name: string]: number} | null> {
        const { data } = await this.apiService.post('market/light-di/rca-chart', { source, market_ids: marketIds });

        if (!data) {
            return null;
        }

        return data.data as {[name: string]: number};
    }

    async getBmlData(source: string[], marketIds: number[]): Promise<BmlDataModel | null> {
        const { data } = await this.apiService.post('market/light-di/bml-chart', { source, market_ids: marketIds });

        if (!data) {
            return null;
        }

        const bmlData = plainToClass(BmlDataModel, data.data, { excludeExtraneousValues: true });
        // Correct order is: Beat -> Meet -> Lose
        const sortedBmlData = {
            beat: bmlData.beat,
            meet: bmlData.meet,
            lose: bmlData.lose,
        };

        return sortedBmlData;
    }

    @otelDownload('dilite')
    async downloadExcel(form: DILiteDownloadExcelForm, hotelId: number) {
        const res = await this.apiService
            .post(`rate/excel-light/${hotelId}`, form);

        if (!res || !res.data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel> res.data, { excludeExtraneousValues: true });
    }
}
