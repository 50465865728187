
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import RatesGraphHotels from '@/modules/rates/components/graph/rates-graph-hotels.vue';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import RatesGraphTypeChanger from '@/modules/rates/components/graph/rates-graph-type-changer.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import type Day from '@/modules/common/types/day.type';
import OccupancyDemandGraph from '@/modules/common/components/ui-kit/occupancy-demand-graph/index.vue';
import { OtelRateHotelPageLoading } from '@/modules/open-telemetry/decorators';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import RatesOutOfRangeMessage from '../components/rates-out-of-range-message.vue';
import type RatesService from '../rates.service';

@OtelRateHotelPageLoading
@Component({
    components: {
        DateDocumentFilter,
        RatesActions,
        RatesGraphHotels,
        RatesGraphTypeChanger,
        PageWrapper,
        RatesHeader,
        TogglePrices,
        OccupancyDemandGraph,
        RatesOutOfRangeMessage,
    },
})
export default class RatesGraphHotelsPage extends Vue {
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    day: Day | null = null;
    demandGraphOffset: number | null = null;

    selectedDay(day: Day | null) {
        this.day = day;
    }

    setDemandGraphOffset(prices: { maxPrice: number | null}) {
        const { maxPrice } = prices;
        const symbolWidth = 7.5;
        this.demandGraphOffset = maxPrice ? String(maxPrice).length * symbolWidth : 0;
    }

    get showFooter() {
        return !this.isOutOfRange && !this.isOutOfRange;
    }

    get isOutOfRange() {
        return this.ratesService.isOutOfRange() && !this.ratesService.isLoading;
    }

    get isAllChannelsMode() {
        return this.ratesService.isAllChannelsMode;
    }

    get isNonSupportedModal() {
        return this.$route.name!.includes('.not-supported');
    }

    get demandAndOccupancy() {
        if (!this.documentFiltersService.days) {
            return null;
        }
        const demandAndOccupancyArray: {demand: (number | null), occupancy: (number | null)}[] = [];
        this.documentFiltersService.days.forEach(day => {
            const demandValue = this.ratesService.getDemand(day);
            const occupancyValue = this.ratesService.getOccupancy(day);
            demandAndOccupancyArray.push({ demand: demandValue, occupancy: occupancyValue });
        });
        return demandAndOccupancyArray;
    }
}
