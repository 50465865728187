import { render, staticRenderFns } from "./markets-table.page.vue?vue&type=template&id=2f9fcc40&scoped=true&"
import script from "./markets-table.page.vue?vue&type=script&lang=ts&"
export * from "./markets-table.page.vue?vue&type=script&lang=ts&"
import style0 from "./markets-table.page.vue?vue&type=style&index=0&id=2f9fcc40&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f9fcc40",
  null
  
)

export default component.exports