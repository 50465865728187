
import { inject } from '@/inversify';
import { Component } from 'vue-property-decorator';
import moment from 'moment';

import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type PromotionsService from '../promotions.service';
import PromotionsPercentTooltip from './promotions-percent-tooltip.vue';

@Component({
    extends: DayTooltipTemplate,
    components: {
        PromotionsPercentTooltip,
    },
})
export default class PromotionsCalendar extends DayTooltipTemplate {
    @inject(KEY.DocumentFiltersService)
    private documentFilterService!: DocumentFiltersService;

    @inject(KEY.PromotionsService)
    private promotionsService!: PromotionsService;

    protected defaultZIndex = 1000;
    protected reversedChangingSide = true;

    get isActive() {
        return !!this.focusElement;
    }

    get isPercentage() {
        if (!this.focusElement) return false;

        return this.focusElement.dataset.program!.startsWith('percentage');
    }

    get days() {
        return Array.from({ length: 7 })
            .map((_, i) => {
                const date = new Date(0, 0, i);

                return moment(date).format('ddd');
            });
    }

    get hotelId() {
        if (!this.focusElement) return null;
        return +this.focusElement.dataset.hotelid!;
    }

    get dataset() {
        type CellDataset = {
            hotelid: string;
            provider: string;
            program: string;
        };

        if (!this.focusElement) return {} as CellDataset;

        return this.focusElement.dataset as CellDataset;
    }

    get currentHotelId() {
        return +this.$route.params.hotelId;
    }

    get isMainHotel() {
        return this.hotelId === this.currentHotelId;
    }

    get daysOffset() {
        const { year, month } = this.documentFilterService;
        const d = new Date(year, month, 1);

        return d.getDay();
    }

    get numberOfDays() {
        const { year, month } = this.documentFilterService;
        const curDate = new Date(year, month);
        const firstDay = curDate.getDay();
        curDate.setMonth(curDate.getMonth() + 1);
        curDate.setDate(0);
        const numOfDays = curDate.getDate();

        if (numOfDays + firstDay > 35) {
            return 42;
        }

        if (numOfDays + firstDay <= 28) {
            return 28;
        }

        return 35;
    }

    getDay(cellIndex: number) {
        const { year, month } = this.documentFilterService;
        const maxDays = new Date(year, month + 1, 0).getDate();

        const currentDay = cellIndex - this.daysOffset;

        if (currentDay > 0 && currentDay <= maxDays) {
            return currentDay;
        }

        return undefined;
    }

    isCellActive(cellIndex: number, hotelId?: number) {
        const program = this.getCellProgram(cellIndex, hotelId);

        if (!program) return false;

        return program.status;
    }

    getCellStatus(cellIndex: number) {
        if (!this.focusElement) return 'disabled';

        const day = this.getDay(cellIndex);
        if (!day) return 'disabled';

        const { focusElement } = this;
        const { program, provider } = focusElement.dataset;

        if (program!.startsWith('percentage')) {
            const mainProgram = this.promotionsService
                .getProgram(day, provider!, this.currentHotelId!, program!);

            const competitorsAveragePercent = this.promotionsService
                .getCompetitorsAveragePercentDay(day, provider!, program!, this.currentHotelId!);

            if (!mainProgram || !mainProgram.status) {
                return 'disabled';
            }

            if (!competitorsAveragePercent) {
                return 'green';
            }

            const mainPercent = mainProgram.percentage;

            if (competitorsAveragePercent === mainPercent) {
                return 'yellow';
            }

            return competitorsAveragePercent < mainPercent
                ? 'green'
                : 'red';
        }

        return this.isCellActive(cellIndex, this.currentHotelId!) ? 'green' : 'disabled';
    }

    getPercent(cellIndex: number) {
        const programData = this.getCellProgram(cellIndex, +this.dataset.hotelid);

        return programData
            ? programData.percentage
            : undefined;
    }

    getCellProgram(cellIndex: number, forHotel?: number) {
        if (!this.focusElement) return null;

        const day = this.getDay(cellIndex);

        if (!day) return null;

        const { provider, program, hotelid: hotelId } = this.focusElement.dataset as unknown as {
            provider: string;
            program: string;
            hotelid: number;
        };

        const programs = this.promotionsService
            .getPrograms(day, provider, forHotel || hotelId);

        if (!programs) return null;

        const { [program]: programData } = programs;

        return programData;
    }

    emitTooltipElement(e: PointerEvent) {
        const value = this.isPercentage
            ? e.currentTarget
            : null;

        this.$emit('cellover', value);
    }

    emitCellOut() {
        this.$emit('cellout');
    }
}
