import { container } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type ClusterRatesService from '@/modules/cluster/cluster-rates.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import type ClusterMarketsService from '@/modules/cluster/cluster-markets.service';
import type ClusterDiLiteService from '@/modules/cluster/cluster-di-lite.service';
import type ClusterPromotionsService from '@/modules/cluster/cluster-promotions.service';
import type InsightFiltersService from '@/modules/document-filters/insight-filters.service';
import { otelBaseComponentLoading } from '../_utils';

/**
 * Add logs to a cluster rates page Vue components
 */
export function OtelRateClusterPageLoading(constructor: Function) {
    const clusterRatesService = container.get<ClusterRatesService>(KEY.ClusterRatesService);
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);
    const clusterService = container.get<ClusterService>(KEY.ClusterService);

    const filterToBuild = ['provider', 'priceType', 'los', 'roomTypeId', 'mealTypeId', 'month', 'year'];
    const settingsUnion = {
        ...clusterRatesService.settings,
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const settings = filterToBuild.reduce((acc, key) => {
        acc[key] = settingsUnion[key];
        return acc;
    }, {} as any);

    const { loading } = clusterService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a cluster market page Vue components
 */
export function OtelMarketClusterPageLoading(constructor: Function) {
    const clusterMarketsService = container.get<ClusterMarketsService>(KEY.ClusterMarketsService);
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);
    const clusterService = container.get<ClusterService>(KEY.ClusterService);

    const settings = {
        ...documentFiltersService.settings,
        ...clusterMarketsService.storeState.settings,
    } as Record<string, any>;

    const { loading } = clusterService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a cluster ranking page Vue components
 */
export function OtelRankingClusterPageLoading(constructor: Function) {
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);
    const clusterService = container.get<ClusterService>(KEY.ClusterService);

    const filterToBuild = ['month', 'year'];
    const settingsUnion = {
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const settings = filterToBuild.reduce((acc, key) => {
        acc[key] = settingsUnion[key];
        return acc;
    }, {} as any);

    const { loading } = clusterService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a cluster di lite page Vue components
 */
export function OtelDiLiteClusterPageLoading(constructor: Function) {
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);
    const clusterDiLiteService = container.get<ClusterDiLiteService>(KEY.ClusterDiLiteService);
    const clusterService = container.get<ClusterService>(KEY.ClusterService);

    const filterToBuild = ['month', 'year', 'priceType', 'priceShown', 'los', 'roomTypeId', 'mealTypeId'];
    const settingsUnion = {
        ...documentFiltersService.settings,
        ...clusterDiLiteService.settings,
    } as Record<string, any>;

    const settings = filterToBuild.reduce((acc, key) => {
        acc[key] = settingsUnion[key];
        return acc;
    }, {} as any);

    const { loading } = clusterService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a cluster promotions cluster Vue components
 */
export function OtelPromotionsClusterPageLoading(constructor: Function) {
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);
    const clusterPromotionsService = container.get<ClusterPromotionsService>(KEY.ClusterPromotionsService);
    const clusterService = container.get<ClusterService>(KEY.ClusterService);

    const filterToBuild = ['provider', 'los', 'programView', 'month', 'year'];
    const settingsUnion = {
        ...clusterPromotionsService.settings,
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const settings = filterToBuild.reduce((acc, key) => {
        acc[key] = settingsUnion[key];
        return acc;
    }, {} as any);

    const { loading } = clusterService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a cluster insights page Vue components
 */
export function OtelInsightsClusterPageLoading(constructor: Function) {
    const insightFiltersService = container.get<InsightFiltersService>(KEY.InsightFiltersService);
    const clusterService = container.get<ClusterService>(KEY.ClusterService);

    const filterToBuild = ['dateRange', 'provider'];
    const settingsUnion = {
        ...insightFiltersService.settings,
        provider: clusterService.storeState.provider,
    } as Record<string, any>;

    const settings = filterToBuild.reduce((acc, key) => {
        acc[key] = settingsUnion[key];
        return acc;
    }, {} as Record<string, any>);

    const { loading } = clusterService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}

/**
 * Add logs to a cluster benchmark page Vue components
 */
export function OtelBenchmarkClusterPageLoading(constructor: Function) {
    const clusterService = container.get<ClusterService>(KEY.ClusterService);
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);

    const filterToBuild = ['month', 'year']; // Add benchmark specific filters
    const settingsUnion = {
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const settings = filterToBuild.reduce((acc, key) => {
        acc[key] = settingsUnion[key];
        return acc;
    }, {} as any);

    const { loading } = clusterService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}
