import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import Day from '../common/types/day.type';
import type DocumentFiltersService from '../document-filters/document-filters.service';
import UserService, { UserServiceS } from '../user/user.service';
import RateValueScoreApiService from './rate-value-score-api.service';
import RateValueScoreService from './rate-value-score.service';
import RVSStore from './store/rvs-hotel.store';

export const RVSCalendarServiceS = Symbol.for('RVSCalendarService') as unknown as string;

@injectable()
export default class RVSCalendarService {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(KEY.RateValueScoreSerivce) private rateValueScoreService!: RateValueScoreService;
    @inject(KEY.RateValueScoreApiService) private rateValueScoreApiService!: RateValueScoreApiService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(UserServiceS) private userService!: UserService;

    readonly rvsStore: RVSStore = this.storeFacade.getState('RVSStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.year,
            this.documentFiltersService.month,
            this.userService.currentHotelId,
            this.rvsStore.settings.provider,
        ], () => {
            this.storeState.loading.reset();
        });
    }

    get storeState() {
        return this.rvsStore.calendar;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get currentRootCause() {
        const day = this.storeState.currentDay;
        const { currentHotelId } = this.userService;
        if (!currentHotelId) return null;

        return this.data?.checkinDates[day]?.[currentHotelId]?.rootCause || null;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.data;
    }

    private async loadData() {
        const { year, month } = this.documentFiltersService;
        const { currentHotelId } = this.userService;
        const { provider } = this.rvsStore.settings;

        if (!currentHotelId) return false;

        this.storeState.data = await this.rateValueScoreApiService
            .getMonthData(currentHotelId, year, month, provider);

        return true;
    }

    getCompsetAverageValue(day: Day) {
        return this.rateValueScoreService.getCompsetAverageValue(day, this.data);
    }

    getMainValue(day: Day) {
        return this.rateValueScoreService.getMainValue(day, this.data);
    }

    getDifference(day: Day) {
        return this.rateValueScoreService.getDifference(day, this.data);
    }

    getAssessment(day: Day) {
        return this.rateValueScoreService.getAssessment(day, this.data);
    }

    isNoData(day: Day) {
        return this.rateValueScoreService.isNoData(day, this.data);
    }
}
