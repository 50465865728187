

import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ITableHotelData from '@/modules/cluster/interfaces/table-hotel.interface';
import PriceFilter from '@/modules/common/filters/price.filter';
import PercentFilter from '@/modules/common/filters/percent.filter';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import type ClusterRatesService from '@/modules/cluster/cluster-rates.service';
import type RatesService from '@/modules/rates/rates.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import type RatesFiltersService from '@/modules/rates/rates-filters.service';
import PAGES from '@/modules/common/constants/pages.constant';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';

@Component({
    filters: {
        PercentFilter,
        PriceFilter,
    },
})
export default class HotelsColumn extends Vue {
    @inject(HelperServiceS) helperService!: HelperService;
    @inject(KEY.ClusterService) clusterService!: ClusterService;
    @inject(KEY.ClusterRatesService) clusterRatesService!: ClusterRatesService;
    @inject(KEY.RatesService) ratesService!: RatesService;
    @inject(KEY.DocumentFiltersService) documentFiltersService!: DocumentFiltersService;
    @inject(AuthServiceS) authService!: AuthService;
    @inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @inject(KEY.RatesFiltersService) ratesFiltersService!: RatesFiltersService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    isLoading = true;

    @Prop({ type: Array })
    hotels!: ITableHotelData[] | null;

    @Prop({ type: Number })
    hotelId!: number | null;

    isExpanded: boolean = false;
    private isCalendarLoading = false;

    get clickedHotel() {
        return this.hotelId;
    }
    get competitors() {
        return this.clusterRatesService.storeState.hotels;
    }

    isNoData(hotelId: number) {
        return this.clusterRatesService
            .isWholeHotelNoData(hotelId);
    }

    isShow(hotelId: number) {
        return hotelId === this.clickedHotel;
    }

    handleClick(hotelId: number, index: number) {
        if (this.isNoData(hotelId)) return;

        this.$emit('click-row', { hotelId, index });
    }

    getCompsetId(item: ITableHotelData) {
        const { ratesCompsetMain } = item;
        if (!ratesCompsetMain && this.clusterService.currentRatesProvider) {
            const compset = item.compsets.find(x => x.rateProviders.includes(this.clusterService.currentRatesProvider as string));
            return compset ? compset.id : null;
        }
        return ratesCompsetMain.id;
    }

    scoreDiff(newTotalScore: number) {
        const score = Math.round(newTotalScore);
        return `${score > 0 ? '+' : ''}${score}`;
    }

    async goToHotel(hotelId: number, compsetId: string) {
        this.ratesService.saveDocument(null);
        this.documentFiltersService.savePos(null);
        this.documentFiltersService.compsetIdFromHighLevel = compsetId;
        this.ratesFiltersService.providerFromHighLevel = this.clusterService.currentRatesProvider;
        this.userViewService.goToHotelPage(hotelId);
    }

    // Lazy load of document, send request only if component is in viewport
    loadViewportedHotel(hotel: ITableHotelData, isVisible: boolean) {
        if (!hotel || !hotel.id) {
            return;
        }

        const hotelData = this.clusterService.getHotelData<ClusterHotelsRatesModel>(hotel.id);

        if (this.isCalendarLoading || !isVisible || hotelData?.documentLoaded) {
            return;
        }

        this.isCalendarLoading = true;
        this.clusterService
            .loadDaysData(PAGES.RATES, hotel.id)
            .finally(() => {
                this.isCalendarLoading = false;
                this.$emit('dataLoaded');
            });
    }
}
