
import DotsSpinner from '@/modules/common/components/dots-spinner.vue';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import moment from 'moment';
import { Vue, Component } from 'vue-property-decorator';

import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import ListHeader from '../_common/list-days-header.vue';
import HotelsList from '../_common/hotels-list/index.vue';
import type ClusterPromotionsService from '../../cluster-promotions.service';
import type ClusterService from '../../cluster.service';
import WeekdayLetter from '../../filters/weekend-letter.filter';
import PromotionsDaysSet from './promotions-days-set.vue';
import ClusterPromotionsModel from '../../models/cluster-promotions.model';

@Component({
    components: {
        DotsSpinner,
        ListHeader,
        HotelsList,
    },
    filters: {
        DateFilter: (value: (Date | null)) => {
            if (!value) return '';
            return moment(value).format('D/M');
        },

        WeekdayLetter,
    },
})
export default class PromotionsClusterList extends Vue {
    @inject(KEY.ClusterPromotionsService) private clusterPromotionsService!: ClusterPromotionsService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    get hotels() {
        return this.clusterPromotionsService.hotels;
    }

    get pagesCount() {
        return this.clusterPromotionsService.hotelsCount;
    }

    get component() {
        return {
            element: PromotionsDaysSet,
            props: {},
        };
    }

    get headerData() {
        return [{
            value: 'hotelName',
            name: this.$tc('titles.hotelName'),
        }];
    }

    get tableData() {
        if (this.hotels === null || !this.hotels.length) {
            return [];
        }

        return this.hotels.map(h => ({
            table: [{
                value: h.hotelName,
                onClick: () => this.toHotel(h),
            }],
            component: {
                element: PromotionsDaysSet,
                props: { hotelData: h },
            },
        }));
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    toHotel(hotelData: ClusterPromotionsModel) {
        const compset = hotelData.compsetMain?.id ? hotelData.compsets.find(c => c.id === hotelData.compsetMain!.id) : hotelData.compsets[0];
        this.userViewService.goToHotelPage(hotelData.hotelId, compset);
    }
}
