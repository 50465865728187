
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';

import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import type Day from '@/modules/common/types/day.type';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';
import formatDate from '@/modules/common/filters/format-date.filter';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';

import type MarketsService from '@/modules/markets/markets.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import type ClusterMarketsService from '@/modules/cluster/cluster-markets.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

import MarketsDocumentModel from '../models/markets-document.model';

@Component({
    components: {
        ScanBtnSmall,
    },
})
export default class MarketsDayScanBtn extends Vue {
    @inject(KEY.MarketsService) marketsService!: MarketsService;
    @inject(KEY.ClusterService) clusterService!: ClusterService;
    @inject(MarketsCommonServiceS) marketsCommonService!: MarketsCommonService;
    @inject(KEY.ClusterMarketsService) clusterMarketsService!: ClusterMarketsService;
    @inject(KEY.DocumentFiltersService) documentFiltersService!: DocumentFiltersService;
    @inject(CompsetsServiceS) compsetsService!: CompsetsService;
    @inject(ClusterCompsetsServiceS) clusterCompsetsService!: ClusterCompsetsService;
    @inject(UserServiceS) private userService!: UserService;

    @Prop({
        type: Number,
        required: true,
    })
    private day!: Day | undefined;

    @Prop({
        type: Object,
    })
    private settings!: {
        compsetId: string | null;
        provider: string | null;
        los: number | null;
        pos: string | null;
    };

    get document() {
        if (!this.settings.provider) {
            return null;
        }

        if (this.userService.isViewAsCluster) {
            return this.clusterService.getMainCompsetData<MarketsCompsetMainModel>(this.hotelId) as unknown as MarketsDocumentModel;
        }

        return this.marketsService.getDocument(this.settings.provider);
    }

    get hotelId() {
        return +this.$route.params.hotelId || this.userService.currentHotelId!;
    }

    get isScanning() {
        if (!this.document) {
            return false;
        }

        return this.document?.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        const actualCompsetId = this.settings.compsetId || this.documentFiltersService.compsetId;

        const isCompsetActive = this.userService.isViewAsHotel
            ? this.compsetsService.getCompset(actualCompsetId)?.isActive
            : this.clusterCompsetsService.getCompsetById(actualCompsetId!)?.isActive;

        if (!isCompsetActive) return true;

        return !this.marketsCommonService.isScanAvailable(this.day) || !this.settings.provider;
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }

        if (this.userService.isViewAsCluster) {
            await this.clusterMarketsService.triggerScan(this.settings, this.day);
        } else {
            await this.marketsService.triggerScan(this.settings, this.day);
        }
    }

    get formatScanDate() {
        const dateScan = this.marketsCommonService.dayUpdateDate(this.day || 1, this.document);

        return formatDate(dateScan ? new Date(dateScan) : null);
    }
}
